import { Component } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';

@Component({
  selector: 'app-auth-google',
  templateUrl: './auth-google.component.html',
  styleUrls: ['./auth-google.component.scss'],
})
export class AuthGoogleComponent {
  public qrcode$: Observable<{qrCode: SafeResourceUrl, secret: string}>;

  constructor(
    private authService: TwoFactorAuthService,
) {
    this.qrcode$ = this.authService.getGoogleQrCode();
  }
}
