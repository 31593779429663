<div class="app-header__profile-menu">
  <ng-container *ngFor="let item of profileMenuItems | async">
    <div *ngIf="!item.disabled && item.visible"
         class="app-header__profile-menu-item"
         (click)="onClickLocale($event, item)"
         [class.app-header__selected-lang]="selectedLanguage === item.title && item.title !== undefined">
      <i *ngIf="!item.title" class="{{ item.icon }}"></i>
      <img *ngIf="item.title" class="app-header__img-locale" src="assets/flags/{{ item.title }}.svg">
      <span>{{ item.label$ | async }}</span>
      <i [ngClass]="[fontAwesomeIcon.AngleRight, 'app-header__icon']"></i>
    </div>
    <div *ngIf="item.disabled"
         class="app-header__profile-menu-item app-header__item-disabled">
      <span>{{ item.label$ | async }}</span>
    </div>
  </ng-container>
</div>
