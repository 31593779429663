<ng-container *ngIf="state$ | async as state">
  <div class="impersonate-indicator">
    <div class="impersonate-indicator__container" *ngIf="state.isImpersonating">
      <div class="d-flex">
        <span class="impersonate-indicator__label">
          {{ 'impersonate.logged-in-as.indicator-message' | translate : {user: state?.user?.name} }}
        </span>

        <cap-button [label]="'impersonate.back-to-my-account' | translate"
                    (click)="stopImpersonate()"
                    styleClass="primary"
                    [icon]="fontAwesomeIcon.Undo"></cap-button>
      </div>
    </div>
  </div>
</ng-container>
