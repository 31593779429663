import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-auth-sms',
  templateUrl: './auth-sms.component.html',
})
export class AuthSmsComponent implements OnInit {
  @Input()
  public smsCodeShown: boolean;

  @Output()
  public smsCodeEvent: EventEmitter<boolean> = new EventEmitter();

  public smsForm: UntypedFormGroup;
  public smsSent: boolean;
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: TwoFactorAuthService,
  ) {
  }

  public ngOnInit(): void {
    this.smsForm = this.formBuilder.group({
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });

    this.authService.setupSms().subscribe((response) => {
      if (response?.identifier) {
        this.smsForm.patchValue({ phoneNumber: response.identifier });
      }
    });
  }

  public submit(): void {
    if (this.smsForm.valid) {
      this.authService.register('sms', this.smsForm.value.phoneNumber).subscribe(() => {
        this.smsCodeEvent.emit(true);
        this.smsSent = true;
      });
    }
  }
}
