export enum InstancesPermissions {
  Region = 'region',
  Municipality = 'municipality',
  MboInstitution = 'mbo-institution',
  VoInstitution = 'vo-institution',
  VoSchoolGroup = 'vo-school-group',
  DaycareInstance = 'daycare-instance',
  PoInstitution = 'po-institution',
  DaycareOrganisation = 'daycare-organisation',
  RmcRegion = 'rmc-region',
}
