import { Component, Injector } from '@angular/core';
import { IntBaseListComponent } from '@shared/base/int-base-list.component';
import { ListOptions } from '@capturum/api';
import { Field } from '@shared/field/field.enum';
import { Modules } from '@core/enums/modules.enum';
import { RmcReportService } from '@features/dashboard-rmc-reports/services/rmc-report.service';
import { filter, first } from 'rxjs/operators';
import { PageConfig } from '@core/models/page-config.model';
import { NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { SidebarService } from '@core/components/sidebar/services/sidebar.service';
import { Entities } from '@core/components/entities.enum';
import { ReportPagesEnum } from '@features/dashboard-rmc-reports/pages/reports/report-pages.enum';
import { UserService } from '@features/user/services/user.service';
import {Subscription} from "rxjs";
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent extends IntBaseListComponent<any> {

  public reportPageName = null;
  public reportID = null;
  public stopLoading = false;
  public locale;
  public optionsFilter: ListOptions = {
    filters: [{ field: Field.Name, value: Modules.DashboardUS }],
  };

  private redirectStarted = false;
  private initPath = null;
  private sidebarSubscription: Subscription;

  constructor(private location: Location, private sidebarService: SidebarService, injector: Injector,  private userService: UserService, public apiService: RmcReportService) {
    super(injector);

  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.locale = { language: this.userService.getCurrentUser()?.locale?.code, locale: this.userService.getCurrentUser()?.locale?.code === 'nl' ? 'nl-Nl' : 'en-Gb'};
    this.initPath = window.location.pathname;
    this.route.data.pipe(first()).subscribe((data) => {
      this.reportPageName = data?.reportPageName;
    });

    this.getReports();
    this.menuItemNavigation();
  }

  public getReports(): void {
    this.apiService.getReports(this.optionsFilter).subscribe(reportArr => {
      this.stopLoading = true;
      if (reportArr && reportArr.data && reportArr.data[0]) {
        this.reportID = reportArr.data[0]['report_id'];
      } else {
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('dashboard-rmc.dashboard-reports.report-not-found.label'),
        );
      }
    }, () => {
      this.stopLoading = true;
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('dashboard-rmc.dashboard-reports.report-not-found.label'),
      );
    });
  }

  public changeReportName(reportName: string): void {
    this.reportPageName = reportName;
  }

  public getDefaultConfig(): PageConfig {
    return {
      title: this.getPageTitle(),
      buttons: [],
    };
  }

  private menuItemNavigation(): void {
    this.router.events.pipe(filter((routerEvent) => {
      return routerEvent instanceof NavigationStart;
    })).subscribe((event: NavigationStart) => {

    if (this.redirectStarted === false && event.url.includes(`${Modules.DashboardUS}/${Entities.DashboardReports}`) && event.url !== `/${Modules.DashboardUS}/${Entities.UploadedFiles}`) {
      this.redirectStarted = true;
      // prevents redirect
      this.router.navigateByUrl(this.initPath, { replaceUrl: true });

      this.sidebarSubscription = this.sidebarService.getEvent().subscribe((clickEvent: PointerEvent) => {
        // change url
        window.history.pushState('', '',  clickEvent?.currentTarget['dataset']['test']);

        document.querySelector('.item.active')?.classList?.remove(Entities.Active);
        document.querySelector(`[data-test="${clickEvent?.currentTarget['dataset']['test']}"]`)?.classList?.add(Entities.Active);
        this.layoutService.setPageConfig({
          title: this.getPageTitle(clickEvent?.currentTarget['dataset']['test']),
          buttons: [],
        });

        this.changeReportNameForTarget(clickEvent?.currentTarget['dataset']['test']);
        });
      }

      setTimeout(() => {
        this.redirectStarted = false;
      }, 100);
    });
  }

  public ngOnDestroy(): void {
    if (this.sidebarSubscription) {
        this.sidebarSubscription.unsubscribe();
    }
  }

  private changeReportNameForTarget(url: string): void {
    switch (url) {
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.Mbo}`:
        this.changeReportName(ReportPagesEnum.ReportMbo);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMBORegional}`:
        this.changeReportName(ReportPagesEnum.ReportMboRegional);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSGraduated}`:
        this.changeReportName(ReportPagesEnum.Diploma);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSGraduatedRegional}`:
        this.changeReportName(ReportPagesEnum.DiplomaRegional);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSVoReport}`:
        this.changeReportName(ReportPagesEnum.VoOverzicht);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMboReport}`:
        this.changeReportName(ReportPagesEnum.MboOverzicht);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMboDownloadReport}`:
        this.changeReportName(ReportPagesEnum.MboLevering);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevel}`:
        this.changeReportName(ReportPagesEnum.Schoolniveau);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevelRegional}`:
        this.changeReportName(ReportPagesEnum.SchoolniveauRegional);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`:
        this.changeReportName(ReportPagesEnum.Leerlingniveau);
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSTotalDataOverview}`:
        this.changeReportName(ReportPagesEnum.TotalDataOverview);
        break;
    }
  }

  private getPageTitle(reportUrl: string = null): string {
    let title = '';
    switch (reportUrl ?? this.router.url) {
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.Mbo}`:
        title = this.translateService.instant('dashboard-us.report.mbo');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMBORegional}`:
        title = this.translateService.instant('dashboard-us.report.mbo-regional');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSGraduated}`:
        title = this.translateService.instant('dashboard-us.report.graduated');
        break
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSGraduatedRegional}`:
        title = this.translateService.instant('dashboard-us.report.graduated-regional');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSVoReport}`:
        title = this.translateService.instant('dashboard-us.report.vo-report');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMboReport}`:
        title = this.translateService.instant('dashboard-us.report.mbo-report');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSMboDownloadReport}`:
        title = this.translateService.instant('dashboard-us.report.mbo-download-report');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevel}`:
        title = this.translateService.instant('dashboard-us.report.school-level');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevelRegional}`:
        title = this.translateService.instant('dashboard-us.report.school-level-regional');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`:
        title = this.translateService.instant('dashboard-us.report.student-level');
        break;
      case `/${Modules.DashboardUS}/${Entities.DashboardReports}/${Entities.DashboardUSTotalDataOverview}`:
        title = this.translateService.instant('dashboard-us.report.total-overview.report');
        break;
    }
    return title;
  }
}
