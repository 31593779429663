import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Intergrip 2.0',
  production: true,
  hmr: false,
  name: 'production',
  baseUrl: 'https://api.prod.intergrip.nl/api',
  sentryUrl: null,
  webSocketKey: 'e9287822968e5280a7ad',
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/'
};
