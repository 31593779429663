import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NewsOverviewService } from '@features/news/services/news-overview.service';

@Injectable({
  providedIn: 'root',
})

export class AppService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isContentLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
