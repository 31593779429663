<nav class="navbar navbar-static-top white-bg app-header d-flex" role="navigation">
  <div class="app-header__bar app-header__max-w">
      <div class="logo">
        <a routerLink="/">
          <img [src]="'./assets/images/logo.png'" alt="logo" />
        </a>
      </div>
      <div class="app-header__header-menu">
        <div class="app-header__user" *ngIf="user$ | async as user">
          <span class="app-header__name" (click)="navigateToProfile()">{{ user?.name }}</span>
          <span class="app-header__role" (click)="overlayPanel.toggle($event)">
          <span>
            {{ user.currentRoleType.name || '-' }}{{ user?.currentInstance ? ': ' + user.currentInstance.name : '' }}
          </span>
          <i [class]="overlayPanel.overlayVisible ? 'far fa-chevron-up' : 'far fa-chevron-down'"></i>
          </span>
        </div>
        <div *ngIf="activeYear" class="app-header__active-year">{{activeYear}}</div>
        <div class="app-header__to-profile-menu" (click)="openProfileMenu(localePanel, $event)">
          <i [ngClass]="fontAwesomeIcon.EllipsisV"></i>
        </div>
      </div>
  </div>

  <div class="app-header__modules app-header__bar row">
    <div *ngIf="showSliderArrows" class="app-header__arrow" (click)="swipeModule(arrowSide.Left)">
      <i [ngClass]="fontAwesomeIcon.ChevronLeft"></i>
    </div>

    <cap-horizontal-navbar [class]="{'app-header__modules-list': showSliderArrows}" #modulesMenu
                           [items]="moduleMenuItems$ | async">
      <ng-template capTemplate="icon" let-item>
        <ng-container *ngIf="item.key !== 'intergrip' && item.key !== 'student-portal'; else defaultTemplate">
          <img class="app-header__module-logo" [src]="item.key | moduleImage" alt="module logo">
        </ng-container>

        <ng-template #defaultTemplate>
          <i [class]="item.icon"></i>
        </ng-template>
      </ng-template>

      <ng-template capTemplate="nav-item" let-item>
        <span class="module-name">{{ item.title | translate }}</span>
      </ng-template>
    </cap-horizontal-navbar>
    <div *ngIf="showSliderArrows" class="app-header__arrow app-header__arrow--right"
         (click)="swipeModule(arrowSide.Right)">
      <i [ngClass]="fontAwesomeIcon.ChevronRight"></i>
    </div>

  </div>
</nav>

<p-overlayPanel class="app-header__panel" #overlayPanel>
  <app-role-selector [schoolYears]="schoolYears"
                     (onSelectRole)="setActiveRole($event); overlayPanel.hide()"
                     (onFavoriteRole)="updateFavoriteRole($event)">
  </app-role-selector>
</p-overlayPanel>

<p-overlayPanel class="app-header__panel" #localePanel>
  <i [ngClass]="[fontAwesomeIcon.CaretUp, 'fa-solid', 'fa-2x', 'caret-icon']"></i>
  <app-profile-menu [profileMenuItems]="profileMenuItems$"></app-profile-menu>
</p-overlayPanel>
