import {
  ChangeDetectorRef,
  Component, EventEmitter, Input,
  OnInit,
  Optional, Output,
  ViewEncapsulation
} from '@angular/core';
import { FilterType } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterConfig } from '@core/models/filter-config.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileFilterComponent implements OnInit {

  public filterConfig: FilterConfig[];
  public activeFilter: string[];
  public filterType = FilterType;
  @Output()
  public closeModal = new EventEmitter();

  constructor(private config: DynamicDialogConfig, @Optional() private dialogRef: DynamicDialogRef, private translateService: TranslateService, private cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.filterConfig = this.config.data.filters;
    this.activeFilter = this.config.data.activeFilter;
    this.cdr.detectChanges();
  }


  public filter(filter: { field: string; matchMode: string; value: any; }): void {
    this.config.data.onFilter(filter.value, filter.field, filter.matchMode);
  }

  public reset(): void {
    this.config.data.onReset();
    this.activeFilter = [];
  }

  public search(value: string): void {
    this.config.data.onSearch(value);
  }

  public close(): void {
    this.config.data.onClose.next(true);

  }

}

