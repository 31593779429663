<form class="inputs-code w-100" [formGroup]="inputsForm">
  <ng-container *ngFor="let item of inputsArray?.controls; let i = index"
                formArrayName="myCode">
    <cap-input [formControl]="item"
                [id]="'input-code-' + i"
                maxLength="1"
                pKeyFilter="pint"
                styleClass="input-code"
                (onInput)="focusOnNextInput(i, $event, $event.target.value)">
    </cap-input>
  </ng-container>
</form>
