<div class="panel">
  <div class="row app-role-selector__head">
    <div class="col-12 dates">
      <ng-container *ngFor="let item of schoolYears">
        <span class="date" [class.active]="item.year === activeYear?.year" (click)="setActiveYear(item)">
          {{ item.year }}
        </span>
      </ng-container>
    </div>
  </div>

  <div class="app-role-selector__role-list">
    <ng-container *ngFor="let item of activeYear?.roles">
      <div class="row item px-0" (click)="setRole(item)">
        <div class="col-10">
          <span class="label" [class.active]="item.active">{{ item.region_affix ? item.role + ' (' + item.region_affix + ')' : item.role }}</span>
          <span class="description">{{ item.role_type }}</span>
        </div>

        <div class="col-2 text-right">
          <span class="favorite-icon icon" [class.active]="item.favorite" (click)="updateFavoriteRole($event, item.id)">
            <i [class]="item.favorite ? fontAwesomeIcon.Star : fontAwesomeIcon.Star"></i>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
