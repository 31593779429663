import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LocalstorageHelperService {
  public clearFilters(): void {
    const storageItems = { ...localStorage };
    for (const key of Object.keys(storageItems)) {
      if (key.indexOf('-list') > -1) {
        localStorage.removeItem(key);
      }
    }
  }
}
