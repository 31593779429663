import { MessageOfTheDayService } from '@core/services/message-of-the-day.service';
import { TranslationService } from '@capturum/complete';
import { AfterContentChecked, ChangeDetectorRef, Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@shared/services/layout.service';
import { Observable } from 'rxjs';
import { PageConfig } from '@core/models/page-config.model';
import { delay, filter, map } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';
import { MenuItemService } from '@shared/services/menu-item.service';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { AppService } from '@src/app/app.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { Modules } from '@core/enums/modules.enum';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicLayoutComponent implements AfterContentChecked, OnInit {
  @Select(UserState.isImpersonating)
  public isImpersonated$: Observable<boolean>;
  public pageConfig$: Observable<PageConfig>;
  public activeMenuItemKey$: Observable<string>;
  public showMOTD$: Observable<boolean>;
  public timesIcon = FontAwesomeIcon.Times;
  public showHeader = true;
  public isHomePage = false;

  constructor(
    private translateService: TranslateService,
    private layoutService: LayoutService,
    private menuItemService: MenuItemService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private messageOfTheDayService: MessageOfTheDayService,
  ) {
    this.pageConfig$ = this.layoutService.pageConfig$.pipe(
      filter<PageConfig>(Boolean),
      delay(0),
    );
    this.layoutService.pageConfig$.subscribe((data) => {
      this.pageConfig$ = new Observable<PageConfig>((o) => o.next(data));
    });
    this.activeMenuItemKey$ = this.menuItemService.getActiveMenuItem().pipe(
      filter(Boolean),
      map((menuItem: NavMenuItem) => {
        const menuItemName = `module-${menuItem.key ?? Modules.Intergrip}`;
        this.showHeader = menuItemName !== 'module-my_products';
        return menuItemName;
      }), delay(1));

    this.showMOTD$ = this.messageOfTheDayService.shouldShowMOTD();
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.isHomePage = window?.location?.pathname?.indexOf('my_products') > -1;
    this.router.events.pipe(filter((routerEvent) => {
      return routerEvent instanceof NavigationEnd;
    })).subscribe((event: NavigationEnd) => {
      this.isHomePage = window?.location?.pathname?.indexOf('my_products') > -1;
    });
  }


  public get isLoading(): boolean {
    return this.appService.isLoading.getValue();
  }

  public get isContentLoading(): boolean {
    return this.appService.isContentLoading.getValue();
  }
}
