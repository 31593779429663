import {FieldType} from '@ngx-formly/core';
import {Component, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {Field} from "@shared/field/field.enum";
import {FontAwesomeIcon} from "@core/enums/font-awesome-icon.enum";
import {InfoTableColumn, InfoTableColumnType} from '@capturum/ui/info-table';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-formly-time-table',
    templateUrl: './time-table.component.html',
    styleUrls: ['./time-table.component.scss'],
})
export class TimeTableTypeComponent extends FieldType implements AfterViewInit {
    workDaysAliases: any[] = [
        {
            id: 1,
            key: this.translateService.instant(`social-map.timetable.mo.title`),
            label: this.translateService.instant(`social-map.timetable.monday.title`),
        },
        {
            id: 2,
            key: this.translateService.instant(`social-map.timetable.tu.title`),
            label: this.translateService.instant(`social-map.timetable.tuesday.title`),
        },
        {
            id: 3,
            key: this.translateService.instant(`social-map.timetable.we.title`),
            label: this.translateService.instant(`social-map.timetable.wednesday.title`),
        },
        {
            id: 4,
            key: this.translateService.instant(`social-map.timetable.th.title`),
            label: this.translateService.instant(`social-map.timetable.thursday.title`),
        },
        {
            id: 5,
            key: this.translateService.instant(`social-map.timetable.fr.title`),
            label: this.translateService.instant(`social-map.timetable.friday.title`),
        }
    ];
    selectedDays: string[] = [];
    tableCustomData: any[] = [];
    rowId: number = 0;

    constructor(
        public translateService: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterViewInit() {
        if (this.formControl) {
            this.formControl.valueChanges.subscribe((value) => {
                if (!this.tableCustomData || this.tableCustomData.length === 0) {
                    this.tableCustomData = value;
                    this.tableCustomData?.sort((a, b) => a.week_day - b.week_day);
                    this.updateDaySelectionFromData(value);
                    this.cdr.detectChanges();
                }
            });
        }
    }

    tableColumns: InfoTableColumn[] = [
        {
            field: Field.WeekDay,
            title: '',
            sortable: { enabled: false, direction: 'asc' },
            type: InfoTableColumnType.Template
        },
        {
            field: Field.StartTime,
            title: '',
            type: InfoTableColumnType.Template
        },
        {
            field: Field.EndTime,
            title: '',
            type: InfoTableColumnType.Template
        },
        {
            field: Field.Buttons,
            title: '',
            type: InfoTableColumnType.Template
        },
    ];

    getLabel(dayId): string {
        const workDay = this.workDaysAliases.find(item => item.id === dayId)
        return workDay ? workDay.label : '';
    }

    updateDaySelectionFromData(tableData: any): void {
        if (tableData) {
            tableData?.forEach((row) => {
                const workDay = this.workDaysAliases.find(item => item.id === row.week_day)
                const index = this.selectedDays.indexOf(workDay.key);
                row.key = workDay.key;
                if (index === -1) {
                    this.selectedDays.push(workDay.key);
                }
            });
        }
    }

    toggleDaySelection(day: string): void {
        const index = this.selectedDays.indexOf(day);

        if (index === -1) {
            this.selectedDays.push(day); // Add if not selected
            const workDay = this.workDaysAliases.find(item => item.key === day)
            this.tableCustomData.push({
                day: workDay?.label,
                key: day,
                week_day: workDay?.id,
                id: this.rowId
            });
            this.rowId += 1;
        } else {
            this.tableCustomData =  this.tableCustomData.filter(item => item.key !== day);
            this.selectedDays.splice(index, 1);
        }

        this.tableCustomData?.sort((a, b) => a.week_day - b.week_day);

        this.formControl.setValue(this.tableCustomData);
    }

    public removeItem(rowItem: any): void {
        const index = this.tableCustomData.findIndex(item => item.id === rowItem.id);
        if (index !== -1) {
            this.tableCustomData.splice(index, 1);
        }

        this.tableCustomData?.sort((a, b) => a.week_day - b.week_day);

        if (this.tableCustomData) {
            this.selectedDays = [];
            this.tableCustomData?.forEach((row) => {
                const workDay = this.workDaysAliases.find(item => item.id === row.week_day)
                const index = this.selectedDays.indexOf(workDay.key);
                if (index === -1) {
                    this.selectedDays.push(workDay.key);
                }
            });
        }

        this.formControl.setValue(this.tableCustomData);
    }

    public updateData(rowItem: any, value: any, from: boolean, ): void {
        const index = this.tableCustomData.findIndex(item => item.id === rowItem.id);

        if (from) {
            this.tableCustomData[index].start_time = value;
        } else {
            this.tableCustomData[index].end_time = value;
        }

        this.tableCustomData?.sort((a, b) => a.week_day - b.week_day);

        this.formControl.setValue(this.tableCustomData);
    }

    public addItem(rowItem: any): void {
        this.tableCustomData.push({
            day: rowItem.day,
            week_day: rowItem.week_day,
            key: rowItem.key,
            id: this.rowId
        });

        this.rowId += 1;
        this.tableCustomData?.sort((a, b) => a.week_day - b.week_day);
        this.formControl.setValue(this.tableCustomData);
    }

    protected readonly tableField = Field;
    protected readonly fontAwesomeIcon = FontAwesomeIcon;
}
