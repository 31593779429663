import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '@core/enums/date-format.enum';
import { DateParserPipe } from '../date-parser/date-parser.pipe';

@Pipe({
  name: 'appDateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private dateParserPipe: DateParserPipe) {
  }

  public transform(value: string | Date, withSeconds = false): string {
    const date = new Date(value);
    return this.datePipe.transform(this.dateParserPipe.transform(date, DateFormat.DisplayStandardDateTime), withSeconds ? DateFormat.displayDateTimeSeconds : DateFormat.displayDateTime);
  }

}
