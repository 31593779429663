<div class="action ml-2" *ngIf="menuItems">
  <cap-button styleClass="primary menu-item-button" [icon]="fontAwesomeIcon.EllipsisV" *ngIf="menuItems | menuButtonVisibility"
              (click)="overlayPanel.toggle($event);">
  </cap-button>
</div>
<p-overlayPanel styleClass="menu-button--overlaypanel p-0" #overlayPanel appendTo="body">
  <div class="panel">
    <ng-container *ngFor="let item of menuItems">
      <div class="menu-item row" *ngxPermissionsOnly="item.permissions" (click)="overlayPanel.overlayVisible = false; item.callback();">
        <div class="col-12">
          <i class="d-inline mr-3" [class]="item.icon"></i>
          <span class="label d-inline" [class.active]="true">{{ item.label }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</p-overlayPanel>
