import { Permissions } from '@core/enums/permissions.enum';
import arrayPath from '@shared/utils/array-path/array-path.utils';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PageRoute } from '@core/enums/general/routes.enum';
import { MenuItemService } from '@shared/services/menu-item.service';
import { CapturumDialogService, FilterMatchMode, ToastService } from '@capturum/ui/api';
import { UserService } from '@features/user/services/user.service';
import { RoleService } from '@features/role/services/role.service';
import { UserModel } from '@features/user/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { SchoolYear } from '@core/models/school-year.model';
import { RoleItem } from '@core/components/header/role-item.model';
import { ModuleService } from '@features/module/services/module.service';
import { UriUtils } from '@shared/utils/uri/uri.utils';
import { Entities } from '@core/components/entities.enum';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { ModuleIconMap, Modules } from '@core/enums/modules.enum';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { SubSink } from 'subsink';
import { Role } from '@features/role/models/role.model';
import { Select } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';
import { LocaleService } from '@core/services/locale.service';
import Locale from '@capturum/auth/lib/locale.interface';
import User from '@capturum/auth/lib/user.interface';
import { ListOptions } from '@capturum/api';
import { DOCUMENT } from '@angular/common';
import { TranslationService } from '@capturum/complete';
import { intergripNxtFirstSchoolYear, LocaleEnum } from '@core/enums/locale.enum';
import { Module } from '@features/module/models/module.model';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { LogoService } from '@core/services/logo.service';
import { LocalParamsService } from '@core/services/local-params.service';
import { AppService } from '@src/app/app.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { SalesRequestsPermissions } from '@features/sales-requests/sales-requests-permissions.enum';
import { OverlayModalComponent } from '@shared/components/overlay-modal/overlay-modal.component';
import { ProfileMenuComponent } from '@features/profile-menu/profile-menu.component';
import { OverlayPanel } from 'primeng/overlaypanel/overlaypanel';
import { LocalstorageHelperService } from '@shared/services/localstorage-helper.service';
import { IntercomService } from '@src/app/features/auth/services/intercom.service';

export interface ProfileMenuItem extends MenuItem {
  label$: Observable<string>;
}

enum ArrowSide {
  Left = 'left',
  Right = 'right'
}

const arrowWidth = 33;
const screenSize = 767;

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CapturumDialogService],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(UserState.user)
  public user$: Observable<UserModel>;

  public profileMenuItems$: Observable<ProfileMenuItem[]>;
  public profilesMenuItems = {};
  public selectedLanguage: string;
  public currentUser: UserModel;
  public settingPermissions: string[] = [];
  public moduleMenuItems$: Observable<NavMenuItem[]>;
  public schoolYears: SchoolYear[] = [];
  public currentRole: string;
  public arrowSide = ArrowSide;
  public currentRole$: Observable<Role>;
  public localeList$: Observable<Locale[]>;
  public onClose = new BehaviorSubject(false);
  public fontAwesomeIcon = FontAwesomeIcon;

  @ViewChild('modulesMenu', { read: ElementRef })
  public modulesMenu: ElementRef;

  public showSliderArrows = false;
  public activeYear: string;
  private slidedToRight = 0;
  private subs = new SubSink();
  private availableLocales: string[] = [LocaleEnum.Dutch, LocaleEnum.English];
  private observer: MutationObserver;

  private currentSchoolYearPermissions: string[] = [
    arrayPath([Modules.TransferVo, Permissions.CurrentSchoolYear, Permissions.Show]),
    arrayPath([Modules.TransferMbo, Permissions.CurrentSchoolYear, Permissions.Show]),
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private authService: AuthService,
    private paramsService: LocalParamsService,
    private menuItemService: MenuItemService,
    private moduleService: ModuleService,
    private localeService: LocaleService,
    @Inject(DOCUMENT) private _document: Document,
    private infoTableConfig: InfoTableConfigService,
    private translationService: TranslationService,
    private permissionsService: NgxPermissionsService,
    private logoService: LogoService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private dialogService: CapturumDialogService,
    private localstorageHelperService: LocalstorageHelperService,
    private intercomService: IntercomService,
  ) {
    this.settingPermissions = [
      'user.manage.tenant',
      'tenant.manage',
      'module.manage.tenant',
      'translation.manage.tenant',
      'role.manage.tenant',
    ];
    this.getCurrentUser();
    this.currentRole = this.currentUser?.currentRoleType?.name;

    this.infoTableConfig.getConfig().pipe(first()).subscribe((config) => {
      this.infoTableConfig.setConfig({
        defaultTexts: {
          ...config.defaultTexts,
          noResults: this.translateService.stream('intergrip.info-table.no_results.message'),
          footerPaginationText: this.translateService.stream('intergrip.info-table.footer_paginator.message'),
        }, // TODO fix after capturum update
      });
    });

    this.paramsService.roleKeyUpdated.pipe(
      switchMap((data: any) => {
        if (data?.roleKey) {
          this.roleService.setRoleByRoleKey(data.roleKey).subscribe((r) => {
            this.router.navigate([data.route]);
            this.paramsService.roleKeyUpdated.next(null);
          }, () => {
            this.toastService.success(
              this.translateService.instant('toast.error.title'),
              this.translateService.instant('intergrip.error-access-link.message'),
            );
          });
        }

        return of(null);
      }),
      catchError((error) => {
        this.toastService.success(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('intergrip.error-setting-role.message'),
        );

        return throwError(error);
      }),
    ).subscribe(response => {
      if (response) {
        const currentRoute: string = this.router.url;

        this.router.navigate([currentRoute], { relativeTo: this.route });
        this.onWindowResize();
      }
    });
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    let modulesWidth = 0;

    this.modulesMenu.nativeElement.querySelectorAll('cap-nav-item').forEach((node) => {
        modulesWidth += node.offsetWidth;
    });
    this.modulesMenu.nativeElement.querySelector('.navbar-nav').style = '';
    this.slidedToRight = 0;
    if (window.innerWidth < modulesWidth) {
        this.showSliderArrows = true;

        return;
    }
    this.showSliderArrows = false;
  }

  public ngOnInit(): void {

    this.getModuleMenuItems();
    this.setActiveMenuItem(this.router.url);

    this.router.events.pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.setActiveMenuItem(event.urlAfterRedirects);
    });

    this.selectedLanguage = this.currentUser?.locale?.code ? this.currentUser?.locale?.code : LocaleEnum.Dutch;
    this.translateService.use(this.selectedLanguage);
    this.translateService.getTranslation(this.selectedLanguage);


    this.initMenuItems();

    this.subs.add(
      combineLatest([this.userService.userChanged$, this.roleService.roleUpdated$]).pipe(
        tap(() => this.getModuleMenuItems()),
        switchMap(() => this.roleService.all())).subscribe((response: SchoolYear[]) => {
        if (response) {
          this.schoolYears = response;
          this.activeYear = response.find((year) => year.roles.some((role) => role.active))?.year || response[0]?.year;

          this.setCurrentSchoolYearPermission();
        }
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.initArrows();
    this.observeDomChanges();
  }

  private observeDomChanges(): void {
    this.observer = new MutationObserver(() => {
        this.onWindowResize();
    });
    this.observer.observe(this.modulesMenu.nativeElement, { childList: true, subtree: true });
  }

  public initArrows(): void {
    const newItem = document.querySelectorAll('cap-nav-item');

    if (newItem?.length > 0) {
      this.onWindowResize();

      return;
    }
    setTimeout(() => this.initArrows(), 100);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  /**
   * Navigate to given url
   *
   * @param url
   */
  public navigate(url: string): void {
    this.router.navigate([url]);
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
      const token = localStorage.getItem('token');

      if (token) {
          this.authService.logout().subscribe(result => {
              if (result) {
                  this.localstorageHelperService.clearFilters();
                  this.navigate(PageRoute.login);
              }
          });
      } else {
          this.localstorageHelperService.clearFilters();
          this.navigate(PageRoute.login);
          this.toastService.error(
              this.translateService.instant('toast.error.title'),
              this.translateService.instant('intergrip.logout-error.message'),
          );
      }
  }

  public navigateToProfile(): void {
    this.router.navigate([PageRoute.profile]);
  }

  public navigateToNotifications(): void {
    this.router.navigate([PageRoute.Notifications]);
  }

  public navigateToNews(): void {
    this.router.navigate([PageRoute.newsMessages]);
  }

  public updateFavoriteRole(id: string): void {
    this.userService.setRolePermission(id).subscribe((response) => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('toast.success.update', { entity: this.translateService.instant('intergrip.favorite-role.entity_name') }),
      );
    });
  }

  public setActiveRole(role: RoleItem): void {
    this.schoolYears = this.schoolYears.map((year) => ({
        ...year,
        roles: year.roles.map((yearRole) => {
          yearRole.active = yearRole.id === role.id;

          return yearRole;
        }),
      }));
    this.currentRole = role.role;
    this.appService.isLoading.next(true);
    this.roleService.setRole(role.id).subscribe(() => {
      this.activeYear = this.schoolYears.find((year) => year.roles.some((roleItem) => roleItem.active))?.year;
      this.localstorageHelperService.clearFilters();
      this.initMenuItems();
      this.getCurrentUser();
      this.setCurrentSchoolYearPermission();
      this.setActiveMenuItem(this.router.url);
      // Refresh the page to re-activate the route guards
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/'], { relativeTo: this.route });
        setTimeout(() => this.onWindowResize(), 500);
      });
    });
  }

  public swipeModule(side: ArrowSide): void {
    const navBar = this.modulesMenu.nativeElement.querySelector('.navbar-nav');
    const wrapperDebounce = this.modulesMenu.nativeElement.getBoundingClientRect();

    for (const item of this.modulesMenu.nativeElement.querySelectorAll('cap-nav-item')) {
      const rect = item.getBoundingClientRect();

      if (side === ArrowSide.Left && (rect.left + rect.width + arrowWidth > 0)) {
        this.slidedToRight += rect.left - arrowWidth;
        if (this.slidedToRight - 1 <= arrowWidth) {
          this.slidedToRight = 0;
        }
        break;
      }
      if (side === ArrowSide.Right && (rect.right + this.slidedToRight > wrapperDebounce.right + Math.abs(this.slidedToRight))) {
        this.slidedToRight += rect.right - wrapperDebounce.x - wrapperDebounce.width;
        break;
      }
    }
    navBar.style = `right: ${this.slidedToRight}px`;
  }

  public getModuleMenuItems(): void {
    this.moduleMenuItems$ = this.roleService.roleUpdated$.pipe(
      switchMap(() => this.moduleService.getUserModules()),
      map((modules: Module[]) => [
          {
            title: 'intergrip.menu.my-products',
            routerUrl: Entities.My_Products,
            icon: ModuleIconMap[Entities.My_Products],
            permissions:
              [
                arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Manage]),
                arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Show]),
                arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.List]),
              ],
            key: Entities.My_Products,
            color: '#ff9500',
          },
          ...modules.map((module: Module) => ({
              title: `${module.key}.module-name`,
              routerUrl: module.key,
              icon: ModuleIconMap[module.key],
              styleClass: module.key === Modules.StudentPortal ? 'primary-menu-item' : '',
              permissions: [],
              key: module.key,
              color: module.color,
            })),
          {
            title: 'intergrip.menu.manage',
            activeSubRoute: `/${Modules.Intergrip}`,
            routerUrl: UriUtils.submenu(Entities.Students),
            exact: false,
            styleClass: 'primary-menu-item',
            key: 'intergrip',
            icon: FontAwesomeIcon.Cog,
            permissions: [],
            modules: [],
          },
        ]));
  }

  public openLogsSidebar(): void {
    this.dialogService.open(
      ProfileMenuComponent,
      {
        data: {
          profilesMenuItems: this.profileMenuItems$,
          onClose: this.onClose,
          styleClass: 'app-header__overlay',
        },
      },
      OverlayModalComponent,
    );
  }

  public openProfileMenu(panel: OverlayPanel, event: MouseEvent): void {
    if (document.body.clientWidth <= screenSize) {
      this.openLogsSidebar();

      return;
    }
    panel.toggle(event);

  }

  private setActiveMenuItem(url: string): void {
    this.getCurrentUser();
    let activeItem = this.menuItemService.menuItems.find((menuItem) => url.includes(menuItem.activeSubRoute));

    if (!activeItem) {
      return;
    }

    this.intercomService.updateModule(this.translateService.instant(activeItem.title));

    const excludedMenuItems = ['dashboard-rmc.dashboard-reports.vsv-regional-worklists.label',
      'dashboard-rmc.dashboard-reports.jikp-regional-worklists.label', 'dashboard-rmc.dashboard-reports.export-regional-vsv-worklists.label',
      'dashboard-rmc.dashboard-reports.export-regional-jikp-worklists.label', 'dashboard-rmc.dashboard-reports.no-c-delivery.label'];

    if (activeItem.key === Entities.DashboardRMC) {
      activeItem = { ...activeItem, children: [...activeItem.children] };

      const currentRegionIsGroningen = this.currentUser?.currentInstance?.regions?.find(region => (region.name === Entities.Groningen));
      const currentInstanceIsGroningen = this.currentUser?.currentInstance?.name === Entities.Groningen;

      if (!currentRegionIsGroningen && !currentInstanceIsGroningen) {
        activeItem.children = activeItem.children.filter(child => !excludedMenuItems.includes(child.title));
      }
    }

    this.menuItemService.setActiveMenuitem(activeItem);
  }

  private setCurrentSchoolYearPermission(): void {
    if (this.currentUser.currentSchoolYear?.year >= intergripNxtFirstSchoolYear) {
      this.permissionsService.addPermission(this.currentSchoolYearPermissions);
    } else {
      this.currentSchoolYearPermissions.forEach(permission => {
        this.permissionsService.removePermission(permission);
      });
    }
  }

  private getCurrentUser(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.logoService.currentUserRoleChanged.next(this.currentUser);
  }

  private initMenuItems(): void {
    const options: ListOptions = {
      filters: [
        {
          value: this.availableLocales,
          field: 'code',
          operator: FilterMatchMode.IN,
        },
      ],
    };

    this.profileMenuItems$ = this.localeService.getLocales(options).pipe(
      map((locales) => [
          {
            label$: this.translateService.stream('intergrip.profile.entity_name'),
            icon: FontAwesomeIcon.User,
            visible: true,
            command: () => {
              this.navigateToProfile();
            },
          },
          {
            label$: this.translateService.stream('intergrip.profile.manuals'),
            icon: FontAwesomeIcon.Book,
            visible: true,
            command: () => {
              this.redirectToBlankPage('https://www.intergrip.nl/intergrip-handleidingen/');
            },
          },
          {
            label$: this.translateService.stream('intergrip.profile.faq'),
            icon: FontAwesomeIcon.QuestionCircle,
            visible: true,
            command: () => {
              this.redirectToBlankPage('https://intercom.help/intergrip-bv/nl/');
            },
          },
          {
            label$: this.translateService.stream('intergrip.news-messages.entity_name'),
            icon: FontAwesomeIcon.EnvelopeOpenText,
            visible: document.body.clientWidth <= screenSize,
            command: () => {
              this.navigateToNews();
            },
          },
          {
            label$: this.translateService.stream('intergrip.profile.notification-settings'),
            icon: FontAwesomeIcon.EnvelopeOpenText,
            visible: (!!this.permissionsService.getPermission('intergrip.notification-setting.show')
              || !!this.permissionsService.getPermission('intergrip.notification-setting.manage')),
            command: () => {
              this.navigateToNotifications();
            },
          },
          {
            label$: this.translateService.stream('intergrip.profile.sign-out.label'),
            icon: FontAwesomeIcon.SignOutAlt,
            visible: true,
            command: () => {
              this.logout();
            },
          },
          {
            label$: this.translateService.stream('intergrip.locale.change-language.label'),
            disabled: true,
          },
          ...locales.map((locale) => ({
              label$: of(locale.name),
              icon: FontAwesomeIcon.UserCircle,
              title: locale.code,
              visible: true,
              command: () => {
                this.localeService.saveCurrentUserLocale(this.currentUser.id, locale.id).subscribe((user: User) => {
                  this.userService.updateUserLocalStorage(locale);
                  this._document.defaultView.location.reload();
                });
              },
            })),
        ]),
    );
  }

  private redirectToBlankPage(url: string): void {
    window.open(url, '_blank');
  }
}
