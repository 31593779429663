import { Injectable } from '@angular/core';
import { User } from '@capturum/complete';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ImpersonateStateModel {
  originalUser: string;
  isImpersonating: boolean;
  user: User;
}

@Injectable({ providedIn: 'root' })
export class ImpersonateState {
  public state$: Observable<ImpersonateStateModel>;

  private state = new BehaviorSubject<ImpersonateStateModel>({
    isImpersonating: false,
    user: null,
    originalUser: null,
  });

  constructor() {
    this.state$ = this.state.asObservable();
  }

  public setState(state: ImpersonateStateModel): void {
    this.state.next(state);
  }

  public patchState(state: Partial<ImpersonateStateModel>): void {
    this.setState({ ...this.state.getValue(), ...state });
  }

  public setImpersonate(isImpersonating: boolean): void {
    this.setState({ ...this.state.getValue(), isImpersonating });
  }
}
