import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moduleImage'
})
export class ModuleImagePipe implements PipeTransform {
  public transform(moduleKey: string): string {
    return `./assets/images/module-logos/${moduleKey}.png`;
  }
}
