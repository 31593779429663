export enum TodoStudentPermissions {
  TodoStudent = 'todo-student',
  Transfer = 'transfer',
  TransferMbo = 'transfer-mbo',
  History = 'history',
  Municipality = 'municipality',
  Transfercoach = 'transfercoach',
  DiplomaStudent = 'diploma-student',
  RiskStudent = 'risk-student',
  MboChoiceStudentYear = 'mbo-choice-student-year',
  StudentDetails = 'student-details',
  BBL = 'bbl',
  Int1PotentialDropouts = 'potential-dropouts',
  RiskStatusesAtVo = 'risk-statuses-at-vo',
  MyStudentsEverAtLpRmc = 'my-students.ever-at-lprmc',
  MyStudentsBBLWorkPlace = 'my-students.work-place',
  PreferredChoice = 'preferred-choice',
  SoftMatch = 'soft-match',
  SpecialTransferInformation = 'special-transfer-information',
}
