import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="to.template && to.template | async as template">
      <ng-container *ngTemplateOutlet="template; context: {$implicit: formControl.value}">
      </ng-container>
    </ng-container>
  `,
})
export class HtmlFormlyTypeComponent extends FieldType {
}
