import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FavoriteItem } from '@core/components/header/favorite-item';
import { SchoolYear } from '@core/models/school-year.model';
import { RoleItem } from '@core/components/header/role-item.model';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
})
export class RoleSelectorComponent {
  get schoolYears(): SchoolYear[] {
    return this._schoolYears;
  }

  @Input()
  set schoolYears(value: SchoolYear[]) {
    this._schoolYears = value;

    this.activeYear = value.find((year) => {
      return year.roles.some((role) => role.active);
    });

    if (!this.activeYear) {
      this.activeYear = value[0];
    }
  }

  @Output()
  public onSelectRole = new EventEmitter<RoleItem>();

  @Output()
  public onFavoriteRole = new EventEmitter<string>();

  public activeYear: FavoriteItem;
  public fontAwesomeIcon = FontAwesomeIcon;

  private _schoolYears: SchoolYear[];

  public setRole(role: RoleItem): void {
    this.onSelectRole.emit(role);
  }

  public setActiveYear(year: SchoolYear): void {
    this.activeYear = year;
  }

  public updateFavoriteRole(event: Event, id: string): void {
    event.stopImmediatePropagation();

    this._schoolYears = this._schoolYears.map((schoolYear) => {
      return {
        ...schoolYear, roles: schoolYear.roles.map((role) => {
          return { ...role, favorite: role.id === id };
        }),
      };
    });

    this.activeYear = this._schoolYears.find((year) => year.year === this.activeYear.year);

    this.onFavoriteRole.emit(id);
  }
}
