import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {FieldType} from "@ngx-formly/core";

@Component({
    selector: 'app-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputRadioComponent),
            multi: true
        }
    ]
})
export class InputRadioComponent extends FieldType{
    value: any;

    handleChange(value: any) {
        this.value = value;
    }
}
