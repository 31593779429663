import { HttpErrorResponse } from '@angular/common/http';
import type { ErrorHandler as AngularErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { SentryService } from './sentry.service';
import { addExceptionMechanism, isString } from '@sentry/utils';

type ErrorCandidate = {
    name?: unknown;
    message?: unknown;
    stack?: unknown;
};

@Injectable({ providedIn: 'root' })
class SentryErrorHandler implements AngularErrorHandler {

    constructor(private service: SentryService) { }

    /**
     * Method called for every value captured through the ErrorHandler
     */
    public handleError(error: any): void {
        const chunkFailedPattern = /Loading chunk [\d]+ failed/;
        const keyFilterGetDataError = `.onPaste`; //Lastpass and PrimeNG together throw this error
        const dataCloneError = `DataCloneError`;
        const windowPostMessageError = `WindowPostMessageProxy`;
        const webkitError = 'webkit';
        const maskedError = 'masked';
        const generatorError = "Generator";
        const dexieError = "dexie";


        if (!error) {
            return;
        }

        if (chunkFailedPattern.test(error.message)) {
            this.reloadWindow();
            return;
        }

        if (error.name === 'HttpErrorResponse' || error instanceof HttpErrorResponse) {
            return;
        }

        const extractedError = this._extractError(error);

        if (!extractedError) {
            return;
        }

        if (
            error.stack?.includes(keyFilterGetDataError) ||
            error.message?.includes(keyFilterGetDataError) ||
            error.stack?.includes(dataCloneError) ||
            error.message?.includes(dataCloneError) ||
            error.stack?.includes(windowPostMessageError) ||
            error.message?.includes(windowPostMessageError) ||
            error.stack?.includes(generatorError) ||
            error.message?.includes(generatorError) ||
            error.stack?.includes(maskedError) ||
            error.message?.includes(maskedError) ||
            error.stack?.includes(webkitError) ||
            error.message?.includes(webkitError) ||
            error.stack?.includes(dexieError) ||
            error.message?.includes(dexieError)) {
            return;
        }

        if (
            error.name === 'EmptyError' ||
            error.name === '<unknown>' ||
            error.title === 'unknown'
        ) {
            if ('/auth/two-factor-activation' === window.location.pathname) {
                return;
            }

            this.service.captureException(
                `${window.location.pathname} ${error.name}`
            );
            return;
        }

        // Capture handled exception and send it to Sentry.
        this.service.captureException(extractedError, (scope) => {
            scope.addEventProcessor((event) => {
                addExceptionMechanism(event, {
                    type: 'angular',
                    handled: false,
                });

                return event;
            });

            return scope;
        });

        console.error(extractedError);
    }

    public reloadWindow() {
        window.location.reload();
    }

    /**
     * Default implementation of error extraction that handles default error wrapping, HTTP responses,
     * ErrorEvent and few other known cases.
     */
    protected _extractError(errorCandidate: unknown): unknown {
        const error = this.tryToUnwrapZonejsError(errorCandidate);

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || this.isErrorOrErrorLikeObject(error)) {
            return error;
        }

        return null;
    }

    private tryToUnwrapZonejsError(error: unknown): unknown | Error {
        return error && (error as { ngOriginalError: Error }).ngOriginalError
            ? (error as { ngOriginalError: Error }).ngOriginalError
            : error;
    }

    private isErrorOrErrorLikeObject(value: unknown): value is Error {
        if (value instanceof Error) {
            return true;
        }

        if (value === null || typeof value !== 'object') {
            return false;
        }

        const candidate = value as ErrorCandidate;

        return (
            isString(candidate.name) &&
            isString(candidate.message) &&
            (undefined === candidate.stack || isString(candidate.stack))
        );
    }
}

export { SentryErrorHandler };
