import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from '@features/auth/services/auth.service';
import { TwoFactorForm } from '@features/user/interfaces/two-factor-form.interface';
import { CapturumDialogService, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { StyleClass } from '@core/enums/style-class.enum';
import { RightSidebarComponent } from '@shared/components/right-sidebar/right-sidebar.component';
import { filter, first } from 'rxjs/operators';
import { SidebarAction } from '@core/enums/sidebar-actions.enum';
import { TwoFactorFormComponent } from '@shared/components/two-factor/two-factor-form/two-factor-form.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-two-factor-activation',
  templateUrl: './2fa-activation.component.html',
  styleUrls: ['./2fa-activation.component.scss'],
  providers: [CapturumDialogService],
  encapsulation: ViewEncapsulation.None,
})
export class TwoFAActivationComponent {
  public twoFactorFormValue: TwoFactorForm;
  private dialogRef: DynamicDialogRef;

  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private dialogService: CapturumDialogService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  public backToLogin(): void {
    localStorage.removeItem('tempToken');
    this.router.navigate(['../auth/login']);
  }

  public openSideBar(): void {
    this.dialogRef = this.dialogService.open(
      TwoFactorFormComponent,
      {
        data: {
          title: this.translateService.instant('auth.2fa.title'),
          showButtons: true,
          submitButtonStyleClass: StyleClass.Primary,
          autoSubmit: () => this.submit2fa(),
          onChange: (value) => this.setTwoFactorFormValue(value),
        },
        header: this.translateService.instant('auth.2fa.title'),
      },
      RightSidebarComponent,
    );

    this.dialogRef.onClose.pipe(
      filter((value) => value !== SidebarAction.submit),
      first(),
    ).subscribe(() => {
        this.cdr.markForCheck();
        this.dialogRef?.destroy();
        this.backToLogin();
    });
  }

  public submit2fa(): void {
    this.twoFactorAuthService.confirm2faForce(this.twoFactorFormValue.method, this.twoFactorFormValue.key).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('auth.2fa.toast.success'),
      );
      this.dialogRef?.destroy();
      this.cdr.markForCheck();
      this.backToLogin();
    });
  }

  private setTwoFactorFormValue(value: TwoFactorForm): void {
    this.twoFactorFormValue = value;
  }
}
