export enum Modules {
  Intergrip = 'manage',
  TransferVo = 'transfer-vo',
  TransferMbo = 'transfer-mbo',
  Ddd = 'ddd',
  Lobdossier = 'lobdossier',
  LobActivities = 'lobactivities',
  Activity = 'activity',
  Assignment = 'assignment',
  LobVoorlichtingen = 'zoekjouwstudie',
  Monitorus = 'monitorus',
  IntergripModule = 'intergrip',
  KovPo = 'kov-po',
  PreferredChoice = 'preferred-choice',
  Inflow = 'inflow',
  DataSync = 'data-sync',
  SpecialTransfer = 'special-transfer',
  DiscussionRound = 'discussion-round',
  FollowUpChoiceCard = 'follow-up-choice-card',
  CertificateCard = 'certificate-card',
  Jikp = 'jikp',
  DashboardRMC = 'dashboard-rmc',
  DashboardUS = 'dashboard-us',
  InternshipAssignments = 'internship-assignments',
  Maatwerk = 'maatwerk',
  Keuzedelen = 'keuzedelen',
  Stages = 'stages',
  ELearning = 'e-learning',
  Planning = 'planning',
  Verzuim = 'verzuim',
  Dashboarding = 'dashboarding',
  Dossier = 'dossier',
  Mijn = 'mijn',
  StudentPortal = 'student-portal',
  YouthCareMonitor = 'youth-care-monitor',
  Portal = 'portal',
  DashboardOCW = 'dashboard-ocw',
  SocialMap = 'social-map',
}

export enum ModuleIconMap {
  vombo = 'fas fa-sync',
  mbombo = 'fas fa-sync',
  ddd = 'fas fa-file-user',
  lob = 'fas fa-file-user',
  lobdossier = 'fas fa-file-user',
  lobactivities = 'fas fa-file-user',
  zoekjouwstudie = 'fas fa-file-user',
  monitorus = 'fas fa-file-user',
  'student-portal' = 'fas fa-graduation-cap',
}

export enum TranslationModules {
  manage = 'intergrip',
  transfer  = 'transfer',
}
