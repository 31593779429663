import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private _event$: Subject<PointerEvent> = new Subject<PointerEvent>();

  constructor() { }

  public getEvent(): Observable<PointerEvent> {
    return this._event$.asObservable();
  }

  public setEvent(event: PointerEvent): void {
    this._event$.next(event);
  }
}
