import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import { CompleteResolver, IndexedDbGuard } from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { TwoFactorLoginComponent } from '@features/auth/two-factor-login/two-factor-login.component';
import { BasicLayoutComponent } from '@core/layout/basic-layout/basic-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Entities } from '@core/components/entities.enum';
import {
  TwoFactorResetMailResponseComponent,
} from '@features/auth/pages/two-factor-reset-mail-response/two-factor-reset-mail-response.component';
import { ResolvedPermissionsGuard } from '@shared/guards/resolved-permissions.guard';
import { UriUtils } from '@shared/utils/uri/uri.utils';
import { PageRoute } from '@core/enums/general/routes.enum';
import { ActivateUserComponent } from '@features/auth/pages/activate-user/activate-user.component';
import { CustomPublicTranslationResolver } from '@shared/resolvers/custom-public-translation.resolver';
import { Modules } from '@core/enums/modules.enum';
import { Permissions } from '@core/enums/permissions.enum';
import { LegacyComponent } from '@features/legacy/pages/legacy/legacy.component';
import arrayPath from '@shared/utils/array-path/array-path.utils';
import { UserPermissions } from '@features/user/user-permissions.enum';
import { InstancesPermissions } from '@features/instances/instances-permissions.enum';
import { CommunicationPerimssions } from '@features/communication/communication-perimssions.enum';
import { Integrip1Permissions } from '@features/intergrip1-manage/integrip1-permissions.enum';
import { TrainingsPermissions } from '@features/trainings/trainings-permissions.enum';
import { SignStatusPermissions } from '@features/sign-status/sign-status-permissions.enum';
import { InfoManagementPermissions } from '@features/info-management/info-management-permissions.enum';
import { PhasesPermissions } from '@features/phases/phases-permissions.enum';
import {
  LobActivityActivitiesPerimissions,
} from '@features/lob-activity-activities/lob-activity-activities-perimissions.enum';
import {
  LobActivityRegistrationPerimissions,
} from '@features/lob-activity-registrations/lob-activity-registration-perimissions.enum';
import { MboDossierPermissions } from '@features/mbo-dossier/mbo-dossier-permissions.enum';
import { SpecialTransfersPermissions } from '@features/special-transfers/special-transfers-permissions.enum';
import { VoReportPermissions } from '@features/vo-report/vo-report-permissions.enum';
import { SetupPermissions } from '@features/setup/setup-permissions.enum';
import { VoOppPermissions } from '@features/vo-opp/vo-opp-permissions.enum';
import { FormSettingsPermissions } from '@features/form-setting/form-settings-permissions.enum';
import { TransferPermissions } from '@features/transfers/transfer-permissions.enum';
import { ConnectionPermissions } from '@features/connection/connection-permissions.enum';
import { ToolsPermissions } from '@features/tools/tools-permissions.enum';
import { ImportPermissions } from '@features/import/import-permissions.enum';
import { TodoStudentPermissions } from '@features/todo-student/todo-student-permissions.enum';
import { BaseDataPermissions } from '@features/base-data/base-data-permissions.enum';
import { MonitorusReportsPermissions } from '@features/monitorus-reports/monitorus-reports-permissions.enum';
import { AssigmentPermissions } from '@features/assignment/assigment-permissions.enum';
import { LobAssignmentPermissions } from '@features/lob-assignment/lob-assignment-permissions.enum';
import { CarrierAssignmentPermissions } from '@features/carrier-assignment/carrier-assignment-permissions.enum';
import { HandedInAssignemntsPermissions } from '@features/handed-in-assignments/handed-in-assignemnts-permissions.enum';
import {
  LobActivityStudentsPerimissions,
} from '@features/lob-activity-my-students/lob-activity-students-perimissions.enum';
import { LobActivitySettingsPermissions } from '@features/lob-activity-settings/lob-activity-settings-permissions.enum';
import { OtherSettingsPermissions } from '@features/other-settings/other-settings-permissions.enum';
import { VoDossierPermissions } from '@features/vo-dossier/vo-dossier-permissions.enum';
import { EldPermissions } from '@features/eld/eld-permissions.enum';
import {
  Intergrip1ConnectionsPermissions,
} from '@features/intergrip1-connections/intergrip1-connections-permissions.enum';
import { FixesPermissions } from '@features/fixes/fixes-permissions.enum';
import { TransferMonitoringPermissions } from '@features/transfer-monitoring/transfer-monitoring.permissions.enum';
import { Int1AdminPermissions } from '@features/int1-admin/int1-admin-permissions.enum';
import { ActiesPermissions } from '@features/acties/acties-permissions.enum';
import { TransferReportPermissions } from '@features/transfer-reports/transfer-report-permissions.enum';
import { ChoiceSemesterPermissions } from '@features/choice-semester/choice-semester-permissions.enum';
import {
  InternshipAssignmentsPermissions,
} from '@features/internship-assignments/internship-assignments-permissions.enum';
import { PronexusPermissions } from '@features/connections-management/pronexus-permissions.enum';
import { SalesRequestsPermissions } from '@features/sales-requests/sales-requests-permissions.enum';
import { TwoFAActivationComponent } from '@features/auth/2fa-activation/2fa-activation.component';
import { RmcReportsComponent } from '@features/dashboard-rmc-reports/pages/reports/rmc-reports/rmc-reports.component';
import { ReportsComponent } from '@features/dashboard-us/pages/reports/reports.component';
import {
  DashboardUsUploadedFilesComponent,
} from '@features/dashboard-us/pages/dashboard-us-uploaded-files/dashboard-us-uploaded-files.component';
import { VoUsersPermissions } from '@features/vo-users/vo-users-permissions.enum';;
import {KovPoPermissions} from "@features/kov-po/kov-po-permissions";
import {SocialMapPermissions} from "@features/social-map/social-map-permissions.enum";

const routes: Routes = [
  {
    path: 'guest',
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: CustomPublicTranslationResolver,
    },
    loadChildren: () => import('./features/guest/guest.module').then((m) => m.GuestModule),
  },
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: CustomPublicTranslationResolver,
    },
    children: [
      {
        path: 'disable/:token',
        component: TwoFactorResetMailResponseComponent,
      },
      {
        path: 'user/:id/activate',
        component: ActivateUserComponent,
      },
      {
        path: 'two-factor',
        component: TwoFactorLoginComponent,
      },
      {
        path: 'login',
          loadChildren: () => import('./features/custom-login/custom-login.module').then((m) => m.CustomLoginModule),
      },
      {
        path: 'two-factor-activation',
        component: TwoFAActivationComponent,
      },
      ...loginRoutes[0].children,
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard, IndexedDbGuard],
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: '',
        canActivate: [ResolvedPermissionsGuard],
        component: BasicLayoutComponent,
        children: [
          {
            path: 'manage',
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: PageRoute.profile,
              },
              {
                path: Entities.Tenants,
                loadChildren: () => import('./features/tenant/tenant.module').then((m) => m.TenantModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Permissions.Tenant, Permissions.Manage]),
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.Roles,
                loadChildren: () => import('./features/role/role.module').then((m) => m.RoleModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Permissions.Role, Permissions.Manage, Permissions.Tenant]),
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.BaseData,
                loadChildren: () => import('./features/base-data/base-data.module').then((m) => m.BaseDataModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Permissions.BaseDataValue, Permissions.Manage, Permissions.Tenant]),
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.Students,
                loadChildren: () => import('./features/student/student.module').then((m) => m.StudentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, Permissions.Student, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, Permissions.Student, Permissions.Manage]),
                      arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Show]),
                      arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Manage]),
                    ],
                    except: [
                      arrayPath([Modules.TransferVo, Permissions.TransferCoach, UserPermissions.User, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.Users),
                  },
                },
              },
              {
                path: Entities.Children,
                loadChildren: () => import('./features/children/children.module').then((m) => m.ChildrenModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [
                            arrayPath([Modules.KovPo, KovPoPermissions.Child, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(Entities.Users),
                    },
                },
              },
              {
                path: Entities.Users,
                loadChildren: () => import('./features/user/user.module').then((m) => m.UserModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, UserPermissions.User, Permissions.Show]),
                      arrayPath([UserPermissions.User, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.TransferCoach, UserPermissions.User, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.Instances),
                  },
                },
              },
              {
                path: Entities.Instances,
                loadChildren: () => import('./features/instances/instances.module').then((m) => m.InstancesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, InstancesPermissions.Municipality, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, Permissions.School, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, InstancesPermissions.Region, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, InstancesPermissions.MboInstitution, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, InstancesPermissions.VoInstitution, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, InstancesPermissions.VoSchoolGroup, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.Communications),
                  },
                },
              },
              {
                path: Entities.Communications,
                loadChildren: () => import('./features/communication/communication.module').then((m) => m.CommunicationModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, CommunicationPerimssions.RecipientGroup, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, CommunicationPerimssions.Template, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, CommunicationPerimssions.Communication, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.OtherManagement),
                  },
                },
              },
              {
                path: Entities.intergrip1Monitoring,
                loadChildren: () => import('./features/monitoring/monitoring.module').then((m) => m.MonitoringModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Modules.IntergripModule, Integrip1Permissions.Monitoring, Permissions.Show]),
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.intergrip1Manage,
                loadChildren: () => import('./features/intergrip1-manage/intergrip1-manage.module').then((m) => m.Intergrip1ManageModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Modules.IntergripModule, Integrip1Permissions.Manage, Permissions.Show]),
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.Sales,
                loadChildren: () => import('./features/sales-requests/sales-requests.module').then((m) => m.SalesRequestsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Manage]),
                      arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.List]),
                    ],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: 'other-settings',
                loadChildren: () => import('./features/other-settings/other-settings.module').then((m) => m.OtherSettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, OtherSettingsPermissions.Translation, Permissions.Manage, Permissions.Tenant]),
                      arrayPath([Modules.IntergripModule, OtherSettingsPermissions.Setting, Permissions.Manage, Permissions.Tenant]),
                      arrayPath([Modules.IntergripModule, Permissions.BaseDataValue, Permissions.Manage, Permissions.Tenant]),
                    ],
                    redirectTo: PageRoute.profile,
                  },
                },
              },
              {
                path: Entities.ConnectionsManagement,
                loadChildren: () => import('./features/connections-management/connections-management.module').then((m) => m.ConnectionsManagementModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusService, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusService, Permissions.Manage]),
                      arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusIncomingService, Permissions.Show]),
                      arrayPath([Modules.TransferVo, PronexusPermissions.PronexusIncomingService, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusOutgoingService, Permissions.Show]),
                      arrayPath([Modules.TransferVo, PronexusPermissions.PronexusOutgoingService, Permissions.Show]),
                    ],
                    redirectTo: PageRoute.profile,
                  },
                },
              },
              {
                path: 'config',
                loadChildren: () => import('./features/config/config.module').then((m) => m.ConfigModule),
              },
              {
                path: Entities.Module,
                loadChildren: () => import('./features/module/module.module').then((m) => m.ModuleModule),
              },
              {
                path: Entities.MboCheck,
                loadChildren: () => import('./features/mbo-check/mbo-check.module').then((m) => m.MboCheckModule),
              },
              {
                path: 'profile',
                loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
              },
              {
                path: 'notification-settings',
                loadChildren: () => import('./features/notification-settings/notification-settings.module').then((m) => m.NotificationSettingsModule),
              },
              {
                path: Entities.MboCheckService,
                loadChildren: () => import('./features/mbo-check-service/mbo-check-service.module').then((m) => m.MboCheckServiceModule),
              },
              {
                path: Entities.Trainings,
                loadChildren: () => import('./features/trainings/trainings.module').then((m) => m.TrainingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.IntergripModule, TrainingsPermissions.RioConnections, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, TrainingsPermissions.Sector, Permissions.Show]),
                      arrayPath([Modules.IntergripModule, TrainingsPermissions.Sector, Permissions.Manage]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.Students),
                  },
                },
              },
            ],
          },
          {
            path: 'transfer-mbo',
            children: [
              {
                path: Entities.TodoStudents,
                loadChildren: () => import('./features/mbo-todo-student/mbo-todo-student.module').then((m) => m.MboTodoStudentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferMbo, Permissions.Mbo, Permissions.TodoStudent, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, Permissions.Mbo, Permissions.Student, Permissions.Show]),

                      arrayPath([Modules.TransferVo, TodoStudentPermissions.SoftMatch, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, TodoStudentPermissions.SoftMatch, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TodoStudentPermissions.SoftMatch, Permissions.Manage]),
                      arrayPath([Modules.TransferMbo, TodoStudentPermissions.SoftMatch, Permissions.Manage]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.reports, Modules.TransferMbo),
                  },
                },
              },
              {
                path: Entities.ServicePoint,
                loadChildren: () =>
                  import(
                    '@features/transfer-servicepoints/transfer-servicepoints.module'
                  ).then((m) => m.TransferServicePointsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([
                        Modules.TransferVo,
                        Permissions.Transfer_service_point,
                        Permissions.Show,
                      ]),
                      arrayPath([
                        Modules.TransferMbo,
                        Permissions.Transfer_service_point,
                        Permissions.Show,
                      ]),
                      arrayPath([
                        Modules.TransferMbo,
                        Permissions.Transfer_service_point,
                        Permissions.Manage,
                      ]),
                      arrayPath([
                        Modules.TransferMbo,
                        Permissions.Transfer_service_point,
                        Permissions.Manage_All,
                      ]),
                      arrayPath([
                        Modules.TransferVo,
                        Permissions.Transfer_service_point,
                        Permissions.Manage_All,
                      ]),
                      arrayPath([
                        Modules.TransferVo,
                        Permissions.Transfer_service_point,
                        Permissions.Manage,
                      ]),
                    ],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.reports,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/mbo-reports/mbo-reports.module').then((m) => m.MboReportsModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferMbo, Permissions.MboMbo, Permissions.ChoiceStudentYear, Permissions.Export]),
                      arrayPath([Modules.TransferMbo, Permissions.MboMbo, Permissions.BestChoiceStudentYear, Permissions.Export]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.Students),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.TodoStudents, Modules.TransferMbo),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'transfer-vo',
            children: [
              {
                path: Entities.Phases,
                loadChildren: () => import('./features/phases/phases.module').then((m) => m.PhasesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.SignupPhase, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.SignupPhase, Permissions.Manage]),
                    ],
                    redirectTo: UriUtils.list(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.MboSpecialTransfers,
                loadChildren: () => import('./features/mbo-special-transfers/mbo-special-transfers.module').then((m) => m.MboSpecialTransfersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.SpecialTransfer, Permissions.AdminStudent, Permissions.Show]),
                    ],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.SignupStatus,
                loadChildren: () => import('./features/sign-status/signup-status.module').then((m) => m.SignupStatusModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Modules.TransferVo, SignStatusPermissions.SignupStatus, Permissions.Show]),
                    redirectTo: UriUtils.submenu(Entities.eld, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.ServicePoint,
                loadChildren: () => import('@features/transfer-servicepoints/transfer-servicepoints.module').then((m) => m.TransferServicePointsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Manage]),
                      arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Manage_All]),
                      arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Manage_All]),
                      arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Manage]),
                    ],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.DiscussionRound,
                loadChildren: () => import('./features/discussion-round/discussion-round.module').then((m) => m.DiscussionRoundModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Show]),
                      arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Manage]),
                      arrayPath([Modules.DiscussionRound, Permissions.StudentYear, Permissions.Show]),
                      arrayPath([Modules.DiscussionRound, Permissions.StudentYear, Permissions.Manage]),
                    ],
                  },
                },
              },
              {
                path: Entities.TodoStudents,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/todo-student/todo-student.module').then((m) => m.TodoStudentModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.TodoStudent, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, Permissions.TodoStudent, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.Student, Permissions.Show]),
                      arrayPath([Modules.TransferMbo, Permissions.Student, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.Int1Students, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TodoStudentPermissions.BBL, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.WarmTransfer, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TodoStudentPermissions.Int1PotentialDropouts, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.EverAtLprmc, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.BBLWorkplace, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TodoStudentPermissions.RiskStatusesAtVo, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.list(Entities.Students),
                  },
                },
              },
              {
                path: Entities.reports,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/transfer-reports/transfer-reports.module').then((m) => m.TransferReportsModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.ChoiceStudentYear, Permissions.Export]),
                      arrayPath([Modules.TransferVo, Permissions.ChoiceStudentYear, Permissions.BestChoiceExport]),
                      arrayPath([Modules.TransferVo, Permissions.MboChoiceStudentYear, Permissions.Export]),
                      arrayPath([Modules.TransferVo, Permissions.Int1Reports, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.MboCheckYearOverview, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.EverAtLprmc, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.Int1SubExport, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.ProgressVmbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferReportPermissions.ReportsProgressVmbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferReportPermissions.OutflowHistory, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferReportPermissions.ProcessedMboChecks, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.ProgressVmbo, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.list(Entities.TodoStudents, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.eld,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/eld/eld.module').then((m) => m.EldModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, EldPermissions.ImportLog, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.TodoStudents, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.int1connections,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/intergrip1-connections/intergrip1-connections.module').then((m) => m.Intergrip1ConnectionsModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Intergrip1ConnectionsPermissions.ProNexus, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.list(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.fixes,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/fixes/fixes.module').then((m) => m.FixesModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, FixesPermissions.ChoiceDeduplicator, Permissions.Show]),
                      arrayPath([Modules.TransferVo, FixesPermissions.ChoiceDeleter, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.list(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.int1export,
                canActivate: [NgxPermissionsGuard],
                component: LegacyComponent,
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.Int1Export, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.int1Settings,
                canActivate: [NgxPermissionsGuard],
                component: LegacyComponent,
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Permissions.Int1Setting, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.intergrip1Monitoring,
                loadChildren: () => import('./features/transfer-monitoring/transfer-monitoring.module').then((m) => m.TransferMonitoringModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringProgressVmbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringProgressMbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.CheckMboSoftCheck, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringMboCheckYearOverview, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.WarmTransferPerVmbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.WarmTransferPerMbo, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Permissions.BBLWorkplace, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferMonitoringPermissions.DashBoard, Permissions.Show]),
                      arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringInt1SubExport, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.admin,
                loadChildren: () => import('./features/int1-admin/int1-admin.module').then((m) => m.Int1AdminModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, Int1AdminPermissions.Int1Phases, Permissions.Show]),
                      arrayPath([Modules.TransferVo, Int1AdminPermissions.DiscussionRound, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: Entities.acties,
                loadChildren: () => import('./features/acties/acties.module').then((m) => m.ActiesModule),
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.TransferVo, ActiesPermissions.ActionsCheckMboSoftCheck, Permissions.Show]),
                      arrayPath([Modules.TransferVo, ActiesPermissions.AssignDiplomas, Permissions.Show]),
                      arrayPath([Modules.TransferVo, ActiesPermissions.PrintDiplomaCard, Permissions.Show]),
                      arrayPath([Modules.TransferVo, ActiesPermissions.SendChoiceForm, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.SignupStatus, Modules.TransferVo),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.list(Entities.Phases, Modules.TransferVo),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'zoekjouwstudie',
            children: [
              {
                path: Entities.lobVoorlichtingenSettings,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobVoorlichtingen, Permissions.Settings, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenManagement,
                loadChildren: () => import('./features/info-management/info-management.module').then((m) => m.InfoManagementModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobVoorlichtingen, InfoManagementPermissions.Management, Permissions.Show]),
                      arrayPath([Modules.LobVoorlichtingen, InfoManagementPermissions.ManagementAdmin, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenOffer,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobVoorlichtingen, Permissions.Offer, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenPhases,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, PhasesPermissions.Phases, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenPhases, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenMyStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, Permissions.MyStudents, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenActivities,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, LobActivityActivitiesPerimissions.Activities, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenSearch, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenEducation,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, Permissions.Search, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenRegistrations, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenRegistrations,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, LobActivityRegistrationPerimissions.Registrations, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenSort, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenSort,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobVoorlichtingen, Permissions.Sort, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenSettings, Modules.LobVoorlichtingen),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.LobVoorlichtingen),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'ddd',
            children: [
              {
                path: Entities.Dashboard,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.training,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Dashboard, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.DossierConnection,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.training,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.DossierConnection, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.mboDossiers,
                loadChildren: () => import('./features/mbo-dossier/mbo-dossier.module').then((m) => m.MboDossierModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, MboDossierPermissions.MboDossier, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.reports, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.voDossiers,
                loadChildren: () => import('./features/vo-dossier/vo-dossier.module').then((m) => m.VoDossierModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, VoDossierPermissions.VoDossier, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.mboDossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.specialTransfers,
                loadChildren: () => import('./features/special-transfers/special-transfers.module').then((m) => m.SpecialTransfersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Ddd, SpecialTransfersPermissions.MboSpecialTransfer, Permissions.Show]),
                      arrayPath([Modules.Ddd, SpecialTransfersPermissions.VoSpecialTransfer, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.training,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.training,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, TrainingsPermissions.Training, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.setting,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.setting,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.MboSettings, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.subMenuSettings,
                loadChildren: () => import('./features/intergrip1-setting/intergrip1-setting.module').then((m) => m.Intergrip1SettingModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.setting,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Settings, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.pipeline,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.pipeline,
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Pipeline, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.reports,
                loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Report, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.voReports,
                loadChildren: () => import('./features/vo-report/vo-report.module').then((m) => m.VoReportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, VoReportPermissions.VoReport, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.setup,
                loadChildren: () => import('./features/setup/setup.module').then((m) => m.SetupModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, SetupPermissions.Setup, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.opps,
                loadChildren: () => import('./features/vo-opp/vo-opp.module').then((m) => m.VoOppModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, VoOppPermissions.VoOpp, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.myStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Student, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.institutions,
                loadChildren: () => import('./features/institutions/institutions.module').then((m) => m.InstitutionsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, InstancesPermissions.MboInstitution, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.SocialMap,
                loadChildren: () => import('@features/social-map/social-map.module').then((m) => m.SocialMapModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                      only: [
                          arrayPath([Modules.SocialMap, SocialMapPermissions.SocialMapContact, Permissions.Show]),
                          arrayPath([Modules.SocialMap, SocialMapPermissions.SocialMapContact, Permissions.Manage]),
                          arrayPath([Modules.SocialMap, SocialMapPermissions.SocialMapAvailability, Permissions.Show]),
                          arrayPath([Modules.SocialMap, SocialMapPermissions.SocialMapAvailability, Permissions.Manage]),
                      ],
                      redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.Users,
                loadChildren: () => import('@features/vo-users/vo-users.module').then((m) => m.VoUsersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, VoUsersPermissions.Users, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.dossiers,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, Permissions.Dossier, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.voDossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.opp,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, VoOppPermissions.Opp, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.specialTransferManage,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, SpecialTransfersPermissions.SpecialTransfer, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.formSettings,
                loadChildren: () => import('./features/form-setting/form-setting.module').then((m) => m.FormSettingModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, FormSettingsPermissions.FormSetting, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.transfers,
                loadChildren: () => import('./features/transfers/transfers.module').then((m) => m.TransfersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, TransferPermissions.Transfer, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.connections,
                loadChildren: () => import('./features/connection/connection.module').then((m) => m.ConnectionModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, ConnectionPermissions.Connection, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: Entities.tools,
                loadChildren: () => import('./features/tools/tools.module').then((m) => m.ToolsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Ddd, ToolsPermissions.Tool, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.monitorus,
            children: [
              {
                path: Entities.List,
                loadChildren: () => import('./features/monitorus-lists/monitorus-lists.module').then((m) => m.MonitorusListsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, Permissions.List, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusList, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusImport,
                loadChildren: () => import('./features/import/import.module').then((m) => m.ImportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Monitorus, ImportPermissions.Import, Permissions.Show]),
                      arrayPath([Modules.Monitorus, ImportPermissions.ImportFiles, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.monitorusReportsSubmenu, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.Info,
                loadChildren: () => import('./features/monitorus-info/monitorus-info.module').then((m) => m.MonitorusInfoModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, Permissions.Info, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusStudentDetails, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusStudentDetails,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, TodoStudentPermissions.StudentDetails, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusStudentDetails, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusData,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, BaseDataPermissions.Data, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusData, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.Settings,
                loadChildren: () => import('./features/monitorus-settings/monitorus-settings.module').then((m) => m.MonitorusSettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, Permissions.Settings, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusReportsSubmenu, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusReportsSubmenu,
                loadChildren: () => import('./features/monitorus-reports/monitorus-reports.module').then((m) => m.MonitorusReportsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Monitorus, MonitorusReportsPermissions.Reports, Permissions.Show]),
                      arrayPath([Modules.Monitorus, MonitorusReportsPermissions.MboReports, Permissions.Show]),
                      arrayPath([Modules.Monitorus, MonitorusReportsPermissions.VoReports, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.monitorusData, Modules.Monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusImportFiles,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Monitorus, Permissions.ImportFiles, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.monitorusImportFiles, Modules.Monitorus),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.monitorusImport, Modules.Monitorus),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.lobDossier,
            children: [
              {
                path: Entities.lobManagement,
                loadChildren: () => import('./features/management/management.module').then((m) => m.ManagementModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, InfoManagementPermissions.Management, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobdossierMyStudents, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.assignment,
                loadChildren: () => import('./features/assignment/assignment.module').then((m) => m.AssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, AssigmentPermissions.Assigment, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobdossierMyStudents, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.lobAssignment,
                loadChildren: () => import('./features/lob-assignment/lob-assignment.module').then((m) => m.LobAssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentAll, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentReceived, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentShared, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentOverview, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentArchived, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.lobdossierMyStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Lobdossier, Permissions.MyStudents, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobAssignment, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.carrierAssignment,
                loadChildren: () => import('./features/carrier-assignment/carrier-assignment.module').then((m) => m.CarrierAssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, CarrierAssignmentPermissions.CarrierAssigment, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, CarrierAssignmentPermissions.MboCarrierAssigment, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.handedInAssignments,
                loadChildren: () => import('./features/handed-in-assignments/handed-in-assignments.module').then((m) => m.HandedInAssignmentsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, HandedInAssignemntsPermissions.VoHandedInAssignments, Permissions.Show]),
                      arrayPath([Modules.Lobdossier, HandedInAssignemntsPermissions.MboHandedInAssignments, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.ChoiceSemester,
                loadChildren: () => import('./features/choice-semester/choice-semester.module').then((m) => m.ChoiceSemesterModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, ChoiceSemesterPermissions.ChoiceSemester, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.InternshipAssignments,
                loadChildren: () => import('./features/internship-assignments/internship-assignments.module').then((m) => m.InternshipAssignmentsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.InternshipAssignments, InternshipAssignmentsPermissions.InternshipAssignments, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.notAssigned,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.Lobdossier, HandedInAssignemntsPermissions.HandedInAssignments, HandedInAssignemntsPermissions.NotAssigned, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.TaskList,
                loadChildren: () => import('./features/task-list/task-list.module').then((m) => m.TaskListModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Lobdossier, Permissions.TaskList, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.Lobdossier),
                  },
                },
              },
              {
                path: Entities.Report,
                loadChildren: () => import('./features/student-assigment-reports/student-assigment-reports.module').then((m) => m.StudentAssigmentReportsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Lobdossier, Permissions.AssignmentReport, Permissions.Show])],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.Report,
                loadChildren: () => import('./features/class-overview/class-overview.module').then((m) => m.ClassOverviewModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [],
                    redirectTo: '/',
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.lobManagement, Modules.Lobdossier),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.lobActivities,
            children: [
              {
                path: Entities.offerReact,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Modules.LobActivities, Permissions.Offer, Permissions.Show]),
                    redirectTo: {
                      'lobactivities.offer.show': UriUtils.submenu(Entities.import, Modules.LobActivities),
                      default: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                    },
                  },
                },
              },
              {
                path: Entities.activityOverview,
                loadChildren: () => import('./features/lob-activity-activities/lob-activity-activities.module').then((m) => m.LobActivityActivitiesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobActivities, LobActivityActivitiesPerimissions.Activities, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.myStudentTab,
                loadChildren: () => import('./features/lob-activity-my-students/lob-activity-my-students.module').then((m) => m.LobActivityMyStudentsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobActivities, LobActivityStudentsPerimissions.AdminMyStudents, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.registrations,
                loadChildren: () => import('./features/lob-activity-registrations/lob-activity-registrations.module').then((m) => m.LobActivityRegistrationsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.Registrations, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.MboRegistrations, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.VoRegistrations, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.student,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.Registrations, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.MboRegistrations, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.VoRegistrations, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.Settings,
                loadChildren: () => import('./features/lob-activity-settings/lob-activity-settings.module').then((m) => m.LobActivitySettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.LobActivities, Permissions.Settings, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivitySettingsPermissions.VoSettings, Permissions.Show]),
                      arrayPath([Modules.LobActivities, LobActivitySettingsPermissions.AdminSettings, Permissions.Show]),
                    ],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.import,
                loadChildren: () => import('./features/lob-activity-import/lob-activity-import.module').then((m) => m.LobActivityImportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobActivities, ImportPermissions.Import, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: Entities.intergrip_students,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.LobActivities, Permissions.Students, Permissions.Show])],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.offerReact, Modules.LobActivities),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.Instroom,
            children: [
              {
                path: Entities.Signups,
                loadChildren: () => import('./features/signups/signups.module').then((m) => m.SignupsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [arrayPath([Modules.Inflow, Permissions.ChoiceStudentYear, Permissions.Show])],
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.Signups, Modules.Inflow),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.DashboardUS,
            children: [
              {
                path: Entities.DashboardReports,
                children: [
                  {
                    path: Entities.DashboardUSStudentLevel,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'Leerlingniveau',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.vo, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevel}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSSchoolLevel,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'Schoolniveau',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.vo, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMBO}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSSchoolLevelRegional,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'Schoolniveau - zonder regionaal',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMBO}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSMBORegional,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'MBO - zonder regionaal',
                      permissions: {
                        only: [

                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSGraduated}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSMBO,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'MBO',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSGraduated}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSGraduated,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'Diploma behaald',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSVoReport}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSGraduatedRegional,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'Diploma behaald - zonder regionaal',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSVoReport}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSVoReport,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'VO Overzicht',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMboReport}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSMboReport,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'MBO Overzicht',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSMboDownloadReport,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                      reportPageName: 'MBO-levering uitleg',
                      permissions: {
                        only: [
                          arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Manage]),
                        ],
                        redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`, Modules.DashboardUS),
                      },
                    }
                  },
                  {
                    path: Entities.DashboardUSTotalDataOverview,
                    component: ReportsComponent,
                    canActivate: [NgxPermissionsGuard],
                    data: {
                        reportPageName: 'Totaaloverzicht gegevens',
                        permissions: {
                            only: [
                                arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
                                arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                                arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Manage]),
                            ],
                            redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`, Modules.DashboardUS),
                        },
                    }
                  },
                ],
              },
              {
                path: Entities.UploadedFiles,
                component: DashboardUsUploadedFilesComponent,
              },
              {
                path: Entities.DashboardSubMenu,
                loadChildren: () => import('./features/dashboard-us/dashboard-us.module').then((m) => m.DashboardUsModule),
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`, Modules.DashboardUS),
                pathMatch: 'full',
              },
            ],
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.vo, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Permissions.Manage]),
                ],
              },
            }
          },
          {
            path: Entities.DashboardRMC,
            children: [
              {
                path: Entities.DashboardReports,
                children: [
                  {
                    path: '',
                    component: RmcReportsComponent
                  },
                  {
                    path: Entities.DashboardRmcVsvFactsheet,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Factsheet' }
                  },
                  {
                    path: Entities.DashboardRmcVsvProgress,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Voortgang' }
                  },
                  {
                    path: Entities.DashboardRmcVsvEducation,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | ➜ Onderwijs' }
                  },
                  {
                    path: Entities.DashboardRmcVsvWork,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | ➜ Arbeid' }
                  },
                  {
                    path: Entities.DashboardRmcVsvOther,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | ➜ Anders' }
                  },
                  {
                    path: Entities.DashboardRmcVsvTargets,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Streefcijfers' }
                  },
                  {
                    path: Entities.DashboardRmcVsvRegionalWorklists,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Regionale werklijst(en)' }
                  },
                  {
                    path: Entities.DashboardRmcExportVsvRegionalWorklists,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Export Regionale VSV werklijst' }
                  },
                  {
                    path: Entities.DashboardRmcJikpOverview,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Overzicht' }
                  },
                  {
                    path: Entities.DashboardRmcJikpEducation,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Onderwijs' }
                  },
                  {
                    path: Entities.DashboardRmcJikpWork,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Arbeid' }
                  },
                  {
                    path: Entities.DashboardRmcJikpDaycare,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Dagbesteding' }
                  },
                  {
                    path: Entities.DashboardRmcJikpOther,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Anders' }
                  },
                  {
                    path: Entities.DashboardRmcJikpRegionalWorklists,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Regionale werklijst(en)' }
                  },
                  {
                    path: Entities.DashboardRmcNoCDelivery,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'NietMeerInCLevering' }
                  },
                  {
                    path: Entities.DashboardRmcExportJikpRegionalWorklists,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'JIKP | Export Regionale JIKP werklijst' }
                  },
                  {
                    path: Entities.DashboardRmcExportVsv,
                    component: RmcReportsComponent,
                    data: { reportPageName: 'VSV | Exportbestand' }
                  },
                ],
              },
              {
                path: Entities.DashboardSubMenu,
                loadChildren: () => import('./features/dashboard-rmc-reports/dashboard-rmc-reports.module').then((m) => m.DashboardRmcReportsModule),
              },
              {
                path: Entities.UploadedFiles,
                loadChildren: () => import('./features/rmc-uploaded-files/rmc-uploaded-files.module').then((m) => m.RmcUploadedFilesModule),
              },
              {
                path: Entities.offerReact,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: arrayPath([Modules.LobActivities, Permissions.Offer, Permissions.Show]),
                    redirectTo: {
                      'lobactivities.offer.show': UriUtils.submenu(Entities.import, Modules.LobActivities),
                      default: UriUtils.submenu(Entities.lobActivities, Modules.LobActivities),
                    },
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvFactsheet}`, Modules.DashboardRMC),
                pathMatch: 'full',
              },
            ],
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
                ],
              },
            },
          },
          {
            path: Modules.YouthCareMonitor,
            loadChildren: () => import('./features/youth-care-students/youth-care-students.module').then((m) => m.YouthCareStudentsModule),
          },
          {
            path: Entities.My_Products,
            loadChildren: () => import('./features/my-products/my-products.module').then((m) => m.MyProductsModule),
          },
          {
            path: Modules.StudentPortal,
            children: [
              {
                path: '',
                redirectTo: `system-preference`,
                pathMatch: 'full',
              },
              {
                path: `${Entities.SystemPreference}`,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Show]),
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Manage]),
                    ]
                  },
                },
              },
              {
                path: `${Entities.setting}/${Entities.Vo}`,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.StudentPortal, Permissions.SystemPreferences, Permissions.Show]),
                      arrayPath([Modules.StudentPortal, Permissions.SystemPreferences, Permissions.Manage])],
                  },
                },
              },
              {
                path: `${Entities.setting}/${Entities.Choice}`,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Show]),
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Manage]),
                  ]
                  },
                },
              },
              {
                path: `${Entities.setting}/${Entities.Region}`,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Show]),
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Manage]),
                    ]
                  },
                },
              },
              {
                path: Entities.setting,
                loadChildren: () => import('./features/student-portal-settings/student-portal-settings.module').then((m) => m.StudentPortalSettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Show]),
                      arrayPath([Modules.StudentPortal, Permissions.Settings, Permissions.Manage]),
                    ],
                    redirectTo: '/',
                  },
                },
              },
            ]
          },
          {
            path: Entities.News,
            loadChildren: () => import('./features/news/news.module').then((m) => m.NewsModule),
          },
          {
            path: Entities.KovPo,
            loadChildren: () => import('./features/kov-po/kov-po.module').then((m) => m.KovPoModule),
          },
          {
            path: Entities.DashboardOCW,
            loadChildren: () => import('./features/dashboard-ocw/dashboard-ocw.module').then((m) => m.DashboardOCWModule),
          },
          {
            path: '**',
            redirectTo: Entities.My_Products,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: UriUtils.submenu(Entities.Students),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
