import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from '@capturum/api';
import { environment } from '@environments/environment';
import { AuthModule } from '@features/auth/auth.module';
import { AuthModule as CompleteAuthModule } from '@capturum/auth';
import { LoginModule } from '@capturum/login';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { SharedModule } from '@capturum/shared';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CompleteModule, ErrorMessageInterceptor } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { CommonModule } from '@angular/common';
import { CapturumWebsocketModule } from '@capturum/websockets/core';
import { PusherWebsocketService } from '@capturum/websockets/pusher';
import * as Sentry from '@sentry/angular-ivy';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
// Components
import { AppComponent } from './app.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumInputModule } from '@capturum/ui/input';
import { DropdownTypeComponent, FormModule } from '@capturum/formly';
import { AuthService } from './features/auth/services/auth.service';
import { IntLoginService } from './features/auth/services/int-login.service';
import { FormlyConfig, FormlyModule } from '@ngx-formly/core';
import { InputSwitchTypeComponent } from '@shared/formly/types/input-switch/input-switch.formly-type';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { ImageComponent } from '@shared/formly/types/image/image.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EditorFormlyTypeComponent } from '@shared/formly/types/editor/editor.formly-type';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CustomPublicTranslationResolver } from '@shared/resolvers/custom-public-translation.resolver';
import { MultiSelectFormlyTypeComponent } from '@shared/formly/types/multi-select/multi-select.formly-type';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { HtmlFormlyTypeComponent } from '@shared/formly/types/html/html-formly-type';
import { CustomFormlyConfig } from './formly-field';
import { IntTranslateParser } from '@shared/translate/int-translate-parser';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';
import { DateParserPipe } from '@shared/pipes/date-parser/date-parser.pipe';
import { ConfirmationService } from 'primeng/api';
import { FieldTypes } from '@core/enums/field-types.enum';
import { AppFormFieldComponent } from '@shared/formly/form-field/form-field-wrapper.component';
import { FormModule as CapturumFormModule } from '@capturum/formly';
import { CalendarTypeComponent } from '@shared/formly/types/calendar/calendar.component';
import { TranslationLoader } from '@core/translate/translation-loader';
import { SentryErrorHandler } from '@core/sentry/error-handler';
import {InputRadioComponent} from "@shared/formly/types/input-radio/input-radio.component";
import {TimeTableTypeComponent} from "@shared/formly/types/time-table/time-table.component";

registerLocaleData(localeNl, 'nl-NL');
function onAuthError(): void {
  window.location.href = `auth/login`;
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return `[[ ${params.key} ]]`;
  }
}

function intPasswordValidator(control: AbstractControl): ValidationErrors {
  const passwordRegex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{8,}$');

  return !control.value || passwordRegex.test(control.value) ? null : { password: true };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    CommonModule,
    CapturumInputModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumFormModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
      parser: {
        provide: TranslateParser,
        useClass: IntTranslateParser,
      },
    }),
    NgxPermissionsModule.forRoot(),
    CompleteAuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: '/auth/login',
      authService: AuthService,
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    LoginModule.forRoot({
      environment,
      productName: 'Login',
      redirectAfterLogin: '/admin/config',
      backgroundImageUrl: '/assets/images/login-background.png',
      loginService: IntLoginService,
      passwordValidator: intPasswordValidator,
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot({
        indexedDbModels: [],
        databaseName: 'complete-db',
        version: 1,
        loaderText: 'Laden',
      },
      NgxPermissionsService,
      NgxPermissionsModule,
    ),
    CoreModule,
    CapturumButtonModule,
    CapturumToastModule,
    CapturumInputSwitchModule,
    CapturumMultiSelectModule,
    FormModule,
    CapturumEditorModule,
    FormlyModule.forRoot({
      types: [
        {
          component: CalendarTypeComponent,
          name: FieldTypes.Calendar,
          wrappers: ['cap-form-field'],
        },
        {
          component: DropdownTypeComponent,
          name: FieldTypes.Dropdown,
          wrappers: ['cap-form-field'],
        },
        {
          component: TimeTableTypeComponent,
          name: FieldTypes.TimeTable,
          wrappers: ['cap-form-field'],
        },
        {
          component: InputSwitchTypeComponent,
          name: FieldTypes.IntergripInputswitch,
          wrappers: ['cap-form-field'],
        },
        {
          component: ImageComponent,
          name: FieldTypes.Image,
          wrappers: ['cap-form-field'],
        },
        {
          component: EditorFormlyTypeComponent,
          name: FieldTypes.Editor,
          wrappers: ['cap-form-field'],
        },
        {
          component: MultiSelectFormlyTypeComponent,
          name: FieldTypes.MultiSelect,
          wrappers: ['cap-form-field'],
        },
        {
          component: HtmlFormlyTypeComponent,
          name: FieldTypes.Html,
          wrappers: ['cap-form-field'],
        },
        {
          component: InputRadioComponent,
          name: FieldTypes.Radio,
          wrappers: ['cap-form-field'],
        },
      ],
      wrappers: [{
        name: 'app-form-field', component: AppFormFieldComponent,
      }],
    }),
    ConfirmDialogModule,
    CapturumWebsocketModule.forRoot({
      websocketDriver: PusherWebsocketService, // This defines which driver you'll be using
      connectionConfig: { // The connection data/credentials needed to establish a connection
        key: `${environment.webSocketKey}`,
        cluster: 'eu',
        authEndpoint: `${environment.baseUrl}/pusher/auth`,
      },
      endpoint: environment.baseUrl,
    }),
  ],
  providers: [
    {
      provide: FormlyConfig,
      useClass: CustomFormlyConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    CustomPublicTranslationResolver,
    DateParserPipe,
    ConfirmationService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
