import { Injectable } from '@angular/core';
import { ApiService, ListOptions, ApiIndexResult } from '@capturum/api';
import { ModuleApiModel } from '@capturum/complete';
import { BehaviorSubject, Observable } from 'rxjs';
import { responseData } from '@core/utils/converter.utils';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { Module, TenantModule, TenantModuleFormRequest } from '@features/module/models/module.model';
import { NavigationEnd, Router } from '@angular/router';
import { ApiHttpService } from '@capturum/api';
import { Modules } from '@core/enums/modules.enum';
import { Field } from '@shared/field/field.enum';
import { FilterMatchMode } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends ApiService<ModuleApiModel> {
  public currentModule: string;
  public currentModule$: Observable<string>;
  protected endpoint = 'module';
  private moduleSubject = new BehaviorSubject<string>(null);

  constructor(
    apiHttp: ApiHttpService,
    private router: Router,
  ) {
    super(apiHttp);

    this.currentModule = Modules.IntergripModule;

    this.currentModule$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        let module: string = Modules.Intergrip;

        if (event && event.url) {
          const url = event.url.charAt(0) === '/' ? event.url.substring(1) : event.url;
          module = url.split('/', 1)[0];
        }

        return module;
      }),
      shareReplay(1),
      tap((module) => {
        if (module === Modules.Intergrip) {
          module = Modules.IntergripModule;
        }

        this.currentModule = module;
        this.moduleSubject.next(module);
      }));

    this.currentModule$.subscribe((module) => {
      this.currentModule = module;
    });
  }

  public getCurrentModule(): Observable<string> {
    return this.moduleSubject.asObservable();
  }

  public getUserModules(): Observable<Module[]> {
    return this.index({
      filters: [{
        field: Field.Intergrip_Module,
        value: 1,
        operator: FilterMatchMode.EQUALS,
      }],
    }).pipe(responseData, map((modules) => {
      return modules.filter((module) => {
        return module.active === true;
      });
    }));
  }

  public getAllModules(): Observable<ApiIndexResult<Module>> {
    return this.apiHttp.get(`/${this.endpoint}?_meta[page]=1&_meta[perPage]=9999`);
  }

  public getTenantModules(tenantId: string, options?: ListOptions): Observable<TenantModule[]> {
    return this.apiHttp.get(`/tenant/${tenantId}/module${this.getOptionsQuery(options)}`).pipe(responseData);
  }

  public updateTenantModules(tenantId: string, data: TenantModuleFormRequest): Observable<any> {
    return this.apiHttp.put(`/tenant/${tenantId}/module`, data);
  }

  public transformToTreeStructure(modules: Module[]): any {
    let childModuleIds: string[] = [];

    return modules.map((module: Module) => {
      let moduleData = { ...module };
      const childModules = module.children.filter((childModule: Module) => childModule.parent_id === module.id);

      childModuleIds = [...childModuleIds, ...childModules.map(({ id }) => id)];

      if (childModules) {
        moduleData = { ...moduleData, children: childModules };
      }

      return moduleData;
    }).filter((module) => childModuleIds.indexOf(module.id) === -1);
  }
}
