import { Injectable } from '@angular/core';
import { ApiHttpService, ApiListResult, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RmcReportService extends ApiService<any> {

  protected endpoint = 'power-bi';
  private secret = 'hJc8Q~iA1oiHd~ViukXFYCuRsRfHIgQMex73JbcO';
  private name = 'dashboardRMC';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getReports(options?: ListOptions): Observable<ApiListResult> {
    return this.apiHttp.get(`/${this.endpoint}/report${this.getOptionsQuery(options)}`);
  }

  public createReports(): Observable<ApiListResult> {
    return this.apiHttp.post(`/${this.endpoint}/report/`,
      {
        role: 'rmc-regio',
        username: 'regio-18',
        client_secret: this.secret,
        client_id: 'dd112f54-01e8-11ed-88ea-0242ac125555',
        name: this.name,
        group_id: '1427899d-80ed-4899-96e3-2dc0835bb43f',
        report_id: '59c7717a-85ec-49ef-b770-ba9e76a3d1ef',
        data_set_id: '454353ff-ec83-4be6-97d0-7438cc3051b3'
      });
  }

  public postClient(): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/client`,
      { client_secret: this.secret, client_id: '0a6c1e15-0b17-4b9c-82fa-361295a0d448',
      client_name: this.name});
  }

}
