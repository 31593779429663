import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@features/user/services/user.service';
import { Store } from '@ngxs/store';
import { UserModel } from '@features/user/models/user.model';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-impersonate-indicator',
  templateUrl: './impersonate-indicator.component.html',
  styleUrls: ['./impersonate-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpersonateIndicatorComponent {
  public state$: Observable<{ user: UserModel, isImpersonating: boolean }>;
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(
    private userService: UserService,
    private store: Store,
  ) {
    this.state$ = this.store.select(state => ({
      user: state.userState.user,
      isImpersonating: state.userState.isImpersonating,
    }));
  }

  public stopImpersonate(): void {
    this.userService.stopImpersonate();
  }
}
