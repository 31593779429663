export class RouterUtils {

  public static revertUrlChanges(url: string): string {
    if (url) {
      return url.split('%3D').join('')
        .split('%2F').join('/')
        .split('%252F').join('/')
        .split('%253F').join('?')
        .split('%253D').join('');
    }
  }
}
