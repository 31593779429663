export enum KovPoPermissions {
    Child = 'child',
    KovPo = 'kov-po',
    Format = 'format',
    ChildList = 'child-list',
    Observation = 'observation',
    TransferRequest = 'transfer-request',
    Student = 'student',
    Daycare = 'daycare',
    SpecialTransfer = 'special-transfer',
}
