import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { LocaleEnum } from '@core/enums/locale.enum';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { ProfileMenuItem } from '@core/components/header/header.component';
import { UserService } from '@features/user/services/user.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit, AfterViewInit {

  public selectedLanguage: string;
  public fontAwesomeIcon = FontAwesomeIcon;

  @Input()
  public profileMenuItems: Observable<ProfileMenuItem[]>;

  private availableLocales: string[] = [LocaleEnum.Dutch, LocaleEnum.English];

  constructor(private config: DynamicDialogConfig, private cdr: ChangeDetectorRef, private userService: UserService) {
  }

  public ngOnInit(): void {
    this.selectedLanguage = this.userService.getCurrentUser()?.locale?.code;
    if (this.config?.data?.profileMenuItems) {
      this.profileMenuItems = this.config.data.profilesMenuItems;
      this.cdr.detectChanges();
    }
  }

  public ngAfterViewInit(): void {
    if (!this.profileMenuItems) {
      this.profileMenuItems = this.config.data.profilesMenuItems;
      this.cdr.detectChanges();
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (document.body.clientWidth > 767 && this.config?.data?.onClose) {
      this.close();
    }
  }

  public close(): void {
    if (this.config?.data?.onClose) {
      this.config.data.onClose.next(true);
      this.cdr.detectChanges();
    }
  }

  public onClickLocale(event: MouseEvent, item: MenuItem): void {
    if (this.availableLocales.some(code => item.title === code)) {
      this.selectedLanguage = item.title;
    }
    this.close();
    item.command({
        originalEvent: event,
        item: item
    });
  }
}
