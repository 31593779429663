import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { toMapItems } from '@core/utils/converter.utils';
import { NewsMessagesModel } from '@features/news/models/news-messages.model';
import { Field } from '@shared/field/field.enum';
import { Entities } from '@core/components/entities.enum';

@Injectable({
  providedIn: 'root'
})
export class NewsOverviewService extends ApiService<NewsMessagesModel> {

  protected endpoint = Entities.News;

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public index<T = any>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    if (options?.sort?.length === 0 || !options?.sort) {
      options.sort = [{
        field: Field.Created_at,
        direction: 'desc',
      }];
    }
    return super.index(options);
  }
  public getRolesList(): Observable<MapItem[]> {
    return this.apiHttp.get(`/role-type/list`).pipe(toMapItems);
  }

  public getModulesList(): Observable<MapItem[]> {
    return this.apiHttp.get(`/module/list?_meta[filter][intergrip_module][operator]=equals&_meta[filter][intergrip_module][value]=1`).pipe(toMapItems);
  }

  public uploadImage(base64: string, filename: string): Observable<any> {
    return this.apiHttp.post(`/${Entities.News}/upload-image`, {
        blob: base64,
        fileName: filename,
    });
  }
}
