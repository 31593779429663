<div class="input-switch-type">
  <cap-input-switch [formControl]="formControl"
                    [style]="to.style || null"
                    [styleClass]="to.styleClass || null"
                    [disabled]="to.disabled || false"
                    [readonly]="to.readonly || false"
                    *ngIf="!to.disabled | observablePipe | async"
                    [formlyAttributes]="field">
  </cap-input-switch>

  <span *ngIf="to.disabled">
    {{ !!formControl.value ? (to.truthyTranslationKey | translate) : (to.falsyTranslationKey | translate) }}
      <i *ngIf="to.tooltip" [ngClass]="[fontAwesomeIcon.InfoCircle]" [pTooltip]="to.tooltip"> </i>
  </span>
</div>
