import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-more-results-overlay',
  templateUrl: './more-results-overlay.component.html',
  styleUrls: ['./more-results-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreResultsOverlayComponent {
  @Input()
  public title: string;

  @Input()
  public items: string[];
}
