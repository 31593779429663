export enum TransferReportPermissions {
  ChoiceStudentYear  = 'choice-student-year',
  MboChoiceStudentYear  = 'mbo-choice-student-year',
  ChoiceStudent  = 'choice-student',
  BestChoiceExport  = 'best-choice-export',
  VoSchoolProgress  = 'vo-school-progress',
  MboSchoolProgress  = 'mbo-school-progress',
  OutflowHistory = 'outflow-history',
  ReportsProgressVmbo = 'reports.progress-vmbo',
  ProcessedMboChecks = 'mbo-check.processed',
}
