import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';
import {FontAwesomeIcon} from "@core/enums/font-awesome-icon.enum";

@Component({
  selector: 'app-input-switch-formly',
  templateUrl: './input-switch.formly-type.html',
  styleUrls: ['./input-switch.formly-type.scss'],
})
export class InputSwitchTypeComponent extends FieldType {
  // @TODO: Add correct translation keys
  public defaultOptions = {
    templateOptions: {
      truthyTranslationKey: 'intergrip.toggle.active',
      falsyTranslationKey: 'intergrip.toggle.inactive',
    }
  };
  protected readonly fontAwesomeIcon = FontAwesomeIcon;
}
