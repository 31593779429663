import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends FieldType {
    public loading: boolean;
    public previewImage: string | ArrayBuffer;

    public readURL(event: any): void {
        if (event.target.files && event.target.files[0]) {
            this.loading = true;
            const reader = new FileReader();

            this.formControl.setValue(event.target.files[0]);

            reader.onload = (onLoadEvent: Event) => {
                this.previewImage = reader.result;
            };

            reader.onloadend = () => {
                this.loading = false;
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    }
}
