import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field-wrapper.component.html',
    styleUrls: ['./form-field-wrapper.component.scss'],
})
export class AppFormFieldComponent extends FieldWrapper implements OnInit {
    @Input() public control: AbstractControl;
    @Input() public label: string;
    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.control = this.formControl;
        this.label = this.to.translateLabel;
    }
}
