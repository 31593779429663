<div [class]="activeMenuItemKey$ | async">
  <app-header></app-header>

  <div id="wrapper">

    <app-sidebar *ngIf="showHeader"></app-sidebar>

    <div
      [ngClass]="{'page-wrapper': showHeader, 'gray-bg': showHeader, 'impersonating': (isImpersonated$ | async) === true, 'app-basic-layout__overflow--hidden': isContentLoading }">
      <app-page-header *ngIf="showHeader" [pageConfig]="pageConfig$ | async"></app-page-header>

      <router-outlet>

      </router-outlet>
      <div class="app-basic-layout__content-loader"
           [ngClass]="{'app-basic-layout__content-loader--home-page': isHomePage}" *ngIf="isContentLoading">
        <app-loader class="app-basic-layout__content-preloader"></app-loader>
      </div>
    </div>

    <app-impersonate-indicator></app-impersonate-indicator>
  </div>


  <p-confirmDialog header="{{ 'intergrip.confirmation.title' | translate }}" [closable]="false" #cd>
    <p-footer>
      <cap-button [icon]="cd?.confirmation?.rejectIcon || timesIcon"
                  [label]="cd?.confirmation?.rejectLabel || ('general.no' | translate)"
                  (click)="cd.reject()"
                  [styleClass]="'secondary ' + cd?.confirmation?.rejectButtonStyleClass">
      </cap-button>

      <cap-button [icon]="cd?.confirmation?.acceptIcon || 'fa fa-check'"
                  [label]="cd?.confirmation?.acceptLabel || ('general.yes' | translate)"
                  (click)="cd.accept()"
                  [styleClass]="'primary ' + cd?.confirmation?.acceptButtonStyleClass">
      </cap-button>
    </p-footer>
  </p-confirmDialog>
  <app-loader class="app-basic-layout__loading" *ngIf="isLoading"></app-loader>
</div>

<app-message-of-the-day *ngIf="showMOTD$ | async"></app-message-of-the-day>
