import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Region } from '@features/regions/models/region.model';
import { responseData } from '@core/utils/converter.utils';
import { UserModel } from '@features/user/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class LogoService extends ApiService<Region> {
  public currentUserRoleChanged: BehaviorSubject<UserModel> = new BehaviorSubject(null);

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
    }

  public getMenuLogo(regionId: string, listOptions?: ListOptions): Observable<{ url: string }> {
    return this.apiHttp.get(`/region/${regionId}/logo`);
  }

  // TODO: delete this function after get logo function will be ready on BE
  // public getMenuLogoForIurie(regionId: string, listOptions?: ListOptions): Observable<Region> {
  //   return of({
  //     created_at: "2021-01-25 09:10:50",
  //     has_2fa: true,
  //     has_2fa_sms: false,
  //     id: "37e15e90-5eed-11eb-878b-0242ac120006",
  //     name: "Intergrip Regio",
  //     updated_at: "2021-01-25 09:10:50",
  //     logo: {
  //       created_at: "2021-03-09 12:59:49",
  //       disk: "s3",
  //       extension: "png",
  //       file: null,
  //       filename: "intergrip-logo.png",
  //       id: "79b41930-25a5-4744-abd4-c8190f77bb65",
  //       md5_hash: "bc9754d58c770562e9e9548090cf658e",
  //       mime_type: "image/png",
  //       path: "region/logo",
  //       public_url: "https://capturum-intergrip-test.s3.eu-central-1.amazonaws.com/region/logo/79b41930-25a5-4744-abd4-c8190f77bb65",
  //       updated_at: "2021-03-09 12:59:49",
  //
  //     },
  //   } as Region);
  // }
}
