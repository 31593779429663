import { LoginService } from '@capturum/login';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IntLoginService extends LoginService {

  public onLoginSuccess(response: any): void {
    // Prevent the login service from redirecting. This happens in our custom auth service
  }
}
