import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/*
  This service was created as a workaround solution to resolve the problem on getting params from default url
*/

@Injectable({
  providedIn: 'root',
})
export class LocalParamsService {
  public roleKeyUpdated = new BehaviorSubject<any>(null);
}
