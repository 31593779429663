import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInputModule } from '@capturum/ui/input';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserModule } from '@capturum/complete';
// Components
import { TwoFactorLoginComponent } from './two-factor-login/two-factor-login.component';
import { SidebarModule } from 'primeng/sidebar';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { TwoFactorResetMailResponseComponent } from './pages/two-factor-reset-mail-response/two-factor-reset-mail-response.component';
import { RouterModule } from '@angular/router';
import { ActivateUserComponent } from './pages/activate-user/activate-user.component';
import { TwoFAActivationComponent } from './2fa-activation/2fa-activation.component';


@NgModule({
  declarations: [
    TwoFactorLoginComponent,
    TwoFAActivationComponent,
    TwoFactorResetMailResponseComponent,
    ActivateUserComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    CapturumInputModule,
    SidebarModule,
    CapturumButtonModule,
    CapturumDropdownModule,
    ReactiveFormsModule,
    UserModule,
  ],
  exports: [
    FormsModule,
    TwoFactorLoginComponent,
    TwoFAActivationComponent,
    TwoFactorResetMailResponseComponent,
    ActivateUserComponent,
  ],
})
export class AuthModule {
}
