export enum RoleTypeKeys {
  lpTransferpoint = 'lp-transferpoint',
  lpTransferpointAdmin = 'lp-transferpoint-admin',
  mbo = 'mbo',
  mboAdmin = 'mbo-admin',
  vo = 'vo',
  voAdmin = 'vo-admin',
  supervisor = 'supervisor',
  admin = 'admin',
  transfer = 'transfer',
  transferAdmin = 'transfer-admin',
  poAdmin = 'po-admin',
  po = 'po',
  daycareAdmin = 'daycare-admin',
  daycare = 'daycare'
}
