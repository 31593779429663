<div class="app-2fa-activation">
  <div class="app-2fa-activation__title">
    {{'auth.2fa.activation.title' | translate}}
  </div>
  <div class="app-2fa-activation__sub-title">{{'auth.2fa.activation.description' | translate}}</div>
  <div class="d-flex align-items-center justify-content-between mt-5">
    <cap-button styleClass="secondary" (click)="backToLogin()" [label]="'auth.2fa.activation.close' | translate"></cap-button>
    <cap-button styleClass="primary" (click)="openSideBar()" [label]="'auth.2fa.activation.start' | translate"></cap-button>
  </div>
</div>
