<div class="app-form-field">
  <div class="app-form-field__label">
    <label *ngIf="to.translateLabel">{{ to.translateLabel }}</label>
  </div>

  <div *ngIf="to.disabled" class="app-form-field__value">
    {{ formControl.value }}
  </div>
</div>

