import { Pipe, PipeTransform } from '@angular/core';
import { PageConfigAction } from '@core/models/page-config.model';
import { NgxPermissionsService } from 'ngx-permissions';

@Pipe({
  name: 'menuButtonVisibility',
})
export class MenuButtonPermissionPipe implements PipeTransform {
  constructor(protected ngxPermissionService: NgxPermissionsService,
  ) {
  }

  public transform(actions: PageConfigAction[]): boolean {
    return actions
      ?.map((item) => {
        return !item?.permissions?.length ? [true] : (item?.permissions?.filter(permission => (this.ngxPermissionService.getPermission(permission))));
      }).filter(item => item?.length > 0).length > 0;
  }

}
