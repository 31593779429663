<div class="preview-image mb-3" *ngIf="formControl.value">
  <img [src]="previewImage || formControl.value" [class]="to.previewImageClass" alt="preview" />
</div>

<label *ngIf="!to.readOnly && !to.disabled" for="file">
  <cap-button (click)="fileInput.click()" styleClass="primary" [label]="((to.translatePlaceholder || to.placeholder) | observablePipe | async) || 'Upload'" [icon]="to.iconClass || 'fa fa-upload'"></cap-button>
</label>

<input  id="file"
        type="file"
        accept="image/*"
        class="hidden d-none"
        #fileInput
        (change)="readURL($event)"
        [required]="to.required" />

<input type="hidden"
       [formControl]="formControl"
       [formlyAttributes]="field" />

