export enum UserModules {
  INTERGRIP = 'intergrip',
  ADDRESS = 'address',
  MODEL_LOG = 'model_log',
  FAVORITE = 'favorite',
  GRIP_OVERSTAP_VO = 'vombo',
  GRIP_DOORSTROOMDOSSIER = 'ddd',
  GRIP_MONITOR_US = 'monitorus',
  GRIP_OVERSTAP_MBO = 'mbombo',
  GRIP_LOB_VOORLICHTINGEN = 'zoekjouwstudie',
  GRIP_LOB_ACTIVITEITEN = 'lobactivities',
  GRIP_LOB_PORTFOLIO = 'lobdossier',
}
