import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NewsOverviewService } from '@features/news/services/news-overview.service';

@Component({

  template: `
    <cap-editor *ngIf="!formControl?.disabled && isNewsEditor" [formControl]="formControl" [config]="config" [formlyAttributes]="field"> </cap-editor>
    <cap-editor *ngIf="!formControl?.disabled && !isNewsEditor" [formControl]="formControl" [formlyAttributes]="field"></cap-editor>
    <div class="readonly-editor" *ngIf="formControl?.disabled" [innerHtml]="this.sanitizer.bypassSecurityTrustHtml(formControl.value)">
    </div>
  `,
  styles: [
    `
      .readonly-editor {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 15px;
        color: #4f5152;
        line-height: 19px;
      }`
  ]
})
export class EditorFormlyTypeComponent extends FieldType {
    public sanitizer: DomSanitizer;
    public apiHttp: NewsOverviewService;
    public isNewsEditor: boolean = window.location.href.indexOf('communication/news') > -1;
    public config: any = {
        plugins: [
            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
            'lists', 'link', 'image', 'imagetools', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image media',
        images_upload_handler: (blobInfo, success, failure ) => {
            this.apiHttp.uploadImage(blobInfo.base64(), blobInfo.filename()).subscribe(response => {
                success(response.filePath);
            });
        },
        file_picker_types: 'image'
    };

    constructor(sanitizer: DomSanitizer,
                apiHttp: NewsOverviewService) {
        super();
        this.sanitizer = sanitizer;
        this.apiHttp = apiHttp;
    }
}
