<div class="app-mobile-filter cap-table-filter">
    <div class="app-mobile-filter__filters-wrapper">
      <div class="row">
        <app-table-filter
          [filters]="filterConfig"
          [activeFilters]="activeFilter"
          (onReset)="reset()"
          (onSearch)="search($event)"
          (onFilter)="filter($event)"
        ></app-table-filter>

      </div>

    </div>

</div>

<div class="filter-buttons">
  <cap-button [label]="'intergrip.button.reset' | translate"
              styleClass="secondary cap-rounded"
              (click)="reset()">
  </cap-button>

  <cap-button [label]="'intergrip.button.search' | translate"
              styleClass="primary cap-rounded"
              (click)="close()">
  </cap-button>

</div>
