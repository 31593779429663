import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'rmcUploaded',
})
export class RMCUploadedPipe implements PipeTransform {
  constructor(private translatePipe: TranslatePipe) {}

  public transform(key: string): string {
    return this.translatePipe.transform(
      `dashboard-rmc.uploaded-files.${key}.label`
    );
  }
}
