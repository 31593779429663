export enum StyleClass {
  Primary = 'primary',
  Secondary = 'secondary',
  PasswordInput = 'password-input',
  Success = 'success',
  ActionColumn = 'action-column',
  SectionLabel = 'section-label',
  IconOnlyColumn = 'icon-only-column',
  Warning = 'warning',
}
