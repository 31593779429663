import { Modules } from '@core/enums/modules.enum';
import { Entities } from '@core/components/entities.enum';

export class UriUtils {

  public static edit(entity: string, id: string, module: Modules = Modules.Intergrip, subModule?: Entities): string {
    const moduleRoute = subModule ? `${module}/${subModule}` : module;
    return `/${moduleRoute}/${entity}/${id}`;
  }

  public static list(entity: string, module: Modules | string = Modules.Intergrip, subModule?: Entities): string {
    const moduleRoute = subModule ? `${module}/${subModule}` : module;
    return `/${moduleRoute}/${entity}/overview`;
  }

  public static submenu(entity: string, module: Modules = Modules.Intergrip): string {
    return `/${module}/${entity}`;
  }

  public static add(entity: string, module: Modules = Modules.Intergrip): string {
    return `/${module}/${entity}/add`;
  }
}
