<div class="col-12">
  <form class="login-form">
    <div class="title">
      <span class="text">{{ 'auth.activate.title' | translate }}</span>
    </div>

    <form [formGroup]="form">
      <emc-password-form [passwordValidator]="passwordValidator" formControlName="password"></emc-password-form>
    </form>

    <cap-button [disabled]="form.invalid" styleClass="primary" class="text-right" [label]="'button.submit' | translate" (click)="submit()"></cap-button>
  </form>
</div>
