<i [ngClass]="[fontAwesomeIcon.InfoCircle, 'info']"></i>
<div>
  <i class="fa fa-times close-button" [pTooltip]="'Melding sluiten'" (click)="closeClicked()"></i>
  <div class="content">
    <h5 class="heading">Attentie</h5>
    <p>Beste Intergripgebruiker,</p>
    <br />
    <p class="break-line" [innerHTML]="messageOfTheDayService.messageOfTheDay.message">
    </p>
    <br />
    <p>Team Intergrip </p>
  </div>
</div>
