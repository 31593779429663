<div class="page-header" *ngIf="pageConfig">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="page-header__icon-wrapper">
      <ng-container *ngIf="pageConfig.backUrl">
        <i [attr.data-test]="'header-previous-link'" [ngClass]="[fontAwesomeIcon.ArrowLeft, 'header-previous-link page-header__icon']"
           [routerLink]="[pageConfig.backUrl]" [queryParams]="pageConfig.backUrlQueryParams"></i>
      </ng-container>

      <div class="page-header__title-wrapper">
        <span class="page-header__title-inner">
          {{ pageConfig.title | observablePipe | async }}
        </span>
      </div>
    </div>
    <div class="page-header__action-buttons" *ngIf="pageConfig">
      <ng-container *ngFor="let selectOption of pageConfig.selectOptions">
        <ng-container [ngSwitch]="selectOption.inputType">
          <cap-dropdown
            class="page-header__dropdown"
            (change)="selectOption.onChangeCallback($event.value)"
            [options]="selectOption.options"
            *ngSwitchCase="inputType.dropdown"
            [withIcons]="true"
            [sortAlphabetically]="false">
          </cap-dropdown>

          <cap-multi-select
            [options]="selectOption.options"
            *ngSwitchCase="inputType.multiSelect"
            (change)="selectOption.onChangeCallback($event.value)">
          </cap-multi-select>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let button of pageConfig.buttons">
        <ng-container *ngIf="!button.hide || (button.hide | async) === false">
          <div class="button ml-2" *ngxPermissionsOnly="button.permissions">
            <cap-button
              [label]="button.label | translate : button.translateParams"
              [icon]="button.icon"
              [styleClass]="button.styleClass"
              (click)="buttonClicked(button)"
              [disabled]="(typeOf(button?.disabled) === 'boolean' && button?.disabled) || (button?.disabled | async) === true"
              [attr.data-test]="button?.dataTestAttribute">
            </cap-button>
          </div>
        </ng-container>
      </ng-container>
      <app-menu-button [menuItems]="pageConfig?.actions"></app-menu-button>

      <ng-container *ngIf="pageConfig.savedState">
        <div class="saved-state">
            <span class="label">{{ 'intergrip.saved.label' | translate }}: </span>
            <i *ngIf="!pageConfig.savedState.saving; else spinner"
                [class]="fontAwesomeIcon.CheckCircle"
                [pTooltip]="pageConfig.savedState.lastSaved | dateParser: 'dd - MM - yyyy @ HH:mm:ss'">
            </i>
            <ng-template #spinner>
                <div class="spinner-border" role="status"></div>
            </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
