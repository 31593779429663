export enum FieldTypes {
  Dropdown = 'dropdown',
  Password = 'password',
  IntergripInputswitch = 'intergripInputswitch',
  Image = 'image',
  Editor = 'editor',
  Html = 'html',
  Input = 'input',
  MultiSelect = 'multi-select',
  TextArea = 'textarea',
  Calendar = 'calendar',
  Radio = 'input-radio',
  TimeTable = 'time-table',
}
