import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValueAccessorBase } from '@capturum/ui/api';
import { Subscription } from 'rxjs';
import { CapturumInputComponent } from '@capturum/ui/input';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(InputCodeComponent)],
})
export class InputCodeComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
  @Input()
  public inputCodeLength = 6;
  @Output()
  public onInitialized = new EventEmitter<boolean>();
  @Output() public valuesFilled = new EventEmitter();
  @ViewChildren(CapturumInputComponent, { read: ElementRef })
  public inputs: QueryList<ElementRef>;

  public inputsForm: UntypedFormGroup;
  private prevValues = [];
  private subs: Subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && document.activeElement.className.includes('input-code')) {
      const inputElements = this.inputs.toArray();
      const activeElementId = document.activeElement.parentElement.parentElement.parentElement.getAttribute('id');
      inputElements.forEach((inputElement, index) => {
        if (activeElementId === `input-code-${index}` && !this.inputsArray.controls[index].value) {
          if (index !== 0 && !this.prevValues[index]) {
            this.inputs
              .toArray()
              [index].nativeElement.querySelector('input')
              .blur();
            this.focusOnInput(index - 1);
          }
          this.prevValues[index] = '';
        }
      });
    }
  }

  public ngOnInit(): void {
    this.inputNumber();
    this.subs = this.inputsArray.valueChanges.subscribe(() => {
      this.value = this.inputsArray.value.join('');
      if (this.inputsArray.valid) {
        this.valuesFilled.emit();
      }
    });
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public writeValue(value: string): void {
    if (value) {
      super.writeValue(value);
    } else {
      this.inputsArray.reset();
    }
  }

  public focusOnNextInput(index: number, event: InputEvent, value: string): void {
    event.stopImmediatePropagation();
    if (value.trim() !== '') {
      this.prevValues[index] = value;
      const currentInputElement = this.inputs
        .toArray()
        [index].nativeElement.querySelector('input');

      if (currentInputElement.value?.length > 1) {
        currentInputElement.value = currentInputElement.value[0];
      }

      if (this.inputs.toArray()[index + 1] && value !== '') {
       this.focusOnInput(index + 1);
      }
    }
  }

  public focusOnInput(index: number): void {
    this.inputs
      .toArray()
      [index].nativeElement.querySelector('input')
      .focus();
  }

  // @ts-ignore
  public get inputsArray(): UntypedFormArray {
    return this.inputsForm.get('myCode') as UntypedFormArray;
  }

  public focus(): void {
    this.inputs
      .toArray()
      [0].nativeElement.querySelector('input')
      .focus();
  }

  private inputNumber(): void {
    this.inputsForm = this.formBuilder.group({
      myCode: this.formBuilder.array([]),
    });

    for (let i = 0; i < this.inputCodeLength; i++) {
      this.inputsArray.push(new UntypedFormControl(null, [Validators.required]));
    }
  }
}
