import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItemService } from '@shared/services/menu-item.service';
import { Observable, of } from 'rxjs';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { UserModel } from '@features/user/models/user.model';
import { LogoService } from '@core/services/logo.service';
import { Logo } from '@features/regions/models/region.model';
import { ListOptions } from '@capturum/api';
import { FilterInclude } from '@core/enums/general/filter-includes.enum';
import { BindableType } from '@core/enums/bindable-type.enum';
import { UserService } from '@features/user/services/user.service';
import { switchMap, map} from 'rxjs/operators';
import { SidebarService } from '@core/components/sidebar/services/sidebar.service';
import { UserModules } from '@core/enums/user-modules.enum';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { RoleTypeKeys } from '@core/enums/role-type-keys.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public activeMenuItem$: Observable<NavMenuItem>;
  public currentUser: UserModel;
  public showInfoBox = false;
  public logo$: Observable<string|Logo>;
  public listOptions: ListOptions;
  public infoBoxRoles = [RoleTypeKeys.supervisor, RoleTypeKeys.vo, RoleTypeKeys.voAdmin, RoleTypeKeys.mbo, RoleTypeKeys.mboAdmin, RoleTypeKeys.admin];
  public circleIcon: FontAwesomeIcon = FontAwesomeIcon.InfoCircle;
  constructor(
    private menuItemService: MenuItemService,
    private logoService: LogoService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private sidebarService: SidebarService,
  ) {
    this.listOptions = {
      include: [FilterInclude.Logo],
    };
  }

  public ngOnInit(): void {
    this.activeMenuItem$ = this.menuItemService.getActiveMenuItem();
    this.currentUser = this.userService.getCurrentUser() as UserModel;

    if (this.activeMenuItem$) {
      this.activeMenuItem$.subscribe(item => {
        this.showInfoBox = item?.key === UserModules.GRIP_DOORSTROOMDOSSIER && this.infoBoxRoles.includes(this.currentUser?.currentRoleType?.key as RoleTypeKeys)
      });
    }

    this.logo$ = this.logoService.currentUserRoleChanged.pipe(
      switchMap((user: UserModel) => {
        if (user?.currentInstance?.pivot?.bindable_type === BindableType.Region) {
          return this.logoService.getMenuLogo(user?.currentInstance?.pivot.bindable_id, this.listOptions)
            .pipe(
              map((logo) => logo.url),
            );
        }

        return of(null);
      }),
    );

    this.cdr.detectChanges();
  }

  public menuItemClick(event: PointerEvent): void {
    this.sidebarService.setEvent(event);
  }

  public redirectToDDD(): void {
    window.open('https://doorstroomdossier.nl/', '_blank');
  }
}
