import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, ComponentRef, ElementRef, Input,
  Type, ViewChild,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogcontentDirective } from '@capturum/ui/api';
import { MenuItemService } from '@shared/services/menu-item.service';
import { Observable, Subscription } from 'rxjs';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-overlay-modal',
  templateUrl: './overlay-modal.component.html',
  styleUrls: ['./overlay-modal.component.scss'],
})
export class OverlayModalComponent implements AfterViewInit {

  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;


  @Input()
  public icon: string = FontAwesomeIcon.Times;

  public title: Observable<string>;
  public styleClass = '';

  private animationFrom: 'left'|'right'|'bottom'|'top' = 'right';
  private sub: Subscription;

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private menuItemService: MenuItemService,
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
  ) {
  }

  public ngAfterViewInit(): void {
    if (this.childComponentType) {
      this.loadChildComponent(this.childComponentType);
      this.cd.detectChanges();
    }
  }

  public ngOnInit(): void {
    if (this.config.data.animationFrom) {
      this.animationFrom = this.config.data.animationFrom;
    }

    if (this.config.data.icon) {
      this.icon = this.config.data.icon;
    }

    if (this.config.data.title) {
      this.title = this.config.data.title;
    }

    if (this.config.data.styleClass) {
      this.styleClass = this.config.data.styleClass;
    }

    this.sub = this.config.data.onClose.subscribe((value) => {
      if (value) {
        this.hide();
      }
    });
  }

  public ngOnDestroy(): void {
    this.config.data.onClose.next(false);
    this.sub.unsubscribe();
    this.dialogRef?.destroy();
  }

  public hide(): void {
    const closeClass = this.elementRef.nativeElement.querySelector('#overlayModal');
    closeClass.className = `app-overlay-modal__hide--${this.animationFrom} app-overlay-modal__hide`;
    setTimeout(() => {
      this.dialogRef?.destroy();
      this.componentRef.destroy();
    }, 400);
  }

  private loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }
}

