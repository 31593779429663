import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateParser',
})
export class DateParserPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  /**
   * Transform value to new date format
   * Note: Safari doesn't accept 'yyyy-MM-dd HH:mm:ss' format. replace '-' with '/'
   * other solution is replace ' ' with 'T'
   *
   * @param value date string
   * @returns string
   */
  public transform(value: any, dateFormat: string = 'dd-MM-yyyy'): string {
    if (typeof value === 'string' && value.indexOf('Z') !== -1) {
      value = new Date(value);
    }
    return this.datePipe.transform(typeof value === 'object' ? value : new Date(value.replace(/-/g, '/')), dateFormat);
  }

}
