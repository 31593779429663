export enum FilterInclude  {
  Attributes = 'attributes',
  Applicant = 'applicant',
  Attr = 'attr',
  Address = 'address',
  AvailableSettings = 'availableSettings',
  CurrentStudentYear = 'currentStudentYear',
  CurrentYearInterventions = 'currentYearInterventions',
  ClassRecipients = 'classRecipients',
  Children = 'children',
  Classes = 'classes',
  CreatedByUser = 'createdByUser',
  Delivererable = 'delivererable',
  Files = 'files',
  File = 'file',
  InterventionType = 'interventionType',
  LatestTraining = 'latestTraining',
  LatestChoiceUpdate = 'latestChoiceUpdate',
  Logo = 'logo',
  Locale = 'locale',
  LinkType = 'linkType',
  LatestMboCheckFile = 'latestMboCheckFile',
  Key = 'key',
  Members = 'members',
  MboInstitution = 'mboInstitution',
  Institution = 'institution',
  MboCheckFetchServiceType = 'mboCheckFetchServiceType',
  ProcessingStatus = 'processingStatus',
  MboSchoolGroups = 'mboSchoolGroups',
  MboCheck = 'mboCheck',
  MboCheckFiles = 'mboCheckFiles',
  MboCheckJobs = 'mboCheckJobs',
  MboCheckResults = 'mboCheckResults',
  MboCheckStatus = 'mboCheckStatus',
  MboCheckLogs = 'mboCheckLogs',
  MboCheckJobStatus = 'mboCheckJobStatus',
  MboCheckFetchServiceJobs = 'mboCheckFetchServiceJobs',
  MboCheckFetchServiceLogs = 'mboCheckFetchServiceLogs',
  MboLearningTrack = 'mboLearningTrack',
  MinimumSignupStatus = 'minimumSignupStatus',
  Municipalities = 'municipalities',
  Module = 'module',
  Modules = 'modules',
  ManuallyCreatedByUser = 'manuallyCreatedByUser',
  Name = 'name',
  Permissions = 'permissions',
  Region = 'region',
  Regions = 'regions',
  Roles = 'roles',
  RoleType = 'roleType',
  RoleTypes = 'roleTypes',
  RolesForCurrentSchoolYear = 'rolesForCurrentSchoolYear',
  RolesForCurrentSchoolYearAndNoSchoolYear = 'rolesForCurrentSchoolYearAndNoSchoolYear',
  SchoolClass = 'schoolClass',
  SchoolClasses = 'schoolClasses',
  Settings = 'settings',
  Students = 'students',
  Student = 'student',
  Source = 'source',
  StudentYears = 'studentYears',
  StudentYear = 'studentYear',
  StudentRecipients = 'studentRecipients',
  StudentRecipientGroups = 'studentRecipientGroups',
  StudentMembers = 'studentMembers',
  Status = 'status',
  Sector = 'sector',
  SchoolType = 'schoolType',
  School = 'school',
  Schools = 'schools',
  SignupStatus = 'signupStatus',
  Translations = 'translations',
  Tenant = 'tenant',
  Transfer = 'transfer',
  TwoFactorAuth = 'twoFactorAuth',
  Users = 'users',
  User = 'user',
  UserMembers = 'userMembers',
  UserRecipients = 'userRecipients',
  UserRecipientGroups = 'userRecipientGroups',
  VoSchoolGroups = 'voSchoolGroups',
  Category = 'category',
  Logs = 'logs',
  Choiceable = 'choiceable',
  TrainingChoiceStudentYears = 'trainingChoiceStudentYears',
  ChoiceStudentYears = 'choiceStudentYears',
  Transfers = 'transfers',
  LatestTransferStatus = 'latestTransferStatus',
  MboCheckService = 'mboCheckService',
  SoftMatch = 'softMatch',
  SpecialTransferInformation = 'specialTransferInformation',
  Interventions = 'interventions',
  MinimalSignupStatus = 'minimalSignupStatus',
  TransferStatuses = 'transferStatuses',
  TransferredByUser = 'transferredByUser',
  TransferType = 'transferType',
  BblWorkPlace = 'bblWorkplace',
  UserPartBAvailable = 'userPartBAvailable',
  RoleUsers = 'roleUsers',
  RoleUser = 'roleUser',
  Role = 'role',
  RoleId = 'role_id',
  ChoiceLocations = 'choiceLocations',
  AllStatusChoiceStudentYears = 'allStatusChoiceStudentYears',
  TrainingStatus = 'trainingStatus',
  RioConnectionJobs = 'rioConnectionJobs',
  RioConnectionResults = 'rioConnectionResults',
  RioConnectionLogs = 'rioConnectionLogs',
  RioConnectionJobStatus = 'rioConnectionJobStatus',
  LatestJob = 'latestJob',
  CurrentChildYear = 'currentChildYear',
  RmcRegion = 'rmcRegion',
  LatestStudentListFile = 'latestStudentListFile',
  ImportedByUser = 'importedByUser',
  StudentListFileRows = 'studentListFileRows',
  StudentListFileMessages = 'studentListFileMessages',
  StudentListFileRowMessages = 'studentListFileRowMessages',
  StudentListFileRow = 'studentListFileRow',
  StudentListFileLogs = 'studentListFileLogs',
  PropertyValues = 'propertyValues',
  KennisnetUids = 'kennisnetUids',
  Property = 'property',
  Observation = 'observation',
  Part = 'part',
  Blocks = 'blocks',
  Questions = 'questions',
  Child = 'child',
  QuestionResponses = 'questionResponses',
  Responseable = 'responseable',
  Answer = 'answer',
  TransferRequest = 'transferRequest',
  SchoolOfOrigin = 'schoolOfOrigin',
  StudentFetchServiceType = 'studentFetchServiceType',
  LatestStudentFetchServiceJob = 'latestStudentFetchServiceJob',
  StudentFetchServiceJobStatus = 'studentFetchServiceJobStatus',
  InvitedBy = 'invitedBy',
  SocialMapAvailabilities = 'socialMapAvailabilities'
}
