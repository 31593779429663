import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { Field } from '@shared/field/field.enum';
import { USFile } from '@features/dashboard-us/models/USFile';
import { USFileResponse } from '@features/dashboard-us/models/USFileResponse';
import { USFileDownloadResponse } from '@features/dashboard-us/models/USFileDownloadResponse';

@Injectable({
  providedIn: 'root'
})
export class DashboardUsService extends ApiService<any> {
  protected endpoint = 'dashboard-us/uploaded-files';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public index<T = any>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    if (options?.sort?.length === 0 || !options?.sort) {
      options.sort = [{
        field: Field.Created_at,
        direction: 'desc',
      }];
    }
    return super.index(options);
  }

  public getFileTypesList(): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/file-types/list`);
  }

  public uploadFile(data: USFile): Observable<USFileResponse> {
    return this.apiHttp.post(`/${this.endpoint}`, data);
  }

  public downloadItem(id: string): Observable<USFileDownloadResponse> {
    return this.apiHttp.post(`/${this.endpoint}/download`, {id: id});
  }
}
