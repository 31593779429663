import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ApiHttpService, ApiListResult, ApiService } from '@capturum/api';
import { PermissionService, SettingService } from '@capturum/complete';
import { FavoriteItem } from '@core/components/header/favorite-item';
import { responseData } from '@core/utils/converter.utils';
import { switchMap, tap } from 'rxjs/operators';
import { UserService } from '@features/user/services/user.service';
import { Role } from '@features/role/models/role.model';
import { SetUser } from '@core/state/user/user.actions';
import { Store } from '@ngxs/store';
import { UserModel } from '@features/user/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends ApiService<Role> {
  public roleUpdated$: Observable<boolean>;
  protected endpoint = 'role';
  private roleUpdated = new BehaviorSubject<boolean>(true);

  constructor(
    public api: ApiHttpService,
    private permissionService: PermissionService,
    private userService: UserService,
    private settingService: SettingService,
    private store: Store,
  ) {
    super(api);

    this.roleUpdated$ = this.roleUpdated.asObservable();
  }

  public all(): Observable<FavoriteItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/all`).pipe(responseData);
  }

  public setRoleUpdated(): void {
    this.roleUpdated.next(true);
  }

  public setRole(roleId: string): Observable<boolean[]> {
    return this.apiHttp.post<{ data: UserModel & { currentRoleType: Role } }>(`/user/${this.endpoint}`, { role_user_id: roleId })
      .pipe(
        tap((response) => {
          this.userService.updateCurrentUser(response.data);
          this.store.dispatch(new SetUser(response.data));
          this.setRoleUpdated();
        }),
        switchMap(() => forkJoin([
          this.permissionService.loadPermissions(),
          this.settingService.loadAll(),
        ])),
      );
  }

  public setRoleByRoleKey(roleKey: string): Observable<any> {
    return this.apiHttp.post(`/user/role/by-key`, { redirectRoleKey: roleKey }).pipe(
      tap((response: {data: UserModel}) => {
        this.userService.updateCurrentUser(response.data);
        this.store.dispatch(new SetUser(response.data));
        this.setRoleUpdated();
      }),
      switchMap(() => forkJoin([
        this.permissionService.loadPermissions(),
        this.settingService.loadAll(),
      ])),
    );
  }

  public getWholeSectorList(): Observable<ApiListResult> {
    return this.apiHttp.get(`/sector/list`);
  }
}
