<div class="page">
  <app-table-filter [filters]="filterConfig"
                    [activeFilters]="infoTable?.activeFilters"
                    (onReset)="reset()"
                    (onFilter)="filter($event)">
  </app-table-filter>

  <cap-info-table #infoTable
                  [loading]="false"
                  [columns]="tableColumns"
                  [paginator]="paginator"
                  [lazyLoading]="true"
                  [autoLayout]="true"
                  [clickable]="false"
                  [stateKey]="stateKey"
                  (onLazyLoad)="loadTableData($event)"
                  [data]="tableData">
    <ng-template capTemplate="type.value" let-item="item">
      {{ item.type.value | rmcUploaded }}
    </ng-template>
    <ng-template capTemplate="status.value" let-item="item">
      {{ item.status.value | rmcUploaded }}
    </ng-template>
    <ng-template [capTemplate]="tableField.Created_at" let-item="item">
      {{item.created_at | appDateTime}}
    </ng-template>

  </cap-info-table>
</div>
