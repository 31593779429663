import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '@features/auth/services/auth.service';
import { PageRoute } from '@core/enums/general/routes.enum';
import { passwordValidator } from '@shared/validators/password.validator';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss'],
})
export class ActivateUserComponent implements OnInit {
  public form: UntypedFormGroup;
  public fields: FormlyFieldConfig;
  public passwordValidator: (control: UntypedFormControl) => ValidationErrors = passwordValidator;
  private token = 'token';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [],
    });

    if (!this.route.snapshot.queryParamMap.get(this.token)) {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('auth.activate-user.error.message')
      );

      this.router.navigate([PageRoute.login]);
    }
  }

  public submit(): void {
    if (this.form.valid) {
      const postData = this.form.value.password;

      this.authService.activateUser(
        this.route.snapshot.queryParamMap.get(this.token),
        this.route.snapshot.paramMap.get('id'),
        postData,
      ).subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('auth.activate-user.success.message')
        );

        this.router.navigate([PageRoute.login]);
      });
    }
  }
}
