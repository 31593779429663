<div class="input-radio-type">
    <cap-radio *ngFor="let option of to.options"
               [formControl]="formControl"
               [value]="option.value"
               (change)="handleChange($event)"
               [style]="to.style || null"
               [label]="option.label"
               [disabled]="to.disabled || false"
               (ngModelChange)="handleChange($event)"
               [formlyAttributes]="field">
    </cap-radio>
</div>
