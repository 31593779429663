<div class="app-overlay-modal" id="overlayModal" [class]="styleClass">
    <div class="app-overlay-modal__icon">
      <i [class]="icon" (click)="hide()"></i>
    </div>
    <div *ngIf="title" class="app-overlay-modal__title">
      <span>{{ title }}</span>
    </div>
    <ng-container>
      <ng-template capDynamicDialogContent></ng-template>
    </ng-container>
</div>



