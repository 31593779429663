import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PageConfig, PageConfigAction } from '@core/models/page-config.model';
import { InputType } from '@capturum/builders/core';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { take } from 'rxjs';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent {
    @Input()
    public pageConfig: PageConfig;
    public inputType = InputType;
    public fontAwesomeIcon = FontAwesomeIcon;

    public buttonClicked(button: PageConfigAction) {
        if (button.disabled === undefined) {
            button.callback();
        } else if (typeof button.disabled === 'boolean' && !button.disabled) {
            button.callback();
        } else if (typeof button.disabled === 'object') {
            button.disabled.pipe(take(1)).subscribe((disabled) => { if (!disabled) button.callback()} );
        }
    }

    public typeOf(object: any) {
        return typeof object;
    }
}
