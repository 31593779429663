<div class="body-part">
  <h5>2. {{ 'auth.2fa.sms.title' | translate }}</h5>

  <p>{{ 'auth.2fa.sms.description' | translate }}</p>
</div>

<form [formGroup]="smsForm">
  <cap-input
    type="text"
    styleClass="w-100 mt-3"
    formControlName="phoneNumber">
  </cap-input>

  <cap-button
    type="submit"
    [class.btn-disabled]="smsForm.invalid"
    [disabled]="smsForm.invalid"
    class="w-100"
    styleClass="primary w-100"
    [label]="(smsSent ? 'auth.2fa.sms.button.resend' : 'auth.2fa.sms.button.send') | translate"
    (click)="submit()">
  </cap-button>

  <h6 class="done-text mt-3 mb-4" *ngIf="smsSent">
    <i [ngClass]="fontAwesomeIcon.CheckCircle"></i> {{ 'auth.2fa.sms.send' | translate }}
  </h6>
</form>
