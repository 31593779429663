import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import {BehaviorSubject, EMPTY, throwError} from 'rxjs';
import { MapItem, ToastService} from '@capturum/ui/api';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonLoadingAnimationService } from '@shared/services/button-loading-animation.service';
import { VaporFileUploadService } from '@capturum/api';
import { FieldTypes } from '@core/enums/field-types.enum';
import { Field } from '@shared/field/field.enum';
import {catchError, filter, first, switchMap} from 'rxjs/operators';
import { SidebarAction } from '@core/enums/sidebar-actions.enum';
import { HttpEventType } from '@angular/common/http';
import { FormUtils } from '@core/utils/form.utils';
import { DashboardUsService } from '@features/dashboard-us/services/dashboard-us.service';
import { UserModel } from '@features/user/models/user.model';
import { UserService } from '@features/user/services/user.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { Entities } from '@core/components/entities.enum';
import { LocaleEnum } from '@core/enums/locale.enum';
import { DialogSubmitService } from '@shared/services/dialog-submit.service';

@Component({
  selector: 'app-file-upload-form',
  templateUrl: './file-upload-form.component.html',
  styleUrls: ['./file-upload-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadFormComponent {
  public USUploadForm: FormlyFieldConfig[];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public $status: BehaviorSubject<MapItem[]> = new BehaviorSubject([]);
  public currentUser: UserModel;
  public fontAwesomeIcon = FontAwesomeIcon;
  private subSink = new SubSink();

  constructor(
    public translateService: TranslateService,
    public cdr: ChangeDetectorRef,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    public apiService: DashboardUsService,
    private toastService: ToastService,
    private buttonLoadingAnimationService: ButtonLoadingAnimationService,
    private vaporFileUploadService: VaporFileUploadService,
    private userService: UserService,
    private dialogSubmitService: DialogSubmitService,
  ) {
  }

  public ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser() as UserModel;

    this.apiService.getFileTypesList().subscribe(statusArr => {
        if (this.currentUser?.currentRoleType?.key === Field.MboAdmin) {
            const mboDelivery = statusArr.file_types_us.values.find((item) => item.value === Entities.MboDelivery);

            this.$status.next([{
                    value: mboDelivery.key,
                    label: mboDelivery.translations[this.currentUser?.locale?.code ?? LocaleEnum.Dutch],
                }]
            );
        }

        if (this.currentUser?.currentRoleType?.key === Field.LpTransferpointAdmin) {
            const cDelivery = statusArr.file_types_us.values.find((item) => item.value === Entities.CDelivery);

            this.$status.next([{
                    value: cDelivery.key,
                    label: cDelivery.translations[this.currentUser?.locale?.code ?? LocaleEnum.Dutch],
                }]
            );
        }
    });

    this.USUploadForm = [
      {
        type: FieldTypes.Dropdown,
        key: Field.Type,
        templateOptions: {
          placeholder: this.translateService.instant('dashboard-us.uploaded-files.type.label'),
          translateLabel: this.translateService.stream('dashboard-us.uploaded-files.type.label'),
          options: this.$status,
          required: true,
        },
      },
      {
        key: Field.File,
        template: `<label class="d-block">File</label>`,
        templateOptions: {
          translateLabel: this.translateService.stream('mbo-check.file.label'),
          translatePlaceholder: this.translateService.stream('mbo-check.file.label'),
          required: true,
        },
      },
    ];

    this.subSink.add(
        this.dialogSubmitService.observable.subscribe(() => {
            if (this.form.valid && !this.buttonLoadingAnimationService?.isSideBarSubmitDisabled()) {
                this.buttonLoadingAnimationService?.disableSideBarSubmitButton('success.us-upload');
                this.vaporFileUploadService.uploadFile(this.form.value.file).pipe(
                    filter((response) => response.type === HttpEventType.Response),
                    first(),
                    catchError((error) => {
                        return throwError(error);
                    }),
                ).subscribe((r) => {
                    this.apiService.uploadFile({
                        type_id: this.form?.value?.type,
                        file: {
                            data: (r.data as any).uuid,
                            filename: this.form?.value?.file?.name,
                            mime_type: this.form?.value?.file?.name.indexOf('.zip') >= 0 ? 'application/zip' : 'text/csv',
                        },
                    }).subscribe(() => {
                        this.toastService.success(
                            this.translateService.instant('toast.success.title'),
                            this.translateService.instant('dashboard-us.file-upload.success.message')
                        );
                        this.dialogRef.close(SidebarAction.close);
                    }, () => {
                        this.buttonLoadingAnimationService?.enableSideBarSubmitButton('success.us-upload');
                    });
                },
                () => {
                    this.buttonLoadingAnimationService?.enableSideBarSubmitButton('success.us-upload');
                    this.toastService.error(
                        this.translateService.instant('toast.error.title'),
                        this.translateService.instant('dashboard-us.file-upload.error.message')
                    );
                });
            } else {
                FormUtils.markAsTouched(this.form);
            }
            this.cdr.detectChanges();
        })
    );

    this.cdr.detectChanges();
  }

  public uploadFile(files: File[]): void {
    this.form.patchValue({ file: files[0] });
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public removeFile(): void {
    this.form.get(Field.File).reset();
  }

}
