import { Injectable } from '@angular/core';
import { LayoutService } from './layout.service';
import { first } from 'rxjs/operators';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { StyleClass } from '@core/enums/style-class.enum';


const ANIMATION_CLASS = 'app-export-icon-spin__animation';
const DISABLED_SUBMIT_CLASS = 'right-sidebar__disable-button';
const DISABLED_BUTTON = 'button__disabled';

@Injectable({
  providedIn: 'root',
})

export class ButtonLoadingAnimationService {
  constructor(protected layoutService: LayoutService) {
  }

  public loadingLayoutButton(loading: boolean, identifier: string|number, defaultIcon: FontAwesomeIcon): void {
    this.layoutService.pageConfig$.pipe(first()).subscribe((value) => {
      if (typeof identifier === 'number') {
        const primaryButtonStyle = value.buttons[identifier].styleClass.replace(ANIMATION_CLASS, '');
        value.buttons[identifier].icon = loading ? FontAwesomeIcon.SpinnerThird : defaultIcon;
        value.buttons[identifier].disabled = loading;
        value.buttons[identifier].styleClass = `${primaryButtonStyle} ${loading ? `${primaryButtonStyle} ${ANIMATION_CLASS}` : ''}`;
      }
      if (typeof identifier === 'string') {
        value?.buttons?.forEach((button) => {
          if (button.label === identifier) {
            button.icon = loading ? FontAwesomeIcon.SpinnerThird : defaultIcon;
            button.disabled = loading;
            button.styleClass = `${button.styleClass.replace(ANIMATION_CLASS, '')} ${loading ? `${button.styleClass} ${ANIMATION_CLASS}` : ''}`;
          }
        });
      }

      this.layoutService.patchPageConfig({
        buttons: value?.buttons,
      });
    });
  }

  public disableSideBarSubmitButton(selector: string = StyleClass.Primary, icon: FontAwesomeIcon = FontAwesomeIcon.Save): void {
    const button = document.querySelector(`p-sidebar .${selector}`);
    const iconElement = button?.querySelector(`p-sidebar button .${icon.split(' ')[1]}`);
    button?.classList.add(DISABLED_SUBMIT_CLASS, ANIMATION_CLASS);
    iconElement?.classList?.remove(...icon.split(' '));
    iconElement?.classList?.add(...FontAwesomeIcon.SpinnerThird.split(' '));
  }

  public enableSideBarSubmitButton(selector: string = StyleClass.Primary, icon: FontAwesomeIcon = FontAwesomeIcon.Save): void {
    const button = document.querySelector(`p-sidebar .${selector}`);
    const iconElement = button?.querySelector(`p-sidebar button .fas`);
    button?.classList.remove(DISABLED_SUBMIT_CLASS, ANIMATION_CLASS);
    iconElement?.classList.remove(...FontAwesomeIcon.SpinnerThird.split(' '));
    iconElement?.classList.add(...icon.split(' '));
  }

  public disableButton(selector: string, icon: FontAwesomeIcon): void {
    const button = document.querySelector(`${selector}`);
    const iconElement = button?.querySelector(`.${icon.split(' ')[0]}`);
    button?.classList.add(DISABLED_BUTTON, ANIMATION_CLASS);
    iconElement?.classList?.remove(...icon.split(' '));
    iconElement?.classList?.add(...FontAwesomeIcon.SpinnerThird.split(' '));
  }

  public enableButton(selector: string, icon: FontAwesomeIcon): void {
    const button = document.querySelector(`${selector}`);

    if (button) {
        const iconElement = button.querySelector(`.fas`);
        button?.classList.remove(DISABLED_BUTTON, ANIMATION_CLASS);
        iconElement?.classList.remove(...FontAwesomeIcon.SpinnerThird.split(' '));
        iconElement?.classList.add(...icon.split(' '));
    }
  }

  public isSideBarSubmitDisabled(): boolean {
    return !!document.querySelector(`.right-sidebar__footer .${DISABLED_SUBMIT_CLASS}`);
  }
}
