import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ApiHttpService, ApiService } from '@capturum/api';
import { responseData, toMapItems } from '@core/utils/converter.utils';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TwoFactorMethod } from '@core/enums/helpers/two-way-auth.enum';
import { UserModel } from '@features/user/models/user.model';
import { HttpHeaders } from '@angular/common/http';
import { PageRoute } from '@core/enums/general/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthService extends ApiService<any> {
  protected endpoint = 'auth';

  constructor(
    private api: ApiHttpService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
    super(api);
  }

  public list2faMethods(): Observable<MapItem[]> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.list2faForceMethods();
    }
    return this.apiHttp.get(`/${this.endpoint}/2fa/methods`).pipe(
      toMapItems,
    );
  }

  public list2faForceMethods(): Observable<MapItem[]> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.get(`/${this.endpoint}/force-2fa/methods`, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(
      toMapItems,
    );
  }

  public login(postData: { user_id: string, method: string, key: string }): Observable<{ token: string, user: UserModel }> {
    try {
      return this.apiHttp.post(`/${this.endpoint}/login2fa`, postData);
    } catch (e) {
      return null;
    }
  }

  public getGoogleQrCode(): Observable<{ qrCode: SafeResourceUrl, secret: string }> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.getGoogleQrCodeForce();
    }
    return this.apiHttp.get<{ data: { qrCode: string, secret: string } }>(`/${this.endpoint}/2fa/setup/google`)
      .pipe(
        map((response) => ({
          ...response.data,
          qrCode: this.sanitizer.bypassSecurityTrustResourceUrl(response.data.qrCode),
        })),
      );
  }

  public getGoogleQrCodeForce(): Observable<{ qrCode: SafeResourceUrl, secret: string }> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.get<{ data: { qrCode: string, secret: string } }>(`/${this.endpoint}/force-2fa/setup/google`, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    })
      .pipe(
        map((response) => ({
          ...response.data,
          qrCode: this.sanitizer.bypassSecurityTrustResourceUrl(response.data.qrCode),
        })),
      );
  }

  public confirm2fa(method: string, key: string): Observable<{ success: boolean }> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.confirm2faForce(method, key);
    }
    return this.apiHttp.post(`/${this.endpoint}/2fa/confirm`, { method, key }).pipe(responseData);
  }

  public confirm2faForce(method: string, key: string): Observable<{ success: boolean }> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.post(`/${this.endpoint}/force-2fa/confirm`, { method, key }, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(responseData);
  }

  public setupEmail(): Observable<{ identifier: string }> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.setupForceEmail();
    }
    return this.apiHttp.get(`/${this.endpoint}/2fa/setup/email`).pipe(responseData);
  }

  public setupForceEmail(): Observable<{ identifier: string }> {
    const token = localStorage.getItem('tempToken');

    return this.apiHttp.get(`/${this.endpoint}/force-2fa/setup/email`, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(responseData);
  }

  public register(method: string, identifier: string): Observable<{ success: boolean }> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.registerForce(method, identifier);
    }
    return this.apiHttp.post(`/${this.endpoint}/2fa/register`, { method, identifier }).pipe(responseData);
  }

  public registerForce(method: string, identifier: string): Observable<{ success: boolean }> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.post(`/${this.endpoint}/force-2fa/register`, { method, identifier }, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(responseData);
  }

  public confirm(method: string, identifier: string): Observable<{ success: boolean }> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.post(`/${this.endpoint}/force-2fa/confirm`, { method, identifier }, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(responseData);
  }

  public setupSms(): Observable<{ identifier: string }> {
    if (window.location.pathname === PageRoute.twoFactorActivate) {
      return this.setupSmsForce();
    }
    return this.apiHttp.get(`/${this.endpoint}/2fa/setup/sms`).pipe(responseData);
  }

  public setupSmsForce(): Observable<{ identifier: string }> {
    const token = localStorage.getItem('tempToken');
    return this.apiHttp.get(`/${this.endpoint}/force-2fa/setup/sms`, {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${token}`),
    }).pipe(responseData);
  }

  public send2faResetMail(user: { id: string, name: string }): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/send-disable-email`, { user_id: user.id });
  }

  public disable2fa(token: string): Observable<{ success: boolean }|string[]> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/disable?token=${token}`, { token });
  }

  public requestDisable(): Observable<{ success: boolean }> {
    return this.apiHttp.get(`/${this.endpoint}/2fa/request-disable`);
  }

  public confirmDisable(method: TwoFactorMethod, key: string): Observable<{ success: boolean }> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/confirm-disable`, { method, key });
  }
}
