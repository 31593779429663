import { Component, EventEmitter, Injector} from '@angular/core';
import { IntBaseListComponent } from '@shared/base/int-base-list.component';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { CapturumDialogService, FilterType, MapItem, TableAction } from '@capturum/ui/api';
import { Field } from '@shared/field/field.enum';
import { TranslateService } from '@ngx-translate/core';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { PageConfig } from '@core/models/page-config.model';
import { UserModel } from '@features/user/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { DashboardUsService } from '@features/dashboard-us/services/dashboard-us.service';
import { UserService } from '@features/user/services/user.service';
import { FileUploadFormComponent } from '@features/dashboard-us/components/file-upload-form/file-upload-form.component';
import { RightSidebarComponent } from '@shared/components/right-sidebar/right-sidebar.component';
import { StyleClass } from '@core/enums/style-class.enum';
import { Entities } from '@core/components/entities.enum';
import base64FileDownload from '@shared/utils/base64-file-download/base64-file-download';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import { SidebarAction } from '@core/enums/sidebar-actions.enum';

@Component({
  selector: 'app-dashboard-us-uploaded-files',
  templateUrl: './dashboard-us-uploaded-files.component.html',
  providers: [CapturumDialogService, DynamicDialogRef],
  styleUrls: ['./dashboard-us-uploaded-files.component.scss'],
})
export class DashboardUsUploadedFilesComponent extends IntBaseListComponent<any> {

  public tableColumns: InfoTableColumn[];
  public tableField: typeof Field = Field;
  public includes = [Field.UploadedByUser, Field.Type, Field.Status, `${Field.File}.${Field.Name}`];
  public $status: BehaviorSubject<MapItem[]> = new BehaviorSubject([]);
  public currentUser: UserModel;
  public tableActions: TableAction[];

  protected entity = Entities.DashboardUS;
  private download = 'download';

  constructor(
    injector: Injector,
    public apiService: DashboardUsService,
    public translateService: TranslateService,
    public dialogRef: DynamicDialogRef,
    private dialogService: CapturumDialogService,
    private userService: UserService,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.stateKey = 'us-uploaded-files-list';
    super.ngOnInit();

    this.currentUser = this.userService.getCurrentUser() as UserModel;

    this.apiService.getFileTypesList().subscribe((statusArr) => {
      if (this.currentUser?.currentRoleType?.key === Field.MboAdmin) {
        this.$status.next([{
            value: statusArr.file_types_us.values[1].value,
            label: statusArr.file_types_us.values[1].translations[this.currentUser?.locale?.code],
          }],
        );
      }

      if (this.currentUser?.currentRoleType?.key === Field.LpTransferpointAdmin) {
        this.$status.next([{
            value: statusArr.file_types_us.values[0].value,
            label: statusArr.file_types_us.values[0].translations[this.currentUser?.locale?.code],
          }],
        );
      }
    });

    this.tableColumns = [
      {
        field: `${Field.Type}.${Field.Value}`,
        title: this.translateService.instant('dashboard-us.uploaded-files.type.label'),
        type: InfoTableColumnType.Template,
      },
      {
        field: `${Field.File}.${Field.FileName}`,
        title: this.translateService.instant(
          'dashboard-us.uploaded-files.file-name.label',
        ),
      },
      {
        field: `${Field.Status}.${Field.Value}`,
        title: this.translateService.instant(
          'dashboard-us.uploaded-files.status.label',
        ),
        type: InfoTableColumnType.Template,
      },
      {
        field: Field.Created_at,
        title: this.translateService.instant('dashboard-us.uploaded-files.uploaded_at.label'),
        type: InfoTableColumnType.Template,
      },
      {
        field: `${Field.UploadedByUser}.${Field.Name}`,
        title: this.translateService.instant('dashboard-us.uploaded-files.uploaded_by.label'),
      },
    ];

    this.filterConfig = [
      {
        field: `${Field.Type}.${Field.Value}`,
        type: FilterType.MULTISELECT,
        options: this.$status,
        placeholder: this.translateService.instant('dashboard-us.uploaded-files.type.label'),
        icon: FontAwesomeIcon.Search,
      },
    ];
  }

  public getDefaultConfig(): PageConfig {
    return {
      title: this.translateService.instant('dashboard-us.uploaded-files.overview.title'),
      buttons: [{
        label: 'dashboard-us.uploaded-files.upload-file.label',
        icon: FontAwesomeIcon.Plus,
        styleClass: StyleClass.Primary,
        permissions: ['dashboard-us.dashboard-us.manage'],
        callback: () => this.openSideBar(),
      }],
    };
  }

  private downloadItem(id: string): void {
    this.apiService.downloadItem(id).subscribe((response) => {
      base64FileDownload(response.data.mimetype, response.data.file_content, response.data.filename);
      this.toastService.success(this.translateService.instant('toast.success.title'), this.translateService.instant('dashboard-us.dashboard-reports.report.download-success'));
    }, error => {
      this.toastService.error(this.translateService.instant('toast.error.title'), error);
    });
  }

  private openSideBar(): void {
    this.dialogRef = this.dialogService.open(
      FileUploadFormComponent,
      {
        data: {
          showButtons: true,
          user: this.currentUser,
          submitButtonStyleClass: 'success us-upload',
        },
        header: this.translateService.instant('dashboard-rmc.uploaded-files.upload-file.side-bar.title'),
      },
      RightSidebarComponent,
    );

    this.dialogRef.onClose.subscribe((data) => {
        if (data === SidebarAction.close) {
            this.dialogRef?.destroy();
            this.loadTableData(this.lazyLoadEvent);
        }
    });
  }
}
