<div class="app-rmc-file-upload-form">
  <form [formGroup]="form">
    <formly-form [fields]="USUploadForm"
                 [form]="form">
    </formly-form>
    <cap-file-upload *ngIf="!form.get('file')?.value"
                       [multiple]="false"
                       [uploaderContent]="uploaderContent"
                       accept=".csv, .zip, .xlsx"
                       (onFileChange)="uploadFile($event)">
    </cap-file-upload>

    <div class="d-flex" *ngIf="form.get('file')?.value as file">
          <label class="label-upload-disabled">{{ file.name }}</label>
          <i [ngClass]="[fontAwesomeIcon.Times, 'py-1', 'px-1']" (click)="removeFile()"></i>
    </div>
  </form>
</div>

<ng-template #uploaderContent>
  <i [ngClass]="fontAwesomeIcon.CloudUploadAlt"></i> {{ 'file-upload.select-file' | translate }}
  <span class="file-upload-drag-label">{{ 'file-upload.or-drag-file' | translate }}</span>
</ng-template>
