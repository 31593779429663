import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserModel } from '@features/user/models/user.model';
import { Injectable } from '@angular/core';
import { ImpersonateUser, ResetUserState, SetUser, StopImpersonateUser } from './user.actions';

export interface UserStateModel {
  user: UserModel;
  isImpersonating: boolean;
  originalUser: UserModel;
}

@State<UserStateModel>({
  name: 'userState',
})
@Injectable()
export class UserState {

  @Selector()
  public static user(state: UserStateModel): UserModel {
    return state.user;
  }

  @Selector()
  public static isImpersonating(state: UserStateModel): boolean {
    return state.isImpersonating;
  }

  @Action(SetUser)
  public setRole({ getState, setState }: StateContext<UserStateModel>, payload: SetUser): void {
    setState({
      ...getState(),
      user: payload.user,
    });
  }

  @Action(ImpersonateUser)
  public impersonateUser({ getState, setState }: StateContext<UserStateModel>, payload: ImpersonateUser): void {
    setState({
      ...getState(),
      isImpersonating: true,
      user: payload.userToImpersonate,
      originalUser: getState().user,
    });
  }

  @Action(StopImpersonateUser)
  public stopImpersonateUser({ getState, setState }: StateContext<UserStateModel>): void {
    setState({
      ...getState(),
      isImpersonating: false,
      user: getState().originalUser,
      originalUser: null,
    });
  }

  @Action(ResetUserState)
  public resetUserState({ setState }: StateContext<UserStateModel>): void {
    setState({
      isImpersonating: false,
      user: null,
      originalUser: null,
    });
  }
}
