export enum Field {
  AddChoice = 'add-choice',
  Actions = 'actions',
  Active = 'active',
  Contact = 'contact',
  AddedBy = 'addedBy',
  AmountOfSchools = 'amountOfSchools',
  BestChoice = 'bestChoice',
  BirthdateInfo = 'birthdateInfo',
  Category = 'category',
  ChoicesInstance = 'ChoicesInstance',
  Choiceable = 'choiceable',
  Class = 'class',
  ContactName = 'contanct_name',
  Count = 'count',
  Crebo = 'crebo',
  Code = 'code',
  TrainingCrebo = 'training_crebo',
  TrainingUnit = 'training_unit',
  CreatedAt = 'createdAt',
  Created_at = 'created_at',
  CurrentStudentYear = 'currentStudentYear',
  Definite = 'definite',
  Deleted_at = 'deleted_at',
  Delete = 'delete',
  Description = 'description',
  Diploma = 'diploma',
  EducationCode = 'education_code',
  Email = 'email',
  Errors = 'errors',
  FromDate = 'from_date',
  FullName = 'fullname',
  StudentFetchServiceTypeBaseDataValueId= 'student_fetch_service_type_base_data_value_id',
  Has2fas = 'has_2fa',
  Has2fasSms = 'has_2fa_sms',
  Id = 'id',
  Bsn = 'bsn',
  Instance = 'instance',
  InQueue = 'inQueue',
  Is_enabled = 'is_enabled',
  Is_admin = 'is_admin',
  Is_active = 'is_active',
  LearningTrack = 'learningTrack',
  LearningTracks = 'learningTracks',
  LatestTraining = 'latestTraining',
  Name = 'name',
  Full_Name = 'full_name',
  StudentNumber = 'student_number',
  SchoolClass = 'schoolClass',
  Grade = 'grade',
  Mentor = 'mentor',
  RegisteredAt = 'registered_at',
  Value = 'value',
  Parent = 'parent',
  SchoolClassName = 'school_class_name',
  Key = 'key',
  LastLoginAt = 'last_login_at',
  LatestChoiceUpdate = 'latestChoiceUpdate',
  Level = 'level',
  LinkType = 'linkType',
  MboInstitution = 'mboInstitution',
  MboCheckService = 'mboCheckService',
  MboLearningTrack = 'mboLearningTrack',
  Message = 'message',
  MinimumSignupStatus = 'minimumSignupStatus',
  MobilePhone = 'mobile_phone',
  Modules = 'modules',
  ModulesAmount = 'modules_amount',
  TwoFactorAuthEnabledAt = 'two_factor_auth_enabled_at',
  Module = 'module',
  Municipality = 'municipality',
  MunicipalitiesCount = 'municipalities_count',
  MentorsList = 'mentorsList',
  NotUnderConsideration = 'not_under_consideration_anymore',
  Number = 'number',
  NumberOfRecipients = 'number_of_recipients',
  PersonalId = 'personal_id',
  PronexusService = 'pronexusService',
  PronexusServiceMunicipality = 'pronexusServiceMunicipality',
  Participant_number = 'participant_number',
  Phase = 'phase',
  Rank = 'rank',
  Recipients = 'recipients',
  Region = 'region',
  Regions = 'regions',
  RioCode = 'rio_code',
  Roles = 'roles',
  Role = 'role',
  RoleUser = 'roleUser',
  RoleId = 'role_id',
  Rows = 'rows',
  Row = 'row',
  SchoolType = 'school_type',
  SchoolTypeCamel = 'schoolType',
  School = 'school',
  Sector = 'sector',
  Send_at = 'sent_at',
  Severity = 'severity',
  SignupDate = 'signupDate',
  SignupStatus = 'signupStatus',
  Status = 'status',
  IsStatusActive = 'is_status_active',
  StatusLpRmc = 'statusLpRmc',
  StudentsWithChoiceCount = 'students_with_choice_count',
  StudentName = 'student_name',
  Soft_Match_Student_Name = 'soft_match_student_name',
  Student = 'student',
  StudentYear = 'studentYear',
  StudentYears = 'studentYears',
  Subject = 'subject',
  Success = 'success',
  TableActions = 'tableActions',
  TemplateName = 'template_name',
  Translations = 'translations',
  Transfers = 'transfers',
  Transfer = 'transfer',
  TillDate = 'till_date',
  RecipientGroupId = 'recipient_group_id',
  Type = 'type',
  MboCheckFetchServiceType = 'mboCheckFetchServiceType',
  Training = 'training',
  TrainingName = 'training_name',
  TwoFactorAuth = 'two_factor_auth',
  UserName = 'userName',
  User_name = 'user_name',
  Asc = 'asc',
  Desc = 'desc',
  User = 'user',
  UserObject = 'user_object',
  CurrentRoleType = 'currentRoleType',
  RoleTypes = 'roleTypes',
  UserID = 'user_id',
  Users = 'users',
  Updated_at = 'updated_at',
  UpdatedAt = 'updatedAt',
  UnderConsideration = 'under_consideration',
  Visible = 'visible',
  VoSchoolGroups = 'voSchoolGroups',
  Vo_id = 'vo_id',
  AllClasses = 'allClasses',
  Preference = 'preference',
  Changeable_type = 'changeable_type',
  Source_name = 'source_name',
  Client_name = 'client_name',
  Subscriber_name = 'subscriber_name',
  Original_id = 'original_id',
  Method = 'method',
  Timestamp = 'timestamp',
  Originator = 'originator',
  Logged_At = 'logged_at',
  Class_method = 'class_method',
  Model_class = 'model_class',
  Changeable_id = 'changeable_id',
  Source = 'source',
  RecievedAt = 'recieved_at',
  RegionsId = 'regions_id',
  ApprovedBy = 'approved_by',
  ApprovedAt = 'approved-at',
  RejectedBy = 'rejected_by',
  RejectedAt = 'rejected_at',
  ClaimedAt = 'claimed_at',
  SoftMatch = 'softMatch',
  LearningPath = 'learningPath',
  SchoolOfOriginName = 'school_of_origin_name',
  StudentsSchoolClassName = 'students_school_class_name',
  StudentFullName = 'student_fullname',
  ChoiceNumber = 'nr_of_choices',
  ChoiceNumberOtherMbo = 'nr_of_choices_other_mbo',
  MunicipalityName = 'municipality_name',
  LatestTrainingName = 'latest_training_name',
  TransferTypeStatusLpRmc = 'transfer_type_lprmc_status_name',
  AmountOfDaysInLprmcStatus = 'amount_of_days_in_lprmc_status',
  AmountOfDaysInTransfercoachStatus = 'amount_of_days_in_transfercoach_status',
  AmountOfDaysInStatus = 'amount_of_days_in_status',
  TransferTypeStatusTransferCoach = 'transfer_type_transfercoach_status_name',
  SchoolName = 'school_name',
  ChoicesInstanceName = 'choice_instance',
  ChoiceStatusName = 'status_name',
  UnsubscribeDate = 'unsubscribe_date',
  LearningTrackValueId = 'learning_track_base_data_value_id',
  InstitutionName = 'institution_name',
  CityId = 'city_id',
  Schools = 'schools',
  StatusLowerThan = 'status_lower_than',
  Round = 'round',
  Action = 'action',
  Executor = 'executor',
  TargetDate = 'target_date',
  SchoolListNames = 'school_list_names',
  SignupStatusName = 'signup_status_name',
  NumberOfStudents = 'number_of_students',
  SchoolList = 'school_list',
  DiscussionRoundId = 'discussion_round_id',
  DiscussionRoundName = 'discussion_round_name',
  StudentId = 'student_id',
  Address = 'address',
  Addition = 'addition',
  Affix = 'affix',
  ApiKey = 'api_key',
  Birthdate = 'birthdate',
  BSN = 'bsn',
  City = 'city',
  Country = 'country',
  FirstName = 'firstname',
  File = 'file',
  File_Name = 'file_name',
  FileName = 'filename',
  GenderBaseDataValueId = 'gender_base_data_value_id',
  HouseNumber = 'housenumber',
  Initials = 'initials',
  Learning_Track = 'learning_track',
  MunicipalityId = 'municipality_id',
  Phone = 'phone',
  School_Class = 'school_class',
  Street = 'street',
  Student_Year = 'student_year',
  Student_Years = 'student_years',
  Surname = 'surname',
  Url = 'url',
  Zipcode = 'zipcode',
  Identifier = 'identifier',
  Institution = 'institution',
  VoInstitution = 'voInstitution',
  Password = 'key',
  Reason = 'reject_reason',
  MboCheckFetchServiceTypeId = 'mbo_check_fetch_service_type_id',
  SectorIds = 'sector_ids',
  CreboIds = 'crebo_ids',
  MentorpartAvailable = 'mentorpart_available',
  InstanceId = 'instance_id',
  InstanceIds = 'instance_ids',
  ModuleId = 'module_id',
  RoleTypeId = 'role_type_id',
  Controls = 'controls',
  Contact_Name = 'contact_name',
  Contact_Function = 'contact_function',
  Contact_Available_On = 'contact_available_on',
  Remarks = 'remarks',
  Function = 'function',
  SignUpStatusId = 'signup_status_id',
  SectorId = 'sector_id',
  MBOLearningTackBaseDataValueId = 'mbo_learning_track_base_data_value_id',
  EndDate = 'end_date',
  Has2fa = 'has_2fa',
  Has2FaSms = 'has_2fa_sms',
  MinimumStatusId = 'minimum_status_id',
  MboInstitutionId = 'mbo_institution_id',
  Member = 'member',
  MboInstitutionLink = 'mboInstitutionLink',
  RoleType = 'role-type',
  RoleTypeRelation = 'roleType',
  RegionId = 'region_id',
  StatusBaseDataValueId = 'status_base_data_value_id',
  Search = 'search',
  Students = 'students',
  SchoolTypeBaseDataValueId = 'school_type_base_data_value_id',
  SchoolId = 'school_id',
  SignupStatusId = 'signup_status_id',
  StartDate = 'start_date',
  TrainingId = 'training_id',
  Till_Date_Time = 'till_date_time',
  From_Date_Time = 'from_date_time',
  Source_Name = 'source_Name',
  SpecialTransferStatus = 'specialTransferStatus',
  SpecialTransfer = 'specialTransfer',
  Upload_date = 'upload_date',
  DeliveredBy = 'deliveredBy',
  Organisation = 'organisation',
  Logo = 'logo',
  Brin = 'brin',
  InstitutionableId = 'institutionable_id',
  IsRegionSchool = 'is_region_school',
  CategoryBaseDataValueId = 'category_base_data_value_id',
  CurrentEducation = 'currentEducation',
  CurrentStatus = 'currentStatus',
  Birthday = 'birthday',
  Postcode = 'postcode',
  Vervolgkeuze = 'vervolgkeuze',
  Intergrip_Module = 'intergrip_module',
  Signup = 'signup',
  FormSection = 'form-section',
  AddressField = 'address-field',
  Application = 'application',
  InterventionTypeBaseDataValueId = 'intervention_type_base_data_value_id',
  CreatedByUser = 'createdByUser',
  EditableTill = 'editable_till',
  EditableFrom = 'editable_from',
  Location = 'location',
  LocationIds = 'location_ids',
  Student_Fullname = 'student_fullname',
  Handle = 'handle',
  Json = 'json',
  Has2faMandatory = 'has_2fa_mandatory',
  Applicant = 'applicant',
  Tenant = 'tenant',
  StatusDecreaseNewStatus = 'statusDecreaseNewStatus',
  StatusDecreasePreviousStatus = 'statusDecreasePreviousStatus',
  StatusDecreaseSchoolClass = 'statusDecreaseSchoolClass',
  Fullname = 'fullname',
  Measuring_Date = 'measuring_date',
  Previous_Best_Choice_Status_Name = 'previous_best_choice_status_name',
  New_Best_Choice_Status_Name = 'new_best_choice_status_name',
  Uploaded_At = 'uploaded_at',
  Uploaded_By = 'uploaded_by',
  UploadedByUser = 'uploadedByUser',
  ContactPerson = 'contact_person',
  PhoneNumber = 'phone_number',
  Result = 'result',
  TransferStatus = 'transferStatus',
  IsRegional = 'is_regional',
  StatusMutation = 'statusMutation',
  SourceBaseDataValueId = 'source_base_data_value_id',
  MboAdmin = 'mbo-admin',
  LpTransferpointAdmin = 'lp-transferpoint-admin',
  Previous_Choice_Instance = 'previous_choice_instance',
  New_Choice_Instance = 'new_choice_instance',
  WorkshopLeader = 'workshop_leader',
  DeletedRoles = 'deleted_roles',
  NewRoles = 'new_roles',
  CommunicationId = 'communication_id',
  Queued = 'queued',
  Sent = 'sent',
  IntakeDate = 'intake_date',
  ReasonEnded = 'reason_ended',
  DaysInStatus = 'number_of_days_in_status',
  MboCheckBaseDataValueId = 'mbo_check_type_base_data_value_id',
  DeliveryTypeBaseDataValueId = 'delivery_type_base_data_value_id',
  ContactInformation = 'contact_information',
  MboCheckTypeTranslation = 'mbo_check_type_translation',
  DeliveryTypeTranslation = 'delivery_type_translation',
  LastExpectedDelivery = 'last_expected_delivery',
  LastReceived = 'last_received',
  LastProcessed = 'last_processed',
  ProcessedAt = 'processed_at',
  Admin = 'admin',
  UserPartBAvailable = 'userPartBAvailable',
  Emailaddress = 'emailaddress',
  LastLoggedIn = 'lastloggedin',
  PartBEnabled = 'partBEnabled',
  Enabled = 'enabled',
  RoleUsers = 'roleUsers',
  YearMonth = 'year_month',
  TrainingAmount = 'countTrainings',
  ChoiceLocations = 'choiceLocations',
  Image = 'image',
  NotAvailablePartB = 'notAvailablePartB',
  HasTrainings = 'hasTrainings',
  MBOName = 'mboName',
  LocationName = 'locationName',
  MBO = 'mbo',
  LatestTrainingChanged = 'latestTrainingChanged',
  CountActiveTrainings = 'countActiveTrainings',
  DateTill = 'date_till',
  DateFrom = 'date_from',
  State = 'state',
  Opens = 'opens',
  Clicks = 'clicks',
  ConsultantName = 'consultant_name',
  ConsultantId = 'consultant_id',
  Segment = 'segment',
  Budget = 'budget',
  Spent = 'spent',
  Remaining = 'remaining',
  Percentage = 'percentage',
  Price = 'price',
  Index = 'index',
  Indicator= 'indicator',
  Amount = 'amount',
  Cluster = 'cluster',
  Class_Name = 'class_name',
  Cluster_Name = 'cluster_name',
  Assignment = 'assignment',
  Trajectory = 'trajectory',
  Entry = 'entry',
  Exit = 'exit',
  Duration = 'duration',
  WaitingTime = 'waiting_time',
  DeclTime = 'declaration_time',
  AvgPrice = 'average_price',
  Purchasing = 'purchasing',
  Funding = 'funding',
  Certification = 'certification',
  ProductTypeX = 'product_type_x',
  ProductTypeY = 'product_type_y',
  ProductTypeZ = 'product_type_z',
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Expired = 'expired',
  Submitted = 'submitted',
  Approved = 'approved',
  Declined = 'declined',
  StatusCategory = 'statusCategory',
  BestChoiceLearningTrack = 'bestChoiceLearningTrack',
  MunicipalityToCompare = 'municipality_to_compare',
  MunicipalityToCompareWith = 'municipality_to_compare_with',
  Difference = 'difference',
  ComparisonAction = 'comparison_action',
  TrainingStatusBaseData = 'training_status_base_data_value_id',
  TrainingStatus = 'trainingStatus',
  Title = 'title',
  Label = 'label',
  MunicipalityProperties = 'municipalityProperties',
  LatestJob = 'latestJob',
  RioConnectionJobStatus = 'rioConnectionJobStatus',
  RegistrationNumber = 'registrationNumber',
  RegistrationNumberNationalDatabase = 'registration_number_national_database',
  DaycareOrganisation = 'daycareOrganisation',
  PoInstitution = 'poInstitution',
  Lines = 'lines',
  Municipalities = 'municipalities',
  ProductName = 'product_name',
  ApplicantName = 'applicant_name',
  SaleStatus = 'sale_status',
  LastName = 'last_name',
  Group = 'group',
  RmcRegionId = 'rmc_region_id',
  RmcRegion = 'rmcRegion',
  LatestStudentListFile = 'latestStudentListFile',
  LineCount = 'line_count',
  ImportedByUser = 'importedByUser',
  StudentListFiles = 'studentListFiles',
  HasChildListSchools = 'has_child_list_schools',
  ImportedByUserName = 'imported_by_user_name',
  ImportedAt = 'imported_at',
  Column = 'column',
  Explanation = 'explanation',
  Button = 'button',
  ChildYear = 'currentChildYear',
  StudentListFileRowId = 'student_list_file_row_id',
  Field = 'field',
  RowNumber = 'row_number',
  FromAvailableChildren = 'fromAvailableChildren',
  ObservationCodeTranslation = 'observation_code_translation',
  ObservationStatusTranslation = 'observation_status_translation',
  ChildFullname = 'child_fullname',
  IdentifyingNumber = 'identifying_number',
  Observation = 'observation',
  ObservationCodeBaseDataValueId = 'observation_code_base_data_value_id',
  ObservationCreatedAt = 'observation_created_at',
  Child = 'child',
  ChildGroup = 'child_group',
  Part = 'part',
  UID = 'uid',
  ChildcareCenter = 'childcare-center',
  ChildName = 'child_name',
  DaycareOrigin = 'daycare_origin',
  SchoolOfOrigin = 'school_of_origin',
  PropertyValues = 'propertyValues',
  Property = 'property',
  VveIndication = 'vve_indication',
  ReceivedIndicationOn = 'received_indication_on',
  SpecialTransferKov = 'special_transfer',
  FromApprovedTransferRequests = 'fromApprovedTransferRequests',
  SchoolClassId = 'school_class_id',
  ChildrenOnly = 'childrenOnly',
  FromAvailableStudents = 'fromAvailableStudents',
  Content = 'content',
  ObservationStatusBaseDataValueId = 'observation_status_base_data_value_id',
  RegionIdContent = 'regionId',
  Choice = 'choice',
  ChoiceId = 'choiceId',
  TelephoneNumber = 'telephoneNumber',
  VoSchool = 'voSchool',
  IsParticipating = 'isParticipating',
  IndexNumber = 'index_number',
  Pgn = 'pgn',
  IsProcessed = 'is_processed',
  StudentFetchServiceType = 'studentFetchServiceType',
  LatestStudentFetchServiceJob = 'latestStudentFetchServiceJob',
  StudentFetchServiceJobStatus = 'studentFetchServiceJobStatus',
  VveStartDate = 'vve_start_date',
  UsersRoleTypes = 'users_role_types',
  SomtodayOrganisationUuid = 'somtoday_organisation_uuid',
  SomtodayBranchUuid = 'somtoday_branch_uuid',
  Department = 'department',
  AlternativeContactName = 'alternative_contact_name',
  AlternativeContactFunction = 'alternative_contact_function',
  AlternativeContactMailAddress = 'alternative_contact_email',
  AlternativeContactPhoneNumber = 'alternative_contact_phone_number',
  WeekDay = 'week_day',
  Buttons = 'buttons',
  StartTime = 'start_time',
  EndTime = 'end_time',
  MobilePhoneNumber = 'mobile_phone_number',
  SocialMapAvailabilities = 'socialMapAvailabilities',
  VisibleInOverview = 'visible_in_overview',
  CurrentFunction = 'current_function',
}
