import { UserModel } from '@features/user/models/user.model';
import { Entities } from '@core/components/entities.enum';
import { PageConfig } from '@core/models/page-config.model';
import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
  Renderer2,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PageRoute } from '@core/enums/general/routes.enum';
import { UserService } from '@features/user/services/user.service';
import { AuthService } from '@features/auth/services/auth.service';
import { ModuleService } from '@features/module/services/module.service';
import { TranslateService } from '@ngx-translate/core';
import { IntBaseListComponent } from '@src/app/shared/base/int-base-list.component';
import { Modules } from '@core/enums/modules.enum';
import { LegacyKeyResponseInterface } from '@core/models/legacy-key-response.interface';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LegacyComponent extends IntBaseListComponent<any> implements OnInit {
  @HostBinding('class')
  public class = 'legacy-component';

  public url: SafeResourceUrl;
  public currentUrlString: string[];
  public height = '0px';
  @ViewChild('legacyIframe', { static: true })
  public legacyIframe: ElementRef;
  public currentUser: UserModel;

  public loading = true;
  protected moduleName: string;
  protected portalAliases = [
    'manage',
  ];

  constructor(
    public injector: Injector,
    public route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authService: AuthService,
    public moduleService: ModuleService,
    public translateService: TranslateService,
    private _renderer: Renderer2,
  ) {
    super(injector);
    this.currentUrlString = this.router.url.split('/');
    this.moduleName = this.currentUrlString[1];

    if (this.moduleName === Modules.Intergrip) {
      this.moduleName = this.currentUrlString.indexOf('_vombo') > -1 ? 'overstap' : 'portal';
    }

    if (this.router.url.includes(`${Entities.ImportStudent}`)) {
      this.moduleName = 'upload';
    }

    this.userService.legacyKey(this.moduleName).subscribe(response => {
      this.setUrl(response.data.legacyKey, response.data.legacyBaseUrl, this.route.routeConfig.path);
      window.addEventListener('message', event => {
        this.handlePotentialLogout(event, response.data);
        this.handleHeightUpdate(event, response.data);
        this.handleScrollTop(event, response.data);
        this.handleScrollBottom(event, response.data);
        this.redirectToMentor(event, response.data);
        this.handleScrollToImage(event, response.data);
      });
    });
  }

  public handleScrollTop(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if (event.data === 'scrollToTop' || (typeof event.data !== 'object' && event.data.indexOf('popup') > -1)) {
      document.querySelector('.page-wrapper').scrollTo(0, 0);
    }
  }

  public handleScrollBottom(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if (event.data === 'scrollToBottom') {
        document.querySelector('.page-wrapper').scrollTo(0, 99999);
    }
  }

  public redirectToMentor(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if (event.data === 'redirectToMentor') {
        this.router.navigate(['/manage/student/mentor/overview']);
    }
  }

  public handleScrollToImage(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if ((typeof event.data !== 'object' && event.data?.indexOf('scrollToY.') > -1)) {
      const yCord = parseInt(event.data.split('.')[1], 10);
      document.querySelector('.page-wrapper').scrollTo(0, yCord);
    }
  }

  public handlePotentialLogout(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if (event.origin === response.legacyBaseUrl && event.data === 'logout') {
      this.authService.logout().subscribe(() => {
        this.router.navigate([PageRoute.login]);
      });
    }
  }

  public handleHeightUpdate(event: MessageEvent, response: LegacyKeyResponseInterface): void {
    if (
      event.origin.indexOf('intergrip')
      && event.data !== undefined
      && typeof event.data === 'string'
      && event.data.indexOf('scrollHeight.') > -1
    ) {
      const newHeight = parseInt(event.data.split('.')[1], 10);
      if (event.data.indexOf('popup') === -1) {
        this.height = `${newHeight}px`;
      }
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this._renderer.listen(this.legacyIframe.nativeElement, 'load', () => {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    });
  }

  public getDefaultConfig(): PageConfig {
    let key = `intergrip.${this.currentUrlString[1]}.${this.currentUrlString[2]}.overview.title`;
    if (this.currentUrlString?.length >= 4) {
      let backUrl = `/${this.currentUrlString[1]}/${this.currentUrlString[2]}`;

      switch (true) {
        case this.router.url.includes(Entities.myStudents):
          key = `intergrip.${this.currentUrlString[1]}.student.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes('student/education_day'):
          key = `intergrip.${this.currentUrlString[1]}.search.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.List}/`):
          key = `${this.currentUrlString[1]}.menu.list.title`;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.Settings}`):
          key = `${this.currentUrlString[1]}.menu.settings.title`;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.monitorusImportFiles}`):
          key = `intergrip.${this.currentUrlString[1]}.import-files.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.monitorusData}`):
          key = `intergrip.${this.currentUrlString[1]}.data.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.monitorusReportsSubmenu}`):
          key = `${this.currentUrlString[1]}.menu.reports.title`;
          break;

        case this.router.url.includes(`${Modules.Monitorus}/${Entities.Info}`):
          key = `${this.currentUrlString[1]}.menu.info.title`;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.activityOverview}`):
          key = `${this.currentUrlString[1]}.menu.activities.title`;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.Settings}`):
          key = `${this.currentUrlString[1]}.menu.settings.title`;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.registrations}`):
          key = `${this.currentUrlString[1]}.menu.registrations.title`;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.student}`):
          key = `${this.currentUrlString[1]}.menu.students.title`;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.intergrip_students}`):
          key = `${this.currentUrlString[1]}.menu.all-students.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.LobVoorlichtingen}/${Entities.lobVoorlichtingenActivities}`):
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.Ddd}/${Entities.voDossiers}/${Entities.dossier}`):
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.LobActivities}/${Entities.myStudentTab}`):
          key = `${this.currentUrlString[1]}.menu.my-students.title`;
          backUrl = `/${Modules.LobActivities}/${Entities.myStudentTab}`;
          break;

        case this.router.url.includes(`${Entities.intergrip1Students}`) &&
        this.router.url.includes(`${Entities.Students}`) && !this.router.url.includes('student-portal'):
          key = `${this.currentUrlString[1]}.student.overview.title`;
          break;

        case this.router.url.includes(`${Modules.Intergrip}/${Entities.Instances}/${Entities.location}`):
          key = `${this.currentUrlString[1]}.instances.overview.title`;
          break;

        case this.router.url.includes(`${Entities.Communications},${Entities.ContentNotificationsSuper}`):
          key = 'intergrip.sub-menu.content-notifications.title';
          break;

        case this.router.url.includes(`${Entities.Communications},${Entities.ContentNotificationsAdmin}`):
          key = 'intergrip.sub-menu.content-notifications.title';
          break;

        case this.router.url.includes(`${Modules.Intergrip}/${Entities.Students}`):
          key = `${this.currentUrlString[1]}.menu.students`;
          break;

        case this.router.url.includes(Entities.CreboList1):
          key = 'intergrip.crebo-list-1.overview.title';
          break;

        case this.router.url.includes(`${Modules.Intergrip}/${Entities.MboCheck}`):
          key = `transfer-vo.menu.mbo-checks`;
          break;

        case this.router.url.includes(`${Modules.Intergrip}/${Entities.intergrip1Manage}`):
          key = `intergrip.menu.settings`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Modules.Ddd}/${Entities.voReports}`):
          key = `${this.currentUrlString[1]}.menu.reports.title`;
          break;

        case this.router.url.includes(`${Modules.Ddd}/${Entities.specialTransferManage}`):
          key = `${this.currentUrlString[1]}.${Entities.specialTransfer}.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Entities.eld}`):
          key = `${this.currentUrlString[1]}.${Entities.eld}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.int1connections}`):
          key = `${this.currentUrlString[1]}.${Entities.int1connections}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.acties}`):
          key = `${this.currentUrlString[1]}.${Entities.acties}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.fixes}`):
          key = `${this.currentUrlString[1]}.${Entities.fixes}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.int1export}`) && this.router.url.includes(`${Entities.reports}`):
          key = `${this.currentUrlString[1]}.${Entities.reports}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.int1export}`) && this.router.url.includes(`${Entities.intergrip1Monitoring}`):
          key = `${this.currentUrlString[1]}.${Entities.intergrip1Monitoring}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.int1export}`):
          key = `${this.currentUrlString[1]}.exports.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Entities.int1Settings}`):
          key = `${this.currentUrlString[1]}.int1-settings.overview.title`;
          backUrl = ``;
          break;

        case this.router.url.includes(`${Entities.intergrip1Monitoring}`):
          key = this.route.snapshot.data?.title ?? `${this.currentUrlString[1]}.${Entities.intergrip1Monitoring}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.reports}`):
          key = `${this.currentUrlString[1]}.${Entities.reports}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.admin}`):
          key = `${this.currentUrlString[1]}.${Entities.admin}.overview.title`;
          break;

        case this.router.url.includes(`${Entities.TodoStudents}`):
          key = `${this.currentUrlString[1]}.${Entities.TodoStudents}.overview.title`;
          break;
        case this.router.url.includes(`${Entities.setting}/${Entities.System}`):
          key = `${this.currentUrlString[1]}.${this.currentUrlString[2]}.overview.title`;
          backUrl = ``;
          break;
        case this.router.url.includes(`${Entities.setting}/${Entities.Vo}`):
        case this.router.url.includes(`${Entities.setting}/${Entities.Region}`):
          key = `${this.currentUrlString[1]}.${this.currentUrlString[2]}.${this.currentUrlString[3]}.overview.title`;
          break;
        case this.router.url.includes(`${Entities.setting}/${Entities.Choice}`):
          key = `${this.currentUrlString[1]}.${this.currentUrlString[2]}.mbo.overview.title`;
          break;

        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobManagement}/${Entities.competences}`):
          key = `lobdossier.sub-menu.management-competences.title`;
          break;

        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobManagement}/${Entities.format}`):
          key = `lobdossier.sub-menu.management-format.title`;
          break;

        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobManagement}/${Entities.managementRegion}`):
          key = `lobdossier.sub-menu.management-region.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobManagement}/${Entities.managementMbo}`):
          key = `lobdossier.sub-menu.management-mbo.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobManagement}/${Entities.managementVo}`):
          key = `lobdossier.sub-menu.management-vo.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.assignment}/${Entities.assignmentOverview}`):
          key = `lobdossier.sub-menu.assignment-overview.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.assignment}/${Entities.assignmentRegionAll}`):
          key = `lobdossier.sub-menu.assignment-region-all.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.assignment}/${Entities.assignmentGeneralArchived}`):
          key = `lobdossier.sub-menu.assignment-general-archived.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobAssignment}/${Entities.assignmentAll}`):
          key = `lobdossier.sub-menu.lob-assignment-all.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobAssignment}/${Entities.assignmentReceived}`):
          key = `lobdossier.sub-menu.lob-assignment-recieved.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobAssignment}/${Entities.assignmentShared}`):
          key = `lobdossier.sub-menu.lob-assignment-shared.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobAssignment}/${Entities.assignmentOverview}`):
          key = `lobdossier.sub-menu.lob-assignment-overview.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.lobAssignment}/${Entities.assignmentArchived}`):
          key = `lobdossier.sub-menu.lob-assignment-archived.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.carrierAssignment}/${Entities.carrierAssignmentAll}`):
          key = `lobdossier.sub-menu.carrier-assignment-all.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.carrierAssignment}/${Entities.carrierAssignmentPreparation}`):
          key = `lobdossier.sub-menu.carrier-assignment-preparation.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.InternshipAssignments}/${Entities.InternshipAssignmentsAssignments}`):
          key = `internship-assignments.sub-menu.internship-assignments-assignments.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.InternshipAssignments}/${Entities.InternshipAssignmentsArchivedAssignments}`):
           key = `internship-assignments.sub-menu.internship-assignments-archived-assignments.title`;
           break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.handedInAssignments}/${Entities.handedPerAssignment}`):
           key = `lobdossier.sub-menu.handed-in-assignments-assignment.title`;
           break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.handedInAssignments}/${Entities.handedPerStudent}`):
          key = `lobdossier.sub-menu.handed-in-assignments-student.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.carrierAssignment}/${Entities.carrierAssignmentEvaluation}`):
          key = `lobdossier.sub-menu.carrier-assignment-evaluation.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.ChoiceSemester}/${Entities.ChoiceSemestersAssignments}`):
          key = `lobdossier.sub-menu.choice-semester-assignments.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.ChoiceSemester}/${Entities.ChoiceSemesterArchivedAssignments}`):
          key = `lobdossier.sub-menu.choice-semester-archived-assignments.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.ChoiceSemester}/${Entities.ChoiceSemestersOverview}`):
          key = `lobdossier.sub-menu.choice-semester-overview.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.TaskList}/${Entities.TaskPeriod}`):
          key = `lobdossier.sub-menu.task-list-periods.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.TaskList}/${Entities.TaskPart}`):
          key = `lobdossier.sub-menu.task-list-parts.title`;
          break;
        case this.router.url.includes(`${Modules.Lobdossier}/${Entities.TaskList}/${Entities.Tasks}`):
          key = `lobdossier.sub-menu.task-list-tasks.title`;
          break;
        default:
          backUrl = `/${this.currentUrlString[1]}/${this.currentUrlString[2]}`;
      }

      if (this.router.url.includes(`${Entities.lobDossier}/${Entities.lobdossierMyStudents}`)) {
        key = `${this.currentUrlString[1]}.${Entities.Students}.overview.title`;
        backUrl = ``;
      }
      return {
        title: key ? this.translateService.instant(key) : '',
        backUrl,
      };
    }

    if (this.router.url.includes(`${Entities.SystemPreference}`)) {
      key = '';
    }

    return {
      title: key ? this.translateService.instant(key) : '',
    };
  }

  private setUrl(key: string, baseUrl: string, url: string): void {
    if (this.router.url.includes(`${Modules.LobActivities}/${Entities.activityOverview}/status`)) {
      url = `${Entities.activityOverview}/${url}`;
    }
    if (url === 'location-mbo') {
      url = `/_portal/admin/location`;
    }
    if (this.router.url.includes(Entities.SystemPreference)) {
      url = 'settings/system';
    }
    this.url = `${baseUrl}/auth/legacy/key?endpoint=${url}&key=${key}`;
  }
}
