import { Component } from '@angular/core';
import { CSSVarNames, ThemeService, ToastPosition } from '@capturum/ui/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public position: ToastPosition = ToastPosition.TOP_RIGHT;

    constructor(
        private themeService: ThemeService,
    ) {
        this.setTheme();
    }

  public setTheme(): void {
    this.themeService.setProps({
        [CSSVarNames.InputHeight]: '36px',
    });
  }
}
