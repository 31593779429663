<div *ngIf="!to.disabled">
    <div class="work-days">
        <div
            *ngFor="let alias of workDaysAliases"
            class="work-day"
            [ngClass]="{'active': selectedDays.includes(alias.key)}"
            (click)="toggleDaySelection(alias.key)"
        >
            {{ alias.key }}
        </div>
    </div>

    <cap-info-table #infoTable1
                    [loading]="false"
                    [columns]="tableColumns"
                    [paginator]="null"
                    [pagination]="false"
                    [lazyLoading]="false"
                    [selectable]="false"
                    [autoLayout]="true"
                    [stateKey]="null"
                    [sortable]="false"
                    class="bordered-table"
                    [data]="tableCustomData">
        <ng-template [capTemplate]="tableField.StartTime" let-item="item">
            <div class="d-flex">
                <div class="time-input-label">
                    {{ 'social-map.timetable.from.title' | translate }}
                </div>
                <input type="time" id="dateFrom" [value]="item.start_time" (change)="updateData(item, $event.target.value, true)" name="from" step="900" />
            </div>


        </ng-template>

        <ng-template [capTemplate]="tableField.EndTime" let-item="item">
           <div class="d-flex">
                <div class="time-input-label">
                    {{ 'social-map.timetable.till.title' | translate }}
                </div>
               <input type="time" id="dateTill" [value]="item.end_time" (change)="updateData(item, $event.target.value, false)" name="till" step="900" />
           </div>

        </ng-template>

        <ng-template [capTemplate]="tableField.WeekDay" let-item="item">
            {{ getLabel(item.week_day) }}
        </ng-template>

        <ng-template [capTemplate]="tableField.Buttons" let-item="item">
            <div class="row">
                <div class="m-3">
                    <i (click)="addItem(item)" [ngClass]="[fontAwesomeIcon.Plus, 'button']"></i>
                </div>
                <div class="m-3">
                    <i (click)="removeItem(item)" [ngClass]="[fontAwesomeIcon.TrashAlt, 'button']"></i>
                </div>
            </div>
        </ng-template>

    </cap-info-table>
</div>

<div class="info-wrapper" *ngIf="to.disabled">
    <div class="disabled-rows">
        <ul>
            <ng-container *ngFor="let row of tableCustomData; let i = index">
              <span *ngIf="i === 0 || getLabel(row.week_day) !== getLabel(tableCustomData[i-1].week_day)">
                {{ getLabel(row.week_day) }}
              </span>
              <span>
                    {{ 'social-map.timetable.from.title' | translate }} {{ row.start_time }} {{ 'social-map.timetable.till.title' | translate }} {{ row.end_time }}
                     <span *ngIf="i !== tableCustomData.length - 1 && getLabel(row.week_day) === getLabel(tableCustomData[i+1].week_day)"> and </span>
              </span>
              <br *ngIf="i !== tableCustomData.length - 1 && getLabel(row.week_day) !== getLabel(tableCustomData[i+1].week_day)">
            </ng-container>
        </ul>
    </div>
</div>
