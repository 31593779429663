import { Injectable } from '@angular/core';
import {
  PermissionService,
  User,
  UserService as CompleteUserService,
} from '@capturum/complete';
import { map } from 'rxjs/operators';
import { ApiHttpService, ApiIndexResult, ApiListResult, ListOptions, Meta } from '@capturum/api';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { PageConfigAction } from '@core/models/page-config.model';
import { TranslateService } from '@ngx-translate/core';
import { FormattedRole, Role } from '@features/role/models/role.model';
import { UserModel } from '@features/user/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ImpersonateState } from './impersonate.state';
import { LoadingService } from '@capturum/ui/loader';
import { AuthService } from '@features/auth/services/auth.service';
import { RoleType } from '@features/role-type/models/role-type.model';
import { Store } from '@ngxs/store';
import { ImpersonateUser, StopImpersonateUser } from '@core/state/user/user.actions';
import { UserStateModel } from '@core/state/user/user.state';
import Locale from '@capturum/auth/lib/locale.interface';
import AuthUser from '@capturum/auth/lib/user.interface';
import { environment } from '@environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { FilterInclude } from '@core/enums/general/filter-includes.enum';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { Field } from '@shared/field/field.enum';
import {Logs} from "@features/sales-requests/models/sales";
import {ApiSingleResult} from "@capturum/api/lib/interfaces/api.interface";
import { Uid } from '../models/uid.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CompleteUserService {
  public userChanged$: Observable<boolean>;
  public isImpersonated$: Observable<boolean>;
  public propertyValues$ = new BehaviorSubject<MapItem[]>(null);
  public permissionSubscription: Subscription;

  protected endpoint = 'user';

  private pageConfigActions: Record<string, PageConfigAction> = {
    sendAccountData:
      {
        label: this.translateService.instant('intergrip.user.send-account-info'),
        icon: FontAwesomeIcon.Envelope,
        callback: () => {
          // @TODO: add function here
        },
      },
    loginAsUser: {
      label: this.translateService.instant('intergrip.user.login-as-user'),
      icon: FontAwesomeIcon.SignIn,
      callback: () => {
        // @TODO: add function here
      },
    },
    sendReset2fa: {
      label: this.translateService.instant('intergrip.user.send-reset-2fa'),
      icon: FontAwesomeIcon.LockAlt,
      callback: () => {
        // @TODO: add function here
      },
    },
    duplicateUser: {
      label: this.translateService.instant('intergrip.entity.duplicate', {
        entity: this.translateService.instant('intergrip.user.entity_name'),
      }),
      icon: FontAwesomeIcon.LockAlt,
      callback: () => {
        // @TODO: add function here
      },
    },
  };
  private userChanged = new BehaviorSubject<boolean>(true);

  constructor(
    apiHttp: ApiHttpService,
    private translateService: TranslateService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private impersonateState: ImpersonateState,
    private loadingService: LoadingService,
    private store: Store,
    private http: HttpClient,
  ) {
    super(apiHttp, authService);
    this.userChanged$ = this.userChanged.asObservable();
  }

  public index<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return super.index(options);
  }

  public indexTrashed<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return this.apiHttp.get(`/${this.endpoint}/trashed/${this.getOptionsQuery(options)}`);
  }

  public indexScoped<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    const indexOptions: ListOptions = {
      ...options,
      filters: [...options.filters],
    };

    return super.index(indexOptions);
  }

  public getConfigActionItem(key: string, callback?: (...args: any) => void): PageConfigAction {
    return {
      ...this.pageConfigActions[key],
      callback: callback ? callback : this.pageConfigActions[key].callback,
    };
  }

  public setRolePermission(roleId: string): Observable<string> {
    return this.apiHttp.put(`/${this.endpoint}/${this.authService.getUser().id}/favorite/role`, { id: roleId });
  }

  public getActivityLogs(id: string, page: number = 1, perPage: number = 10): Observable<{ data: any, meta: Meta }> {
    return this.apiHttp.get<{ data: any[], meta: Meta }>(`/${this.endpoint}/${id}/activity-log${this.getOptionsQuery({
      include: [FilterInclude.User],
      sort: [{
        field: Field.Created_at,
        direction: 'desc',
      }],
      page,
      perPage,
    })}`).pipe(
      map((response) => {
        const logs: Record<string, any[]> = {};
        response.data.forEach((log, index) => {
          if (log.subject_type.indexOf('User') > -1) {
            const diff = {};
            const item = log.properties.old ? log.properties.old : log.properties.attributes;
            if (!log.properties || log?.properties?.length === 0) {
              return;
            }
            if (!log.properties.old) {
              log.properties.old = {};
            }
            if (!log.properties.attributes) {
              log.properties.attributes = {};
            }
            Object.keys(item).forEach((key) => {
              if (log.properties.old[key] !== log.properties.attributes[key]) {
                if ((key === Field.DeletedRoles || key === Field.NewRoles) && log.properties.attributes[key]?.length === 0) {
                  delete log.properties.attributes[key];
                  delete log.properties.old[key];
                  return;
                }
                if ((key === Field.DeletedRoles || key === Field.NewRoles) && log.properties.attributes[key]?.length > 0) {
                  const value = [];
                  log.properties.attributes[key].forEach((roleKey) => {
                    if (key === Field.NewRoles) {
                      value.push({
                        new: `${roleKey.module_name}-${roleKey.role_name}-${roleKey.tenant_name}`,
                        old: '',
                      });
                    } else {
                      value.push({
                        new: '',
                        old: `${roleKey.module_name}-${roleKey.role_name}-${roleKey.tenant_name}`,
                      });

                    }
                  });
                  diff[key] = value;
                  return;
                }
                if (key === Field.WorkshopLeader) {
                  diff[key] = {
                    new: log.properties.attributes && log.properties.attributes?.hasOwnProperty(key)
                      ? this.booleanToString(log.properties.attributes[key])
                      : '',
                    old: log.properties.old && log.properties.old?.hasOwnProperty(key)
                      ? this.booleanToString(log.properties.old[key])
                      : this.booleanToString(log.properties.attributes[key]),
                  };
                } else {
                  diff[key] = {
                    new: log.properties.attributes && log.properties.attributes?.hasOwnProperty(key) ? log.properties.attributes[key] : '',
                    old: log.properties.old && log.properties.old?.hasOwnProperty(key) ? log.properties.old[key] : log.properties.attributes[key],
                  };
                }
              }
            });

            if (!log?.user?.name) {
              log.user = {
                name: this.translateService.instant('intergrip.activity-log.system-user'),
              };
            }
            logs[index] = [{ ...log, action: log.event, model_loggable_type: 'APP\\User', diffs: { ...diff } }];
          }
        });

        return { data: logs, meta: response.meta };
      }),
    );
  }

  public formatRoles(roles: Role[]): Map<string, FormattedRole> {
    const roleTypeMap = new Map();

    roles.forEach((role: Role) => {
      if (role.role_type_id) {
        roleTypeMap.set(role.roleType.name, [
          ...(roleTypeMap.get(role.roleType.name) ? roleTypeMap.get(role.roleType.name) : []),
          {
            id: role.id,
            tenant: role.tenant.name,
            module: role.module,
            schoolYear: role.schoolYear,
            crebo_ids: role.crebo_ids,
            location_ids: role.location_ids || [],
            sector_ids: role.sector_ids,
            mentorpart_available: role.mentorpart_available,
            property_values: role.roleUsers?.property_values || [],
            key: role.key,
            is_deletable: this.hasHigherRoleRank(
              this.store.selectSnapshot((state: { userState: UserStateModel }) => state?.userState?.user?.currentRoleType),
              [role],
            ),
          },
        ]);
      }
    });

    return roleTypeMap;
  }

  public addRole(data: { role_id: string, user_id: string, school_year_id: string, module_id: string }): Observable<Role> {
    return this.apiHttp.post(`/role-user`, data);
  }

  public sendAccountInfo(userId: string): Observable<void> {
    return this.apiHttp.post(`/auth/${this.endpoint}/send-activate-email`, { user_id: userId });
  }

  public impersonate(userId: string): void {
    this.loadingService.toggleLoading(true);

    this.apiHttp.get(`/auth/${this.endpoint}/${userId}/impersonate`).subscribe(({ token, user }) => {
      this.store.dispatch(new ImpersonateUser(user));
      this.setLoggedInUser(token, user);
    }, err => {
      this.loadingService.hideLoader();
    });
  }

  public stopImpersonate(): void {
    this.loadingService.toggleLoading(true);

    this.apiHttp.get(`/auth/stop-impersonate`).subscribe(({ token, user }) => {
      this.store.dispatch(new StopImpersonateUser());

      this.setLoggedInUser(token, user);
    }, () => {
      this.loadingService.hideLoader();
    });
  }

  public restore(userId: string): Observable<void> {
    return this.apiHttp.put(`/user/${userId}/restore`, { user_id: userId });
  }

  public updateUserState(): void {
    this.userChanged.next(true);
  }

  public export(): Observable<HttpResponse<Blob>> {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    return this.http.get(`${environment.baseUrl}/user/export`,
      {
        observe: 'response',
        responseType: 'blob',
        headers: new HttpHeaders()
          .set('Authorization', `Bearer ${token}`),
      });
  }

  /**
   * Compare own roles to user roles for a higher rank
   *
   * @param currentUserRoleType
   * @param userRoles: Role[]
   *
   * @return boolean
   */
  public hasHigherRoleRank(currentUserRoleType: RoleType, userRoles: Role[]): boolean {
    const highestOwnRank = currentUserRoleType?.rank;
    const highestUserRank = Math.min.apply(Math, userRoles.map(role => role?.roleType?.rank));

    if (highestUserRank === 0) {
      return true;
    }

    return highestOwnRank <= highestUserRank;
  }

  public updateUserLocalStorage(locale: Locale): void {
    const localStorageUser = localStorage.getItem('user');
    const updatedUser: AuthUser = JSON.parse(localStorageUser);
    updatedUser.locale = locale;
    updatedUser.locale_id = locale.id;
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }

  public legacyKey(moduleKey: string): Observable<{ data: { legacyKey: string, legacyBaseUrl: string } }> {
    return this.apiHttp.get(`/legacy/key?module_key=${moduleKey}`);
  }

  public getCurrentUser(): UserModel {
    const localStorageUser = localStorage.getItem('user');
    return JSON.parse(localStorageUser);
  }

  public updateCurrentUser(user: UserModel): void {
    const prevUser = JSON.parse(localStorage.getItem('user')) as User;
    const patchedUser = { ...prevUser, ...user };
    localStorage.setItem('user', JSON.stringify(patchedUser));
  }

  public getSectorListByIds(options: ListOptions): Observable<ApiListResult> {
    return this.apiHttp.get(`/sector/list${this.getOptionsQuery(options)}`);
  }

  public getUsersByRoleType(typeId: string): Observable<ApiListResult> {
    return this.apiHttp.get(`/${this.endpoint}/get-by-role-type/${typeId}`);
  }

  public editUid(data: Uid, uidID: string, userId: string): Observable<ApiSingleResult<Uid>> {
    return this.apiHttp.put(`/${this.endpoint}/${userId}/kennisnet-uid/${uidID}`, data);
  }

  public createUid(data: Uid, userId: string): Observable<ApiSingleResult<Uid>> {
    return this.apiHttp.post(`/${this.endpoint}/${userId}/kennisnet-uid`, data);
  }

  public deleteUid(uidID: string, userId: string): Observable<ApiSingleResult<Uid>> {
    return this.apiHttp.delete(`/${this.endpoint}/${userId}/kennisnet-uid/${uidID}`);
  }

  private setLoggedInUser(token: string, user: User): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    this.permissionSubscription = this.permissionService.loadPermissions().subscribe(() => {
      this.userChanged.next(true);

      this.toastService.success(
        this.translateService.instant('impersonate.logged-in-as.toast.title'),
        this.translateService.instant('impersonate.logged-in-as.toast.message', { user: user.name }),
      );

      this.loadingService.hideLoader();

      this.router.navigate(['/']).then(() => {
        this.router.navigate(['./'], { relativeTo: this.route });
      });

      if (this.permissionSubscription) {
        this.permissionSubscription.unsubscribe();
      }
    });

  }

  private booleanToString(value: string): string {
    return parseInt(value, 10) ? this.translateService.instant('intergrip.user.active.label') : this.translateService.instant('intergrip.user.inactive.label');
  }
}
