import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DialogSubmitService {
    private _submitTrigger = new Subject<void>();

    public get hasSubscriptions(): boolean {
        return this._submitTrigger.observed;
    }

    public get observable(): Observable<void> {
        return this._submitTrigger.asObservable();
    }

    public emitSubmit(): void {
        this._submitTrigger.next();
    }
}
