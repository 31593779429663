import { MessageOfTheDayService } from '@core/services/message-of-the-day.service';
import { Component } from '@angular/core';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-message-of-the-day',
  templateUrl: './message-of-the-day.component.html',
  styleUrls: ['./message-of-the-day.component.scss'],
})
export class MessageOfTheDayComponent {
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(public messageOfTheDayService: MessageOfTheDayService) {
  }

  public closeClicked(): void {
    this.messageOfTheDayService.closeMOTD();
  }

}
